import React from "react";
import {
  Container,
  Badge,
  Row,
  Col,
  CustomInput,
  Table,
  Card,
  CardBody,
  CardFooter,
  CardHeader,
  CardTitle,
  Nav,
  Button,
  UncontrolledTooltip,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Form,
  FormGroup,
  Input,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  Label

} from "reactstrap";
import {Formik,Field,ErrorMessage} from "formik";
import swal from "sweetalert2"
import moment from 'moment'
//pagination 
import Pagination from "react-js-pagination";

//importo helpers per tickets
import tkt from "../../helpers/tickets";

class AssistenzaUtente extends React.Component {

    constructor(props){
      super(props)

      this.state = {
        tickets:[],
        ticketsChild:[],
        textAreaRisposta:'',
        activePage: 1,
        itemPerPage: 10,
        selectedTicket: {}
      }

      this.toggleModalNewTicket = this.toggleModalNewTicket.bind(this)
      //this.getTicketDtl = this.getTicketDtl.bind(this)
      this.getTickets = this.getTickets.bind(this)
      //this.showTktDtl = this.showTktDtl.bind(this)
    }

    componentDidMount(){
      //invoco api per ottenere ticket relativi a utente 
      this.getTickets()
    }

    //gestisce cambio pagina paginazione
    handlePageChange(pageNumber){
      //console.log("PAGE NUMBER",pageNumber)
      this.setState({ activePage: pageNumber });
    }

    //scarica ticket per utente locale o mail inserita in parametri url 
    getTickets(){
      tkt.getUserTickets().then(result=>{
        this.setState({tickets:result})
      }).catch(err=>{

      })
    }

    //ottiene conversazioni di un ticket 
    getTicketDtl=(IDTkt)=>{
      //invoco api a backend per avere conversazione ticket 
      tkt.getTktDtl(IDTkt).then(result=>{
        this.setState({ticketsChild:result})
      }).catch(err=>{

      })
    }

    toggleModalTktDtl=(e)=>{
        this.setState({isModalTckDtlOpen:!this.state.isModalTckDtlOpen})
         //svuoto stringa 
         this.setState({textAreaRisposta:''})
    }


    //aggiorna valore su stato di risposta 
    handleChangeRisposta = (e)=>{
      //console.log("rispsota ",e.target.value)
      this.setState({textAreaRisposta:e.target.value})
    }

    //mostra conversazione ticket
    showTktDtl=(e,item)=>{
      this.setState({selectedTicket:item})
      //ottengo cponversazione ticket 
      this.getTicketDtl(item.ID)

      //mostro popup 
      //...

      this.toggleModalTktDtl()

    }

    //gestisce inserimento risposta a ticket 
    handleReplyTicket = (selectedTicketID,selectedMsg)=>{
      //invio richiesta a backend per inserire risposta a ticket 
      tkt.reply({ID:selectedTicketID,msg:selectedMsg}).then(isOK=>{
        //chiudo popup 
        this.toggleModalTktDtl()
        //refresh conversazione 
        this.getTickets()
      }).catch(err=>{

      })

    }

    //aprepopup per inserimento nuovo ticket 
    toggleModalNewTicket(){
      var _t = this;
      swal.fire({
     
        title: 'Inserisci nuovo Ticket',
        html:     
          '<label class="w-100 text-left"><b>Mail</b></label>'+
          '<input id="swal-input0" type="mail" class="form-control w-100">'+    
          '<label class="w-100 text-left"><b>Titolo</b></label>'+
          '<input id="swal-input1" type="text" class="form-control w-100">'+
          '<label class="w-100 text-left"><b>Descrizione</b></label>'+
          '<textarea id="swal-input2" rows="5" class="form-control w-100"></textarea>',
        focusConfirm: false,
        preConfirm: () => {
          if(document.getElementById('swal-input1').value=='' || document.getElementById('swal-input2').value=='' ){
            swal.showValidationMessage('Campi mancanti')
          }else{
            return [          
              document.getElementById('swal-input0').value,    
              document.getElementById('swal-input1').value,
              document.getElementById('swal-input2').value
            ]
          }
        }
        }).then(function (result) {
            // If validation fails, the value is undefined. Break out here.
            if (typeof(result.value) == 'undefined') {
                return false;
            }else{
              //chiamo api per inserire pagamento 
              //console.log("resuklt",result)
              //swal.fire(JSON.stringify(result))
              var objIn = {
                Mail: result.value[0],
                Title :result.value[1],
                Descr: result.value[2]               
              }

              tkt.addTicket(objIn).then(res=>{
                //console.log("res",res)
                //this.setState({users:res})
                swal.fire(
                  'Aggiunto!',
                  'Il ticket è stato Aggiunto, verrai notificato via mail per gli aggiornamenti ',
                  'success'
                )
                _t.getTickets()
              }).catch(err=>{
                console.log("err",err)
              })
  
  
            }
           
        }).catch(swal.noop)

    }

    render() {
      const { tickets, activePage, itemPerPage } = this.state;

      const indexOfLastTodo = activePage * itemPerPage;
      const indexOfFirstTodo = indexOfLastTodo - itemPerPage;
      const renderedTickets = tickets.slice(indexOfFirstTodo, indexOfLastTodo);

        return (
            <div className="position-relative">
            {/* shape Hero */}
            <section className="section section-md section-shaped pb-10">
              <div className="shape shape-style-1 shape-primary">
                <span />
                <span />
                <span />
                <span />
                <span />
                <span />
                <span />
                <span />
                <span />
              </div>
              <Container  className="py-lg-md d-flex" >
                <div className="col px-0">
                  <Row>
                    <Col lg="6">                        
                        <p className="lead text-white">
                         Inserisci un ticket
                        </p>                        
                    </Col>
                  </Row>
                </div>
              </Container>
              {/* SVG separator */}
              <div className="separator separator-bottom separator-skew">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  preserveAspectRatio="none"
                  version="1.1"
                  viewBox="0 0 2560 100"
                  x="0"
                  y="0"
                >
                  <polygon
                    className="fill-white"
                    points="2560 0 2560 100 0 100"
                  />
                </svg>
              </div>
            </section>
            
            <section className="section pb-10 pt-0">
              <Container fluid className="p-5">
                <Row>
                  <Col lg="12">
                    <Card className=" shadow border-0">
                      <CardHeader>
                        <Row>
                          <Col><b>Storico Tickets</b></Col>
                          <Col className="text-right">
                            <Button color="default" size="sm" outline type="button" onClick={this.toggleModalNewTicket}>                      
                              Aggiungi
                            </Button>
                          </Col>
                        </Row>  
                      </CardHeader>
                      <CardBody className="p-0">
                        <Table className="mb-0" size="sm" hover striped responsive>{/* size="sm"*/}
                            <thead>
                              <tr>
                                <th></th>
                                <th>#</th>                                
                                <th>Titolo</th>
                                <th>Descrizione</th>
                                <th>Data</th>                               
                                <th>Stato</th>
                                
                              </tr>
                            </thead>
                            <tbody>
                              {renderedTickets.map((item,i)=>{
                                return <tr key={i}>
                                    <td>
                                      <Button outline
                                      className=" btn-icon btn-simple"
                                      color="info"
                                      size="sm"
                                      type="button"
                                      onClick={(e)=>{this.showTktDtl(e,item)}}
                                      >
                                        <i className="fa fa-search"> </i> 
                                      </Button>
                                    </td>
                                    <td>{item.ID}</td>
                                    <td>{item.Title}</td>
                                    <td>{item.Description}</td>
                                    <td>{moment(item.DateInsert).format("DD-MM-YYYY HH:mm")}</td>                                                                    
                                    
                                    <td>
                                        <Button outline
                                        className=" btn-icon btn-simple"
                                        color={tkt.getStatusByID(item.StatusDescr).color}
                                        size="sm"
                                        type="button"
                                        
                                        >
                                         {tkt.getStatusByID(item.StatusDescr).msg}
                                      </Button>
                                    </td>
                                  </tr>
                              })
                            }
                            </tbody>
                        </Table>
                      </CardBody>
                      <CardFooter>
                          <Nav className="justify-content-center">
                            <Pagination
                                  hideNavigation
                                  activePage={this.state.activePage}                                  
                                  itemsCountPerPage={this.state.itemPerPage}
                                  totalItemsCount={this.state.tickets.length}
                                  pageRangeDisplayed={5}
                                  onChange={this.handlePageChange}
                                  itemClass="page-item"
                                  linkClass="page-link"                                 
                              />
                            </Nav>
                        </CardFooter>
                    </Card>
                  </Col>
                </Row>
              </Container>
            </section>
            {/*          Modal visualizzazione storia ticket          */}
            <Modal isOpen={this.state.isModalTckDtlOpen} toggle={this.toggleModalTktDtl} size="lg">
                      <ModalHeader toggle={this.toggleModalTktDtl}>Conversazione per ticket {this.state.selectedTicket.ID} <Badge color="secondary">{this.state.ticketsChild.length}</Badge></ModalHeader>
                      <ModalBody>
                        <Container fluid>   
                          <Row className="mb-2">
                              <Card className="col-12 bg-gradient-warning">
                                  <CardBody className="p-2">
                                  <CardTitle className=" text-white" tag="h3">
                                      {this.state.selectedTicket.Title}
                                  </CardTitle>
                                  <blockquote className=" blockquote text-white mb-0">
                                      <p>
                                      {this.state.selectedTicket.Description}
                                      </p>
                                      <footer className="text-right blockquote-footer text-white">
                                          {moment(this.state.selectedTicket.DateInsert).format("DD-MM-YY HH:mm")} - {this.state.selectedTicket.UserMail}
                                      </footer>
                                  </blockquote>
                                  </CardBody>
                              </Card>
                          </Row>
                          {this.state.ticketsChild.map((el,id)=>{
                            return <Row key={id} className={(el.StatusDescr==2?"justify-content-start":"justify-content-end")+" mb-2"}>
                                  <Card className={"col-10 p-3 "+(el.StatusDescr==2?"bg-gradient-default":"bg-teal")+" text-white"}>
                                    <CardBody className=" blockquote mb-0 p-2">
                                        <p>
                                        {el.Description}
                                        </p>
                                        <footer className=" blockquote-footer text-white text-right">
                                          <small className=" text-muted text-white">
                                              {el.UserMail} <cite title="Source Title">{moment(el.DateUpdate).format('DD-MM-YY HH:mm')}</cite>
                                          </small>
                                        </footer>
                                    </CardBody>
                                    </Card> 
                                </Row>
                              }
                            )
                          }
                          
                          {/*<Row className="mb-2 justify-content-start">
                              <Card className="col-10 bg-gradient-default">
                                  <CardBody className="p-2">
                                  <CardTitle className=" text-white" tag="h3">
                                      {this.state.selectedTicket.Title}
                                  </CardTitle>
                                  <blockquote className=" blockquote text-white mb-0">
                                      <p>
                                      {this.state.selectedTicket.Description}
                                      </p>
                                      <footer className=" blockquote-footer text-danger">
                                          {this.state.selectedTicket.UserMail}
                                      </footer>
                                  </blockquote>
                                  </CardBody>
                              </Card>
                        </Row>*/}
                          <Row>
                            <Col>
                              <hr></hr>
                              {this.state.selectedTicket.StatusDescr!=4?
                              <Input                                
                                placeholder="Inserisci la tua risposta qui..."
                                rows="3"
                                value={this.state.textAreaRisposta}
                                onChange={this.handleChangeRisposta}
                                type="textarea"
                              />:<span></span>
                              }
                            </Col>
                          </Row>
                        </Container>
                      </ModalBody>
                      <ModalFooter>
                              <Button color="primary" disabled={this.state.textAreaRisposta=='' || this.state.selectedTicket.StatusDescr==4} onClick={(e)=>{this.handleReplyTicket(this.state.selectedTicket.ID,this.state.textAreaRisposta)}} outline>Rispondi</Button>
                      </ModalFooter>
                  </Modal>
        </div>
        )
    }
}

export default AssistenzaUtente;