// gestisce acquisti abbonamenti ( necessario aver fatto login )

//pagina guest per chi si connette e vuole partecipare a streaming
/*!

=========================================================
* Argon Design System React - v1.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-design-system-react
* Copyright 2020 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/argon-design-system-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React,{useState} from "react";
import classnames from 'classnames';
import { Route, Switch, Redirect } from "react-router-dom";
// reactstrap components
import {   
    Button,   
    Container,
    Row,
    Card,
    CardBody,
    Badge,
    Col,
    Modal,
    ModalHeader,
    ModalBody,
    ModalFooter,
    TabContent, TabPane, Nav, NavItem, NavLink, CardTitle, CardText,
  } from "reactstrap";

  import moment from "moment"
  import swal from "sweetalert2"
// core components
import Header from "components/Navbars/Header";
import HeaderAdmin from "components/Navbars/AdminNavbar";

import {getKEYStripe} from "../helpers/utils"
import sub from "../helpers/subscriptions"
import { Fragment } from "react";

import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";
import { CheckoutForm } from "../helpers/CheckoutForm";
const PUBLIC_KEY = getKEYStripe();
const stripeTestPromise = loadStripe(PUBLIC_KEY);



// index page sections
//import Download from "../IndexSections/Download.js";

class Acquista extends React.Component {
  

  toggle = tab => {
    if(this.activeTab !== tab) this.setState({activeTab:tab})
  }
  constructor(props){
      //console.log("props join",props)      
      super(props)
      this.state = {
        activeTab:'1',
        subscriptions:[],
        isModalPagamentoOpen:false,
        selectedSub:{},
        isLoggedIn:false
      };
      //this.state.id=props.match.params.id
  }
  componentDidMount() {
    //this.setState({id:props.match.params.id})
    this.refreshSubscriptions()
    //setto variabile isLogged in in funzione se esiste token
    if(window.localStorage.getItem("hoppla_token")){
      this.setState({isLoggedIn:true})
    }else{
      this.setState({isLoggedIn:false})
    }
  }

  refreshSubscriptions=()=>{
    sub.getSubscriptions().then(res=>{
      console.log("res",res)
      this.setState({subscriptions:res})
    }).catch(err=>{
      console.log("err",err)
    })
  }

  //funzione per far comparire modal pagamento 
  toggleModalPagamento = (e,item)=>{
    //se sto aprendo modal , seleziono elemento
    if(!this.state.isModalPagamentoOpen){
      this.setState({selectedSub:item})
    }
    this.setState({isModalPagamentoOpen:!this.state.isModalPagamentoOpen})
  }

  //callback per pagamento failed 
  onFailed=(errMsgOut)=>{
    console.log("obj out failed: ",errMsgOut)
    //mostro errore
    swal.fire("Errore",errMsgOut,"error")
  }

  //callback per pagamento ok
  onOk=(objOut)=>{
    console.log("obj out ok: ",objOut)
    //mostro messaggio di successo 
    swal.fire("Fatto","Transazione avvenuta con successo","success")
    //chiudo popup 
    this.toggleModalPagamento()
  }

  //pagamento manuale 
  setManualPay =()=>{
    //invoco api backend con dati di cliente per settarlo come pagato con bonifico 

  }


  render() {
    return (
      <>
      {/* se loggato carico componente admin, altrimenti componente utente normale  */}
          {!this.state.isLoggedIn?
         <Header />
         :
         <HeaderAdmin/>
          }
        <main ref="main">
          <div className="position-relative">
            {/* shape Hero */}
            <section className="section section-lg section-shaped pb-150">
              <div className="shape shape-style-1 shape-primary">
                <span />
                <span />
                <span />
                <span />
                <span />
                <span />
                <span />
                <span />
                <span />
              </div>
              <Container className="py-lg-md d-flex">
                <div className="col px-0">
                  <Row>
                    <Col lg="8" sm="12">
                      <h1 className="display-3 text-white">
                        
                        <span>Acquista</span>
                      </h1>
                      <p className="lead text-white">
                        Scegli l'abbonamento che si addice di più alle tue richieste e acquistalo con il metodo di pagamento che preferisci !
                      </p>                      
                    </Col>
                  </Row>
                </div>
              </Container>
              {/* SVG separator */}
              <div className="separator separator-bottom separator-skew">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  preserveAspectRatio="none"
                  version="1.1"
                  viewBox="0 0 2560 100"
                  x="0"
                  y="0"
                >
                  <polygon
                    className="fill-white"
                    points="2560 0 2560 100 0 100"
                  />
                </svg>
              </div>
            </section>
            {/* 1st Hero Variation */}
          </div>
          <section className="section section-lg pt-lg-0 mt--100">
            <Container>
             
              {/* LISTA CON ABBONAMENTI  */}
              <Row>
                {this.state.subscriptions.map((el,i)=>{
                  return <Col lg="4" sm="12" key={i}>
                    <Card className="card-lift--hover shadow border-0 mb-4">
                      <CardBody className="py-4">
                        {/*<div className="icon icon-shape icon-shape-warning rounded-circle mb-4">
                          <i className="ni ni-palette" />
                        </div>
                        */}
                        <h6 className="text-primary font-weight-light text-center text-uppercase display-4">
                          {el.Title}
                        </h6>
                        <p className="description mt-3">
                          {el.Description}
                        </p> 
                        <div className="description mt-3">
                            <ul className="list-group list-group-flush">
                              {el.isFlat==1?                              
                                <li className="list-group-item p-2"><span className="font-weight-bold">Durata </span><h5 className="float-right mb-0"><Badge className="float-right" pill color="secondary">{moment.duration(el.DurationDays,'d').humanize()}</Badge></h5></li>
                              :<span></span>
                              }
                              {el.MaxGuidesInside>0?<li className="list-group-item p-2"><span className="font-weight-bold">Guide Gestibili </span><h5 className="float-right mb-0"><Badge className="float-right" pill color="secondary">{el.MaxGuidesInside}</Badge></h5></li>:<span></span>}
                              {el.isFlat!=1?<li className="list-group-item p-2"><span className="font-weight-bold">Eventi </span><h5 className="float-right mb-0"><Badge className="float-right" pill color="secondary">{el.MaxEventsPerMonth}</Badge></h5></li>
                              :<li className="list-group-item p-2"><span className="font-weight-bold">Eventi </span><h5 className="float-right mb-0"><Badge className="float-right" pill color="secondary">ILLIMITATI</Badge></h5></li>} 
                                                        
                            </ul>
                          </div>  
                           {/*href="/#/register"      */}                             
                        {
                          this.state.isLoggedIn?
                          <Button
                          className="mt-4 btn-block"
                          color="warning"                          
                          onClick={(e)=>{this.toggleModalPagamento(e,el)}}                     
                        >
                         {el.PriceMonth} € {el.isFlat==1?<small>(al mese)</small>:<i></i>} - Acquista
                        </Button>:
                        <Button
                            className="mt-4 btn-block"
                            color="warning"   
                            href="/#/register" 
                          >
                        {el.PriceMonth} € {el.isFlat==1?<small>(al mese)</small>:<i></i>} - Iscriviti
                        </Button>
                        }
                      </CardBody>
                    </Card>
                  </Col>
                })}                
              </Row>
            </Container>
          </section>
          {/* definisco modal dove ospitare form pagamento  */}
          <Modal isOpen={this.state.isModalPagamentoOpen}  size="" backdrop="static"> {/* toggle={this.toggleModalPagamento} */}
              <ModalHeader toggle={this.toggleModalPagamento} className="">Acquista: {this.state.selectedSub.Title} - <b>{this.state.selectedSub.PriceMonth}</b>€</ModalHeader> 
              <ModalBody className="p-2">
                <Container fluid> 
                    <Elements stripe={stripeTestPromise} >
                      <CheckoutForm 
                      amount={this.state.selectedSub.PriceMonth} 
                      IDRefAbb={this.state.selectedSub.ID}
                      DurationDays={this.state.selectedSub.DurationDays}
                      onOk={this.onOk} 
                      onFailed={this.onFailed} 
                      description={this.state.selectedSub.Title}
                      
                      />
                    </Elements>                  
                  </Container>
              </ModalBody>              
          </Modal>
        </main>
      </>
    );
  }
}

export default Acquista;
