import API,{refresh, APInoToken,manageError} from './api'
import swal from 'sweetalert';

/**
 * ottiene info di utente
 */
async function insert(coupon){    
    try{    
        let data = await API.put('/cpp/',coupon)       
        return data.data;  
    }catch(err){
        //console.log("err -- - - -",err)
        manageError(err.response)
        //console.log("error",err.message)
        throw err.response
    }             
} 

/**
 * ottiene info di utente
 */
 async function activate(coupon){    
    try{    
        let data = await API.post('/cpp/',coupon)       
        return data.data;  
    }catch(err){
        //console.log("err -- - - -",err)
        manageError(err.response)
        //console.log("error",err.message)
        throw err.response
    }             
} 

/**
 * ottiene info di utente
 */
 async function del(ID){    
    try{    
        let data = await API.delete('/cpp/',{params:{ID}})       
        return data.data;  
    }catch(err){
        console.log("err -- - - -",err)
        manageError(err.response)
        //console.log("error",err.message)
        throw err.response
    }             
} 

/**
 * ottiene info di eventi
 */
 async function getCoupons(isActivated, isDeleted){    
    try{    
        //console.log("IDUser",IDUser)
        let data = await API.get('/cpp/',{params:{isActivated,isDeleted}})       
        return data.data;  
    }catch(err){
        //console.log("err -- - - -",err)
        manageError(err.response)
        //console.log("error",err.message)
        throw err.response
    }             
} 

/**
 * fa il check se esiste coupon
 */
 async function check(objIn){    
    try{    
        //console.log("IDUser",IDUser)
        let data = await API.get('/cpp/check',{params:objIn})       
        return data.data;  
    }catch(err){
        //console.log("err -- - - -",err)
        manageError(err.response)
        //console.log("error",err.message)
        throw err.response
    }             
} 


export default{
    insert,
    del,
    getCoupons,
    check,
    activate
}