import API,{manageError, APInoToken} from './api'
import swal from 'sweetalert';



/**
 * ottiene info di utente
 */
async function getMenu(IdMenuCategory){    
    try{    
        let data = await API.get('/sys/menu',{params:{IdMenuCategory:IdMenuCategory}})
        return data.data.menu;  
    }catch(err){
        //console.log("err -- - - -",err)
        manageError(err.response)
        //console.log("error",err.message)
        throw err.response
    }             
} 

//invia mail di contatto 
async function sendContactMail(mail,name,subject){    
    try{    
        let data = await APInoToken.post('/sys/contactmail',{mail,name,subject})
        return data.response;  
    }catch(err){
        //console.log("err -- - - -",err)
        manageError(err.response)
        //console.log("error",err.message)
        throw err.response
    }             
} 

/**
 * fa il login
 
async function login(user,pass){   
    try{  
        let data = await APInoToken.post("/auth/login", {
            username: user,
            password: pass
        })

        window.localStorage.setItem('hoppla_token',data.data.token)
        //window.location.replace('/dashboard')
        //history.push("/admin");
        //this.handleClick()
        refresh()
    }catch(err){
        throw err.response
    }
           
} */

export default{
    getMenu,
    sendContactMail    
}


