/*!

=========================================================
* Argon Design System React - v1.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-design-system-react
* Copyright 2020 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/argon-design-system-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";
import { Route, Switch, Redirect } from "react-router-dom";

// core components
import Header from "components/Navbars/AdminNavbar";
import CardsFooter from "components/Footers/Footer.js";
import routes from "routes.js";

// index page sections
//import Download from "../IndexSections/Download.js";

class Dashboard extends React.Component {
  constructor(props){
    super(props)
  }
  state = {};
  componentDidMount() {
    /*document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
    this.refs.mainT.scrollTop = 0;
    */
    
    
  }

  getRoutes = routes => {
    return routes.map((prop, key) => {
      if (prop.layout === "/dashboard") {
        return (
          <Route
            path={prop.layout + prop.path}
            component={prop.component}                        
            key={key}
          />
        );
      } else {
        return null;
      }
    });
  };

  render() {
    return (
      <>
        <Header parent={this.props}/>  
        <Switch>
            {this.getRoutes(routes)}
            <Redirect from="*" to="/dashboard/index" />
        </Switch>
        <CardsFooter />
      </>
    );
  }
}

export default Dashboard;
