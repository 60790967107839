
import React,{Fragment} from "react";
import ReactDatetime from 'react-datetime';


import {
  Container,
  Badge,
  Row,
  Col,
  CustomInput,
  Table,
  Card,
  CardBody,
  CardFooter,
  CardHeader,
  Nav,
  Button,
  UncontrolledTooltip,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Form,
  FormGroup,
  Input,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  Label
} from "reactstrap";
import {Formik,Field,ErrorMessage} from "formik";

import swal from "sweetalert2"
import moment from 'moment'
//pagination 
import Pagination from "react-js-pagination";

//require("bootstrap/less/bootstrap.less");

//importo helpers per utente
import sub from "../../helpers/subscriptions.js";

class AnagraficheUtenti extends React.Component {

  constructor(props){
    super(props)
    this.state = {
      subscriptions:[],
      subscriptionsAna:[],
      payments:[],
      selectedAbb:{},
      showDisabled:true,
      activePage: 1,
      itemPerPage: 10,
      isModalPagamentiOpen:false,
      isAllUsers:false
    }
    this.handlePageChange = this.handlePageChange.bind(this);
    this.handleUsersLevelChange = this.handleUsersLevelChange.bind(this);
    this.handleDeleteSub = this.handleDeleteSub.bind(this);
    this.refreshSubscriptions = this.refreshSubscriptions.bind(this)
    this.handleSollecito = this.handleSollecito.bind(this)
    this.toggleModalEdit = this.toggleModalEdit.bind(this)
    this.formatDate = this.formatDate.bind(this)
    this.handleChangeSub = this.handleChangeSub.bind(this)
    this.handleRequestNew = this.handleRequestNew.bind(this)
    this.handleRequestEdit = this.handleRequestEdit.bind(this)
    this.validateForm = this.validateForm.bind(this)
    this.toggleModalPagamenti = this.toggleModalPagamenti.bind(this)
    this.getUserDescrFromID = this.getUserDescrFromID.bind(this)
    this.getPaymentsByIDAbb = this.getPaymentsByIDAbb.bind(this)
    this.refreshPayments = this.refreshPayments.bind(this)
    this.togglePaymentState = this.togglePaymentState.bind(this)
    this.togglePaymentEnabled = this.togglePaymentEnabled.bind(this)
    this.toggleModalEditPayments = this.toggleModalEditPayments.bind(this)
  }

  toggleModalEdit(e,item){
    
    if(item){
      //
      this.setState({selectedAbb:item})
     
    }else{
      //caso nuova sottoscrizione o chiusura popup 
      this.setState({selectedAbb:{ExpireOn:new Date(),PricePerMonth: 0,Mail:'',subscriptionID:'',IDUser:''}})
    }
    if(e)
      e.preventDefault()
    this.setState({isModalEditOpen:!this.state.isModalEditOpen})
  }

  //validazione form
  validateForm(values){
    const emailTest = /^[^\s@]+@[^\s@]+\.[^\s@]{2,}$/i;
    let errors = {};

    //ExpireOn:new Date(),PricePerMonth: 0,Mail:'',subscriptionID:'',IDUser:''

    if (values.ExpireOn === "") {
        errors.ExpireOn = "Data di scadenza obbligatoria";
    }
    if (values.PricePerMonth === "" ||values.PricePerMonth<=0) {
      errors.PricePerMonth = "Prezzo obbligatorio";
    }

    
    //controllo tipo di utente selezionato 
    if (values.subscriptionID == "") {
      errors.subscriptionID = "Tipo di sottoscrizione obbligatoria";
    } 

    //IDUser
    if (values.IDUser == "") {
      errors.IDUser = "Utente obbligatorio";
    } 


    //console.log("values in validate ",values )

    return errors;

  }

  handlePageChange(pageNumber){
    //console.log("PAGE NUMBER",pageNumber)
    this.setState({ activePage: pageNumber });
  }

  handleSollecito(e,subobj){
    //pannello di conferma 
    swal.fire({
      title: 'Confermi?',
      text: "Stai per inviare una mail di sollecito a "+subobj.Mail ,
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Conferma'
    }).then((result) => {
      if (result.isConfirmed) {
        //console.log("e",e)
        //console.log("ID",ID)
        sub.sendSollecito(subobj).then(res=>{
          //console.log("res",res)
          //this.setState({users:res})
          swal.fire(
            'Inviata!',
            'Mail di sollecito Inviata',
            'success'
          )
          this.refreshUsers()
        }).catch(err=>{
          console.log("err",err)
        })
      }
    })

    
  }

  handleDeleteSub(e,subobj){
    //pannello di conferma 
    swal.fire({
      title: 'Confermi?',
      text: "Stai per eliminare l'abbonamento "+subobj.ID ,
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Conferma'
    }).then((result) => {
      if (result.isConfirmed) {
        swal.fire(
          'Inviata!',
          'funzione non ancora implementata',
          'success'
        )
      }
    })

    
  }

  handleRequestEdit(obj){
    //console.log("MODIFICA",obj)
    //aggiungo ID a oggetot d apassare a server 
    obj.ID = this.state.selectedAbb.ID
    //invoco api per modificare abbonamento 
    sub.editSubscription(obj).then(rr=>{
      //refresh di sottoscrizioni
      this.refreshSubscriptions()
      
      //chiudo popup 
      this.setState({isModalEditOpen:!this.state.isModalEditOpen})

    }).catch(err=>{

    })
  }

  handleRequestNew(obj){
    console.log("INSERIMENTO",obj)
    //invoco api per inserire nuovo abbonamento 
    sub.insSubscription(obj).then(rr=>{
      //refresh di sottoscrizioni
      this.refreshSubscriptions()
      
      //chiudo popup 
      this.setState({isModalEditOpen:!this.state.isModalEditOpen})

    }).catch(err=>{

    })
  }

  refreshSubscriptions(e){
    var allUsers = this.state.isAllUsers;
    if(e){
      e.persist()
      //console.log("allusers",e.target.checked)
      allUsers = e.target.checked
      this.setState({isAllUsers:allUsers})
    }
    sub.getUsersSubscriptions(allUsers).then(res=>{
      console.log("res",res)
      this.setState({subscriptions:res})
    }).catch(err=>{
      console.log("err",err)
    })
  }

  handleUsersLevelChange(e){
    e.persist()
    this.setState({IDMenuCategory:e.target.value})
    //console.log("this.state",_t.state)
    //scarico abbonamenti in funzione di livello utente selezionato 
    sub.getSubscriptions(e.target.value).then(res=>{
        console.log("result",res)
        this.setState({subscriptionsAna:res})
        if(res.length>0){
            this.setState({subscriptionID:res[0].ID})
        }
    }).catch(err=>{
        console.log("err",err)
    })

  }
/*
  handleChangeUser(e,form){
    e.persist();
    //associo mail a 
  }*/

  //al cambio abbonamento cambia prezzo e .. in futuro data di scadenza per flat 
  handleChangeSub(e,form){
    e.persist()
    //cerco PriceMonth su array di abbonamenti per presettare il prezzo su form
    this.state.subscriptionsAna.forEach(item=>{
      if(item.ID == e.target.value){
        form.setFieldValue("PricePerMonth",item.PriceMonth) 
        //console.log("setto field",item)
        //setto data di fine in base a abbonamento 
        var dateEndAbb = moment().add('days',item.DurationDays);
        form.setFieldValue("ExpireOn",dateEndAbb) 

      }
    })
  }

  toggleModalPagamenti(e,item){
    //apre popup con riassunto pagamenti per quell'abboamento
    this.setState({isModalPagamentiOpen:!this.state.isModalPagamentiOpen})

    if(item){
      this.setState({selectedAbb:item})
      //scarico pagamenti di id abbonamento selezionato 
      this.getPaymentsByIDAbb(item.IDAbb)

    }
  }

  formatDate(momentDate) {        
    return moment(momentDate).format("YYYY-MM-DD");
  }

  getUserDescrFromID(ID){
    var UU = this.state.subscriptions.filter(sub=>{ return sub.IDUser==ID})
    if(UU.length>0){
      return UU[0].Mail //UU[0].Nome +" "+UU[0].Cognome
    }else{
      return "";
    }
  }

  getPaymentsByIDAbb(IDAbb,isAll){
    //invoco api per ottnere lista di pagamenti collegati all'abbonamento 
    sub.getPaymentsByIDAbb(IDAbb,isAll).then(payments=>{
      console.log("pagamenti per utente selezionato ",payments)
      this.setState({payments:payments})
    }).catch(err=>{

    })
  }

  //fa refresh di pagamenti 
  refreshPayments(e){
    var allPayments = false;
    if(e){
      e.persist()
      //console.log("allusers",e.target.checked)
      allPayments = e.target.checked      
    }

    this.getPaymentsByIDAbb(this.state.selectedAbb.IDAbb,allPayments)

  }

  //modifica pagametno 
  toggleModalEditPayments(){
    //inserisco prezzo, data inizo data fine 
    var IDAbb = this.state.selectedAbb.IDAbb
    swal.fire({
     
      title: 'Inserisci prezzo e date inizio e fine',
      html:
        '<label class="w-100 text-left"><b>Prezzo</b></label>'+
        '<input id="swal-input1" type="number" class="form-control ">' +
        '<label class="w-100 text-left"><b>Data Inizio</b></label>'+
        '<input id="swal-input2" type="date" class="form-control w-100">'+
        '<label class="w-100 text-left"><b>Data Fine</b></label>'+
        '<input id="swal-input3" type="date" class="form-control w-100">',
      focusConfirm: false,
      preConfirm: () => {
        if(document.getElementById('swal-input1').value=='' || document.getElementById('swal-input2').value=='' || document.getElementById('swal-input3').value==''){
          swal.showValidationMessage('Campi mancanti')
        }else{
          return [
            document.getElementById('swal-input1').value,
            document.getElementById('swal-input2').value,
            document.getElementById('swal-input3').value
          ]
        }
      }
      }).then(function (result) {
          // If validation fails, the value is undefined. Break out here.
          if (typeof(result.value) == 'undefined') {
              return false;
          }else{
            //chiamo api per inserire pagamento 
            //console.log("resuklt",result)
            //swal.fire(JSON.stringify(result))
            var objIn = {
              Price :result.value[0],
              DateStart: result.value[1],
              DateStop :result.value[2],
              IDAbb :IDAbb
            }
            sub.addPayment(objIn).then(res=>{
              //console.log("res",res)
              //this.setState({users:res})
              swal.fire(
                'Aggiunto!',
                'Il pagamento è stato Aggiunto ',
                'success'
              )
              this.refreshPayments()
              this.refreshSubscriptions()
            }).catch(err=>{
              console.log("err",err)
            })


          }
         
      }).catch(swal.noop)


  }

  //fa toggle di stato di pagamento 
  togglePaymentState(payment){
    var StrState ='';
    var isPayed = false;
    if(payment.PayedDate){
      StrState="Non Pagato"
      isPayed = false;
    }else{
      StrState="Pagato"
      isPayed=true;
    }
    //modifico stato pagamento e invio a backend 
    swal.fire({
      title: 'Confermi?',
      text: "Stai per settare il pagamento come "+StrState,
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Conferma'
    }).then((result) => {
      if (result.isConfirmed) {
        //console.log("e",e)
        //console.log("ID",ID)
        sub.editPaymentState(payment.ID,isPayed).then(res=>{
          //console.log("res",res)
          //this.setState({users:res})
          swal.fire(
            'Modificato!',
            'Il pagamento è stato impostato come '+StrState,
            'success'
          )
          this.refreshPayments()

          this.refreshSubscriptions()
        }).catch(err=>{
          console.log("err",err)
        })
      }
    })

  }

  //abilito o disabilito pagamento (flag is enabled )
  togglePaymentEnabled(ID,futureState){
    var StrState ='';
    var isEnabled = futureState;
    if(isEnabled){
      StrState="ABILITATO"      
    }else{
      StrState="DISABILITATO"     
    }
    //modifico stato pagamento e invio a backend 
    swal.fire({
      title: 'Confermi?',
      text: "Stai per settare il pagamento come "+StrState,
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Conferma'
    }).then((result) => {
      if (result.isConfirmed) {
        //console.log("e",e)
        //console.log("ID",ID)
        sub.editPaymentEnableFlag(ID,isEnabled).then(res=>{
          //console.log("res",res)
          //this.setState({users:res})
          swal.fire(
            'Modificato!',
            'Il pagamento è stato impostato come '+StrState,
            'success'
          )
          this.refreshPayments()
          this.refreshSubscriptions()
        }).catch(err=>{
          console.log("err",err)
        })
      }
    })
  }

  //funzione oper filtrare tabelal sopttoscrizioni 
  customFilterTBL = (item)=>{
    if(this.state.showDisabled==true){
      return true;
    }else{
      if(item.isEnabledAbb==1) return true
    }
  }

  //cambia stato di abbonamento da disabled a enabled e viceversa 
  requestToggleStateAbb = (item) =>{
    var StrState = item.isEnabledAbb==1?'DISABILITATO':'ABILITATO'
    swal.fire({
      title: 'Confermi?',
      text: "Stai per settare l'abbonamento come "+StrState,
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Conferma'
    }).then((result) => {
      if (result.isConfirmed) {
        sub.editSubscriptionEnableFlag(item.IDAbb,item.isEnabledAbb==0).then(res=>{
          swal.fire(
            'Modificato!',
            'L\'abbonamento è stato impostato come '+StrState,
            'success'
          )
          this.refreshSubscriptions()
        }).catch(err=>{

        })
      }
    })
  }


  componentDidMount(){
    this.refreshSubscriptions()
    //scarico tipi di abbonamenti per popolare combo anagraficeh inserimento e modifica 
    //scarico le tipologie di utente 
    /*
    sub.getUsersLevel().then(res=>{
      //console.log("result tipo utente",res)
      this.setState({userslevel:res})
    }).catch(err=>{
      
    })
    */
    sub.getSubscriptions().then(res=>{
       
        this.setState({subscriptionsAna:res})
       
    }).catch(err=>{
        console.log("err",err)
    })
  }
    render() {

      const { subscriptions, activePage, itemPerPage } = this.state;

      const indexOfLastTodo = activePage * itemPerPage;
      const indexOfFirstTodo = indexOfLastTodo - itemPerPage;
      const renderedSubscriptions = subscriptions.slice(indexOfFirstTodo, indexOfLastTodo);

        return (
            <div className="position-relative">
            {/* shape Hero */}
            <section className="section section-md section-shaped pb-10">
              <div className="shape shape-style-1 shape-primary">
                <span />
                <span />
                <span />
                <span />
                <span />
                <span />
                <span />
                <span />
                <span />
              </div>
              <Container fluid className="py-lg-md d-flex" >
                <div className="col px-0">
                  <Row>
                    <Col lg="6">                        
                        <p className="pl-4 lead text-white">
                         Gestisci Abbonamenti e Pagamenti <span className="badge badge-secondary">{this.state.subscriptions.length}</span>
                        </p>                        
                    </Col>
                  </Row>
                </div>
              </Container>
              {/* SVG separator */}
              <div className="separator separator-bottom separator-skew">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  preserveAspectRatio="none"
                  version="1.1"
                  viewBox="0 0 2560 100"
                  x="0"
                  y="0"
                >
                  <polygon
                    className="fill-white"
                    points="2560 0 2560 100 0 100"
                  />
                </svg>
              </div>
            </section>
            <section className="section pb-10 pt-0">
            <Container fluid className="p-5">
              {/*<Row className="row-grid align-items-center">
                
                <Col className="" lg="6" sm="12">
                  <div className="d-flex px-3">
                    <div>
                      <div className="icon icon-lg icon-shape bg-gradient-white shadow rounded-circle text-primary">
                        <i className="ni ni-building text-primary" />
                      </div>
                    </div>

                    <div className="pl-4">
                      <h4 className="display-3 ">
                          Eventi
                          <Button
                          className="mr-4 mt-2 float-right"
                          color="info"
                          href="#pablo"
                          onClick={e => e.preventDefault()}
                          size="sm"
                        >
                          <i className="fa fa-plus"></i> Crea Nuovo
                        </Button>
                        </h4>
                      <p className="">
                        Qui sotto puoi creare e gestire gli eventi di audio guida smart.
                      </p>
                    </div>
                  </div>
                  </Col>
                </Row>*/}
                {/* tabella utenti */}
                <Row>
                  <Col lg="12">
                    <Card className=" shadow border-0">
                      <CardHeader>
                        <Row>
                          <Col md="2">
                            <CustomInput className="ml-4" type="switch" id="exampleCustomSwitch" name="customSwitch" value={this.state.isAllUsers} checked={this.state.isAllUsers} label="Tutti gli utenti" onChange={this.refreshSubscriptions}/>
                            
                          </Col>
                          <Col md="2">
                            <CustomInput className="ml-4" type="switch" id="exampleCustomSwitch1" name="customSwitch1" value={this.state.showDisabled} checked={this.state.showDisabled} label="Mostra Disabilitati" onChange={(e)=>{this.setState({showDisabled:!this.state.showDisabled})}}/>
                          </Col>
                          <Col md="8" className="text-right">
                            {!this.state.isAllUsers?
                            <span></span>  
                            :
                            <Button color="default" size="sm" outline type="button" onClick={this.toggleModalEdit}>                      
                              Nuovo Abbonamento
                            </Button>
                            }
                          </Col>
                        </Row>  
                      </CardHeader>
                        <CardBody className="p-0">
                        <Table className="mb-0" size="sm" hover striped responsive>{/* size="sm"*/}
                          <thead>
                            <tr>
                              <th></th>
                              <th>#</th>
                              <th>Mail</th>
                              <th>Tipo Abbonamento</th>
                              <th>Costo Mensile</th>
                              <th>Abilitato</th>
                              <th>Data Ultimo Pagamento</th>
                              <th></th>
                              <th>Prossima Scadenza</th>
                              <th>Data Inizio</th>
                              <th>Data Fine</th>
                              <th style={{"minWidth":"130px"}}></th>
                            </tr>
                          </thead>
                          <tbody>
                            {renderedSubscriptions.filter(this.customFilterTBL).map((item,i)=>{
                              return <tr key={i}>
                                  <th>
                                  {item.IDAbb ? (
                                      <Button outline
                                      className=" btn-icon btn-simple"
                                      color="info"
                                      size="sm"
                                      type="button"
                                      onClick={(e)=>{this.toggleModalPagamenti(e,item)}}
                                      >
                                        <i className="fa fa-search"></i> {" "+item.NumPagamenti}
                                      </Button>
                                    ) : <span></span>} 
                                  </th>
                                  <th scope="row">
                                    {item.IDAbb ? (
                                      <small><b>{item.IDAbb}</b></small>
                                    ) : (
                                      <small id={"TooltipAL"+i}><i className="text-red fa fa-exclamation-triangle" aria-hidden="true"></i>
                                      <UncontrolledTooltip placement="right" target={"TooltipAL"+i}>
                                        Abbonamento non esistente
                                      </UncontrolledTooltip>
                                      </small>
                                    )}                                                                      
                                  </th>
                                  <td>                                   
                                    {
                                      item.Mail ? (
                                        <small>{item.Mail}</small>
                                      ) : (
                                        <small className="text-red" id={"TooltipML"+i}><i className="text-red fa fa-exclamation-triangle" aria-hidden="true"></i> Utente non Esistente
                                        <UncontrolledTooltip placement="right" target={"TooltipML"+i}>
                                          Utente che ha sottoscritto un abbonamento non più esistente
                                        </UncontrolledTooltip>
                                        </small>
                                      )} 
                                    
                                    </td>
                                  <td><small>{item.Title}</small></td>
                                  <td><small>{item.PricePerMonth}€</small></td>
                                  <td className="text-center" onClick={(e)=>{this.requestToggleStateAbb(item)}}>{item.isEnabledAbb==1?<i className="fa fa-check text-success"></i>:<i className="fa fa-times text-danger"></i>}</td>
                                  <td>{                                      
                                      item.LastPayedDate?(
                                        <small className="">{moment(item.LastPayedDate).format("DD-MM-YYYY")}</small>
                                      ):(
                                        <span></span>
                                      )
                                  }</td>   
                                  {/* evidenzio se pagato o no  e eventualmente uteten affiliato */}      
                                  <td>
                                  {item.parentID?
                                    <span>
                                       <Button
                                        outline
                                        className=" btn-icon btn-simple"
                                        color="primary"
                                        size="sm"
                                        type="button"
                                        
                                      >
                                        {this.getUserDescrFromID(item.parentID)}
                                      </Button>
                                    </span>:
                                    <span>

                                    </span>}
                                    
                                    {
                                    (item.LastPayedDate!=null) && moment(item.NextRinnovo).diff(moment())>0?(
                                        <Button outline
                                          className=" btn-icon btn-simple"
                                          color="success"
                                          size="sm"
                                          type="button"
                                        >
                                          <i className="fa fa-check"></i> PAGATO 
                                        </Button>
                                      ):(
                                        <span>{moment(item.NextRinnovo).diff(moment())<=0?
                                          <Button outline
                                            className=" btn-icon btn-simple"
                                            color="danger"
                                            size="sm"
                                            type="button"
                                            onClick={this.toggleModalPagamenti}
                                          >
                                            <i className="fa fa-exclamation-triangle"></i> DA PAGARE 
                                          </Button>
                                          :
                                          <span>
                                            <Button outline
                                            className=" btn-icon btn-simple"
                                            color="warning"
                                            size="sm"
                                            type="button"
                                            >
                                              <i className="fa fa-exclamation-triangle"></i> Da Pagare 
                                            </Button>                                            
                                          </span>
                                        }
                                        </span>
                                      )
                                  }</td>                         
                                  <td><small>{moment(item.NextRinnovo).format("DD-MM-YYYY")}</small></td>
                                  <td>{
                                    item.DateStartAbb?(
                                      <small className="">{moment(item.DateStartAbb).format("DD-MM-YYYY")}</small>
                                    ):(
                                      <span></span>
                                    )
                                  }</td>
                                  <td>{
                                    item.ExpireOnAbb?(
                                      <small className="">{moment(item.ExpireOnAbb).format("DD-MM-YYYY")}</small>
                                    ):(
                                      <span></span>
                                    )
                                  }</td>
                                  <td className=" td-actions text-right" style={{minWidth:'150px'}}>
                                    
                                   

                                    {item.LastPayedDate?(
                                      <span></span>
                                    ):(
                                      <Button
                                        outline
                                        className=" btn-icon btn-simple"
                                        color="success"
                                        size="sm"
                                        type="button"
                                        onClick={(e)=>{this.toggleModalEdit(e,item)}}
                                      >
                                        modifica
                                      </Button>
                                    )}

                                    {!item.Mail?(                                      
                                      <Button onClick={(e)=>{this.handleDeleteSub(e,item)}}
                                      outline
                                      className=" btn-icon btn-simple"
                                      color="danger"
                                      size="sm"
                                      type="button"
                                    >
                                      elimina
                                    </Button>
                                    ):(
                                      <span></span>
                                    )}

                                    {(!item.LastPayedDate || moment(item.NextRinnovo).diff(moment())<=0)&& item.Mail?(                                      
                                      <Button onClick={(e)=>{this.handleSollecito(e,item)}}
                                      outline
                                      className=" btn-icon btn-simple"
                                      color="danger"
                                      size="sm"
                                      type="button"
                                    >
                                      sollecita
                                    </Button>
                                    ):(
                                      <span></span>
                                    )}


                                    {item.LastPayedDate && moment(item.NextRinnovo).diff(moment())>=0?(
                                      <Button outline
                                      className=" btn-icon btn-simple"
                                      color="info"
                                      size="sm"
                                      type="button"
                                    >
                                      invia fattura
                                    </Button>
                                    ):(
                                      <span></span>
                                    )}
                                    
                                    

                                   
                                  </td>
                                </tr>
                            })}                                                      
                          </tbody>
                        </Table>
                        </CardBody>
                        <CardFooter>
                          <Nav className="justify-content-center">
                            <Pagination
                                  hideNavigation
                                  activePage={this.state.activePage}                                  
                                  itemsCountPerPage={this.state.itemPerPage}
                                  totalItemsCount={this.state.subscriptions.length}
                                  pageRangeDisplayed={5}
                                  onChange={this.handlePageChange}
                                  itemClass="page-item"
                                  linkClass="page-link"                                 
                              />
                            </Nav>
                        </CardFooter>
                        </Card>
                  </Col>
                </Row>
                </Container>
                </section>

                {/*modal per modifica utente */}              
             <Modal isOpen={this.state.isModalEditOpen} toggle={this.toggleModalEdit} size="lg">
                    <ModalHeader toggle={this.toggleModalEdit}>{this.state.selectedAbb.Mail}</ModalHeader>
                          <Formik
                          enableReinitialize
                          validate={this.validateForm}                       
                          initialValues={{
                            IDUser:this.state.selectedAbb.IDUser,
                            //PayedDate:this.state.selectedAbb.PayedDate|| new Date(), 
                            ExpireOn:this.state.selectedAbb.ExpireOn,
                            PricePerMonth: this.state.selectedAbb.PricePerMonth,
                            Mail:this.state.selectedAbb.Mail,
                            subscriptionID:this.state.selectedAbb.IDSubscriptionType}} 
                          onSubmit={(values,{ setSubmitting }) => {
                                //console.log("values in submit",values)
                                //console.log("this.state",this.state.selectedUser.ID)
                                if(!this.state.selectedAbb.ID){
                                  //console.log("nuovo utente")
                                  this.handleRequestNew(values)
                                }else{
                                  //console.log("Edit utente")
                                  this.handleRequestEdit(values)
                                }
                                
                                //alert("Form is validated! Submitting the form...");
                                //setSubmitting(false); role="form" name="formRegister"
                          }}
                          >{({ errors, touched,handleSubmit,handleChange,isSubmitting }) => (
                           <Fragment>
                            <Form onSubmit={handleSubmit} onChange={handleChange}>
                            <ModalBody>
                             
                                <Row>
                                  {/* utente a cui associare abbonamento, solo se è nuovo */}
                                  <Col md="12">
                                        {!this.state.selectedAbb.ID?
                                        <FormGroup>
                                        <Field name="IDUser">
                                          {({ field, form, meta }) => (
                                              <div> {/* value={this.state.IDMenuCategory} onChange={this.handleUsersLevelChange}*/ }
                                                  <Label><b>Utente</b></Label>
                                                  <Input type="select" {...field} className="form-control-sm">
                                                      <option value="">Seleziona un utente</option>
                                                      {renderedSubscriptions.map((item,i)=>{
                                                          return <option key={i} value={item.IDUser}>{item.Mail} </option>
                                                      })}
                                                  </Input>
                                                  {/*<input type="text" {...field} placeholder="First Name"/>*/}
                                                  {/*meta.touched && meta.error && <div className="error">{meta.error}</div>*/}
                                              </div>
                                          )}                               
                                          </Field>
                                          <ErrorMessage component="div" name="IDUser" className="text-red small"/>
                                          {/*
                                        <Label for="exampleSelect2" >Abbonamento</Label>
                                        <Input type="select" name="select" className="form-control-sm" id="exampleSelect2" value={this.state.subscriptionID} onChange={this.handleSubscriptionChange}>
                                                    {this.state.subscriptions.map((item,i)=>{
                                                    return <option key={i} value={item.ID}>{item.Title} - ({item.PriceMonth} € al mese)</option>
                                                    })}
                                        </Input>
                                                  */}
                                    </FormGroup>  
                                  
                                  :
                                  ''}
                                   </Col>
                                  <Col md="6">
                                  {/*TIPO ABBONAMENTO */}
                                  <FormGroup>
                                      <Field name="subscriptionID">
                                        {({ field, form, meta }) => (
                                            <div> {/* value={this.state.IDMenuCategory} onChange={this.handleUsersLevelChange}*/ }
                                                <Label><b>Abbonamento</b></Label>
                                                <Input type="select" {...field} className="form-control-sm" onChange={(el)=>{this.handleChangeSub(el,form)}}>
                                                    <option value="">Seleziona un abbonamento</option>
                                                    {this.state.subscriptionsAna.map((item,i)=>{
                                                        return <option key={i} value={item.ID}>{item.Title} - ({item.PriceMonth} € per {item.DurationDays} giorni)</option>
                                                    })}
                                                </Input>
                                                {/*<input type="text" {...field} placeholder="First Name"/>*/}
                                                {/*meta.touched && meta.error && <div className="error">{meta.error}</div>*/}
                                            </div>
                                        )}                               
                                        </Field>
                                        <ErrorMessage component="div" name="subscriptionID" className="text-red small"/>
                                        {/*
                                      <Label for="exampleSelect2" >Abbonamento</Label>
                                      <Input type="select" name="select" className="form-control-sm" id="exampleSelect2" value={this.state.subscriptionID} onChange={this.handleSubscriptionChange}>
                                                  {this.state.subscriptions.map((item,i)=>{
                                                  return <option key={i} value={item.ID}>{item.Title} - ({item.PriceMonth} € al mese)</option>
                                                  })}
                                      </Input>
                                                */}
                                  </FormGroup>  
                         
                                  </Col>
                                  <Col md="6">
                                    <FormGroup>                        
                                      <Field name="PricePerMonth">
                                          {({ field, form, meta }) => (
                                                <div>
                                                  <Label><b>Prezzo</b></Label>
                                                    <InputGroup className="input-group-alternative">
                                                        <InputGroupAddon addonType="prepend">
                                                            <InputGroupText>
                                                            <i className="ni ni-money-coins" />
                                                            </InputGroupText>
                                                        </InputGroupAddon>
                                                        <Input {...field} 
                                                            disabled={isSubmitting}
                                                            placeholder="Prezzo"
                                                            type="number"
                                                            autoComplete="off"
                                                        />
                                                    </InputGroup>
                                                    {/*<input type="text" {...field} placeholder="First Name"/>*/}
                                                    {/*meta.touched && meta.error && <div className="error">{meta.error}</div>*/}
                                                </div>
                                          )}                               
                                      </Field>
                                      <ErrorMessage component="div" name="PricePerMonth" className="text-red small"/>
                                    </FormGroup>         
                                  </Col>
                                  
                                <Col md="6">
                                    <FormGroup>                        
                                      <Field name="ExpireOn">
                                          {({ field, form, meta }) => (
                                                <div>
                                                    <Label><b>Data Scadenza</b></Label>
                                                    <InputGroup className="input-group-alternative" >
                                                      <InputGroupAddon addonType="prepend">
                                                        <InputGroupText>
                                                          <i className="ni ni-calendar-grid-58" />
                                                        </InputGroupText>
                                                      </InputGroupAddon>
                                                      {/*JSON.stringify(field)*/}
                                                      {/* {...field} */}
                                                      <ReactDatetime id="dtpck1"
                                                        
                                                        inputProps={{                                              
                                                          placeholder: "Data Scadenza"
                                                        }}
                                                        value = {new Date(field.value)}
                                                        initialValue={new Date(field.value)}
                                                        onChange={(dateFromValue) => {form.setFieldValue('ExpireOn', this.formatDate(dateFromValue))}} 
                                                        locale="it"
                                                        timeConstraints={{minutes:{step:15}}}
                                                        timeFormat={false}
                                                        closeOnSelect={true}
                                                      />
                                                      
                                                    </InputGroup>
                                                    {/*<input type="text" {...field} placeholder="First Name"/>*/}
                                                    {/*meta.touched && meta.error && <div className="error">{meta.error}</div>*/}
                                                </div>
                                          )}                               
                                      </Field>
                                      <ErrorMessage component="div" name="ExpireOn" className="text-red small"/>
                                    </FormGroup>
                                  </Col>
                                  <Col md="6">
                                      {/* contrassegna come pagato inserendo data di pagamento  
                                      <FormGroup>                        
                                      <Field name="PayedDate">
                                          {({ field, form, meta }) => (
                                                <div>
                                                    <Label>                                                                                   
                                                    <Input 
                                                        disabled={isSubmitting}                                                       
                                                        checked={field.value!=null || field.value!=undefined}
                                                        type="checkbox"
                                                        onChange={(e)=>{!e.target.checked?form.setFieldValue('PayedDate',undefined):form.setFieldValue('PayedDate',new Date())}}
                                                        autoComplete="off"
                                                    />
                                                    
                                                    <b>Data di pagamento </b>
                                                    </Label>
                                                   
                                                    <InputGroup className="input-group-alternative" >
                                                      <InputGroupAddon addonType="prepend">
                                                        <InputGroupText>
                                                          <i className="ni ni-calendar-grid-58" />
                                                        </InputGroupText>
                                                      </InputGroupAddon>
                                                    
                                                      <ReactDatetime id="dtpck12"
                                                        
                                                        inputProps={{                                              
                                                          placeholder: "Data Scadenza"
                                                        }}
                                                        value = {new Date(field.value)}
                                                        initialValue={new Date(field.value)}
                                                        onChange={(dateFromValue) => {form.setFieldValue('PayedDate', this.formatDate(dateFromValue))}} 
                                                        locale="it"
                                                        timeConstraints={{minutes:{step:15}}}
                                                        timeFormat={false}
                                                        closeOnSelect={true}
                                                      />
                                                      
                                                    </InputGroup>
                                                   
                                                </div>
                                          )}   

                                      </Field>
                                      <ErrorMessage component="div" name="ExpireOn" className="text-red small"/>
                                    </FormGroup>   
                                    */}  

                                  </Col>
                                </Row>
                                <Row>
                                  
                                  
                                </Row>
                            </ModalBody>
                            <ModalFooter>                              
                              {/* tasti conferma per modifica , inserisci per nuovo e chiudi */}
                              <Button color="secondary" disabled={this.state.isFormValid}  onClick={this.toggleModalEdit}>Annulla</Button>
                              {this.state.selectedAbb.ID?
                              <Button color="primary" type="submit" disabled={(Object.keys(errors).length>0)?true:false}>Modifica</Button>
                              :
                              <Button color="primary" type="submit" disabled={(Object.keys(touched).length==0 || Object.keys(errors).length>0)?true:false}>Inserisci</Button>
                              }

                            </ModalFooter>
                            </Form> 
                            </Fragment>
                          )}


                  
                    </Formik>
                </Modal>

                {/*************       MODAL popup gestione pagamenti        ***************/}
                <Modal isOpen={this.state.isModalPagamentiOpen} toggle={this.toggleModalPagamenti} size="lg">
                    <ModalHeader toggle={this.toggleModalPagamenti}>Pagamenti per {this.state.selectedAbb.Mail} <Badge color="secondary">{this.state.payments.length}</Badge></ModalHeader>
                    <ModalBody className="p-0">
                      <Container fluid>
                        <Row className="mt-1 mb-1">
                          <Col>
                            <CustomInput className="ml-5" type="switch" id="CustomSwitchPay" name="customSwitchPay" label="Mostra tutti" onChange={this.refreshPayments}/>
                          </Col>
                          <Col className="text-right">
                            {/* inserisce nuovo pagamento  */}
                            <Button color="default" size="sm" outline type="button" onClick={this.toggleModalEditPayments}>                      
                              Nuovo Pagamento
                            </Button>
                          </Col>
                        </Row>
                        <Row>
                          {/* tabella con pagamenti   per abbonamento selezionato */}
                          <Table className="mb-0" size="sm" hover striped responsive>{/* size="sm"*/}
                            <thead>
                              <tr>                              
                                <th>#</th>     
                                <th>ID Stripe</th>  
                                <th>Prezzo</th>
                                <th>Data Pagamento</th> 
                                <th>Data Inizio</th>
                                <th>Data Fine</th>
                                <th>Stato</th>
                                <th>Visibile</th>
                              </tr>
                            </thead>
                            <tbody>
                              {this.state.payments.map((item,i)=>{
                                return <tr key={i}>
                                          <td><small>{item.ID}</small></td>
                                          <td><small>{item.PayPalID}</small></td>
                                          <td><small>{item.Price}</small></td> 
                                          <td>
                                            {item.PayedDate?
                                            <small>
                                              {moment(item.PayedDate).format('DD-MM-YYYY')}
                                            </small>
                                            :
                                            <span></span>
                                            }
                                          </td>                                        
                                          <td><small>{moment(item.DateStart).format('DD-MM-YYYY')}</small></td>
                                          <td><small>{moment(item.ExpireOn).format('DD-MM-YYYY')}</small></td>
                                          <td>

                                            {item.PayedDate? 
                                            <Button outline
                                              className=" btn-icon btn-simple"
                                              color="success"
                                              size="sm"
                                              type="button"
                                              onClick={(e)=>{this.togglePaymentState(item)}}
                                            >
                                              <i className="fa fa-check"></i> PAGATO
                                            </Button>:
                                            <Button outline
                                                className=" btn-icon btn-simple"
                                                color="warning"
                                                size="sm"
                                                type="button"
                                                onClick={(e)=>{this.togglePaymentState(item)}}
                                              >
                                                <i className="fa fa-exclamation-triangle"></i> DA PAGARE
                                            </Button>
                                            }
                                          </td>
                                          <td>
                                            {item.isEnabled==1?
                                            <i className="fa fa-check text-success" onClick={(e)=>this.togglePaymentEnabled(item.ID,false)}></i>
                                            :
                                            <i className="fa fa-times text-danger" onClick={(e)=>this.togglePaymentEnabled(item.ID,true)}></i>
                                            }
                                          </td>
                                    </tr>
                              })
                            }
                            </tbody>
                          </Table>
                        </Row>
                        </Container>
                    </ModalBody>
                    <ModalFooter>

                    </ModalFooter>
                </Modal>


        </div>
        )
    }
}

export default AnagraficheUtenti;