//gestione pagamenti con stripe
import API,{refresh, APInoToken, manageError} from './api'
import swal from 'sweetalert';

/**
 * invia richiesta di pagamento a backend
 */
async function chargePayment(objIn){    
    try{    
        //console.log("IDUser",IDUser)
        let data = await API.post('/pay/charge',objIn)       
        return data.data;  
    }catch(err){
        //console.log("err -- - - -",err.response)
        manageError(err.response)

        
        //console.log("error",err.message)
        throw err.response
    }             
} 

/**
 * invia fattura tramite stripe
 */
 async function sendFattura(objIn){    
    try{    
        //console.log("IDUser",IDUser)
        let data = await API.post('/pay/fattura',objIn)       
        return data.data;  
    }catch(err){
        //console.log("err -- - - -",err.response)
        manageError(err.response)

        
        //console.log("error",err.message)
        throw err.response
    }             
} 

export default{
    chargePayment,
    sendFattura
}