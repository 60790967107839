/*!

=========================================================
* Argon Design System React - v1.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-design-system-react
* Copyright 2020 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/argon-design-system-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";
import swal from 'sweetalert2';


// reactstrap components
import {
  Button,
  Card,
  CardBody,
  FormGroup,
  Form,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Container,
  Row,
  Col,  
  Alert
} from "reactstrap";

// core components
import DemoNavbar from "components/Navbars/Header.js";
import SimpleFooter from "components/Footers/Footer.js";
import users from "../helpers/user.js";



let _this;

class ForgotPassword extends React.Component {
  //_this = this;
  constructor(props) {
    super(props)
    this.state = { username: '', password: '' ,errLogin:undefined}
    _this = this;
   
  }

  componentDidMount() {
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
    this.refs.main.scrollTop = 0;

    //elimino token login
    window.localStorage.removeItem('hoppla_token')
  }

  apiReq = (event) => {
    event.preventDefault();    
    users.forgotPassword(this.state.username)
    .then(res=>{
      //mostra messaggio conferma email inviata di controllare mail 
      swal.fire({
          title: "Fatto",
          text: 'Ti abbiamo inviato per mail le istruzioni da seguire per resettare la password!',
          icon: "sucess",
          button: "OK"
      }).then(vals=>{
        if(vals.value){
          _this.props.history.push('/login');
        }
      })

    })
    .catch(err=>{
      console.log("caso catch",err.data.description)
      _this.setState({errLogin:err.data.description})
    })
    
  }
  render() {
    return (
      <>
        <DemoNavbar />
        <main ref="main">
          <section className="section section-shaped section-lg">
            <div className="shape shape-style-1 bg-gradient-default">
              <span />
              <span />
              <span />
              <span />
              <span />
              <span />
              <span />
              <span />
            </div>
            <Container className="pt-lg-7">
              <Row className="justify-content-center">
                <Col lg="5">
                  <Card className="bg-secondary shadow border-0">

                    <CardBody className="px-lg-5 py-lg-5">
                      <div className="text-center text-muted mb-4">
                        <small>Inserisci la tua <b>mail</b> <br/> Riceverai le istruzioni per resettare la password</small>
                      </div>
                      {this.state.errLogin?<Alert color="danger">
                        {this.state.errLogin}
                      </Alert>:''}
                      <Form role="form" >
                        <FormGroup className="mb-3">
                          <InputGroup className="input-group-alternative">
                            <InputGroupAddon addonType="prepend">
                              <InputGroupText>
                                <i className="ni ni-email-83" />
                              </InputGroupText>
                            </InputGroupAddon>
                            <Input placeholder="Email" type="text" onChange={(e) => this.setState({ username: e.target.value })} />
                          </InputGroup>
                        </FormGroup>
                       
                       
                        <div className="text-center">
                          <Button
                            className="btn-block"
                            color="primary"
                            type="submit"
                            onClick={(event) => { this.apiReq(event) }}
                          >
                            Reset Password
                          </Button>
                        </div>
                        {/*this.state.errLogin?<UncontrolledAlert color="danger" fade={false}>
                          
                          <span className="alert-inner--text ml-1">
                            {this.state.errLogin}</span>
    </UncontrolledAlert>:''*/}
                      </Form>
                    </CardBody>
                  </Card>
                  
                </Col>
              </Row>
            </Container>
          </section>
        </main>
        <SimpleFooter />
      </>
    );
  }
}

export default ForgotPassword;
