import API,{refresh, APInoToken, manageError} from './api'
import swal from 'sweetalert';

/**
 * ottiene lista di eventi di utente
 */
async function getEvents(isAll,isCompleted){    
    try{    
        //console.log("IDUser",IDUser)
        let data = await API.get('/evt/events',{params:{isAll,isCompleted}})       
        return data.data;  
    }catch(err){
        //console.log("err -- - - -",err.response)
        manageError(err.response)

        
        //console.log("error",err.message)
        throw err.response
    }             
} 

/**
 * ottiene lista di tutti eventi a sistema
 */
 async function getAllEvents(isAll,isCompleted){    
    try{    
        //console.log("IDUser",IDUser)
        let data = await API.get('/evt/allevents',{params:{isAll,isCompleted}})       
        return data.data;  
    }catch(err){
        //console.log("err -- - - -",err.response)
        manageError(err.response)

        
        //console.log("error",err.message)
        throw err.response
    }             
} 

/**
 * ottiene info di eventi
 */
 async function getStatus(UUID){    
    try{    
        //console.log("IDUser",IDUser)
        let data = await API.get('/evt/status',{params:{UUID}})       
        return data.data;  
    }catch(err){
        //console.log("err -- - - -",err)
        manageError(err.response)
        //console.log("error",err.message)
        throw err.response
    }             
} 

/**
 * elimina un evento
 */
 async function deleteEvent(ID){    
    try{    
        //console.log("IDMENUCAT",IDMenuCategory)
        let data = await API.delete('/evt/event',{params:{ID}})       
        return data.data;  
    }catch(err){
        //console.log("err -- - - -",err)
        manageError(err.response)
        //console.log("error",err.message)
        throw err.response
    }             
} 


/**
 * inserisce un evento 
 */
 
async function insertEvent(evt){    
    try{    
        let data = await API.put('/evt/event',evt)       
        return data.data;  
    }catch(err){
        //console.log("err -- - - -",err)
        manageError(err.response)
        //console.log("error",err.message)
        throw err.response
    }             
} 

/**
 * avvia un evento 
 */
 async function startEvent(evt){    
    try{    
        let data = await API.post('/evt/event',evt)       
        return data.data;  
    }catch(err){
        //console.log("err -- - - -",err)
        manageError(err.response)
        //console.log("error",err.message)
        throw err.response
    }             
} 

/**
 * conclude un evento 
 */
 async function stopEvent(evt){    
    try{    
        let data = await API.patch('/evt/event',evt)       
        return data.data;  
    }catch(err){
        //console.log("err -- - - -",err)
        manageError(err.response)
        //console.log("error",err.message)
        throw err.response
    }             
} 
/**
 * imposta flag isBroadcasterOnLine a db 
 */
 async function toggleBroadcasterOnLine(UUID,flag){    
    try{    
        let data = await API.post('/evt/broadcaster',{UUID,flag})       
        return data.data;  
    }catch(err){
        //console.log("err -- - - -",err)
        manageError(err.response)
        //console.log("error",err.message)
        throw err.response
    }             
} 

export default{
    insertEvent,
    startEvent,
    stopEvent,
    deleteEvent,
    getStatus,
    toggleBroadcasterOnLine,
    getEvents,
    getAllEvents
   
}

