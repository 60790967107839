
import React,{useState,Fragment} from "react";
import { CardElement, useStripe, useElements } from "@stripe/react-stripe-js";
import {Button,Progress,Nav,NavItem,NavLink,Row,Col,TabPane,TabContent,Input,InputGroup, InputGroupAddon} from "reactstrap"
import classnames from 'classnames';

import payHelper from "./payment"
import coupon from "./coupon"

import swal from "sweetalert2"

export const CheckoutForm = (props) => {
  const stripe = useStripe();
  const elements = useElements();
  let LocalProps = props

  //let isDisabled = false;
  //let amount = props.amount
  const [amount,setAmount] = useState(props.amount)
  const [_DurationDays,setDurationDays] = useState(props.DurationDays)
  //console.log("props",props)
  //props.onOk --> callback per ok
  //props.onFailed --> callback per errore 
  //props.description = descrizione dell'abbonamento 
  //props.customer = nome del cliente
  //props.customerMail = mail del cliente

  const [isDisabled, setDisabled] = useState(false);
  const [activeTab, setActiveTab] = useState('1');
  const [couponCode,setCoupon] = useState('')
  const [couponColor,setCouponColor] = useState('primary')
  const [couponObj,setCouponObj] = useState({})
  const [isLoadingCoupon,setIsLoadingCP] = useState(false)

  const toggle = tab => {
    if(activeTab !== tab) setActiveTab(tab);
  }
  

  const handleChangeCoupon = (e)=>{
    //console.log("e",e.target.value)
    setCoupon(e.target.value)
  }

  const checkCoupon = ()=>{
    setIsLoadingCP(true)
    coupon.check({codice:couponCode}).then(rr=>{
      //se ok capisco se 100% allora imposto come bypass pagamento e mostro prezzo a 0 altrimenti applico sconto a prezzo
      console.log("rr",rr)
      if(rr===false){
        setCouponColor('danger')       
      }else{
        setCouponColor('success')
        //capisco che tipo di sconto è
        setCouponObj(rr)
        //se sconto inferiore al 100% riassegno prezzo e riassegno durata
        if(rr.PercentualeSconto<100){
          swal.fire(
            'Valido!',
            'Coupon valido per uno sconto del '+rr.PercentualeSconto+"% , procedi con il metodo di pagamento che preferisci!" ,
            'success'
          )

          //console.log("ricalcolo",amount)
          
                  //10 - 0.25 *10
                  
          let lclamount = parseFloat((amount - (rr.PercentualeSconto/100)*amount).toFixed(2))
          setAmount(lclamount)
          //setDurationDays(rr.Durata)
          //props.amount = 1
          //console.log("ricalcolo 2",amount)
        }
        
      }
    }).catch(err=>{

    }).finally(()=>{
      setIsLoadingCP(false)
    })
  }

  //INVOCATA SOLO SE SCONTO GRATIS 
  const setFreePay = () =>{
    var paymentMethod = {}
    paymentMethod.isTotallyFree = true;
    paymentMethod.couponObj = couponObj
    paymentMethod.amount = 0;
    paymentMethod._description = props.description;
    paymentMethod._customer = props.customer;
    paymentMethod._customerMail = props.customerMail;
    paymentMethod._IDRefAbb = props.IDRefAbb;
    paymentMethod._IDRefPagamento = props.IDRefPagamento; //usato per rferimento id tabella pagamenti per pagamentio di riga già inserita
    paymentMethod._DurationDays = couponObj.Durata//props.DurationDays 
    paymentMethod._isFromRegister = props.isFromRegister //usato per flaggare quando provengo da pagina registra 
    //send token to backend here
    payHelper.chargePayment(paymentMethod).then(ok=>{
        //console.log("tutto ok")
        if(props.onOk)
          props.onOk(ok)
    }).catch(err=>{
        console.log("errore pagamento",err)
        if(props.onFailed)
          props.onFailed(err.data.description)
        else
          console.error(err)
    }).finally(()=>{
      setDisabled(false);
    })
  } 

  const setManualPay = ()=>{
    //costruisco ogtgetto pagamento in caso di pagamento manuale 
    setDisabled(true);
    var objPayment = {
      couponObj:(couponObj.ID?couponObj:undefined),
      amount : amount,
      _description : props.description,
      _customer : props.customer,
      _customerMail :props.customerMail,
      _IDRefAbb : props.IDRefAbb,
      _IDRefPagamento : props.IDRefPagamento, //usato per rferimento id tabella pagamenti per pagamentio di riga già inserita
      _DurationDays : _DurationDays, 
      _isFromRegister: props.isFromRegister, //usat
      _isIBAN:true
    }

    payHelper.chargePayment(objPayment).then(ok=>{
      //console.log("tutto ok")
      if(props.onOk)
        props.onOk(ok)
    }).catch(err=>{
        console.log("errore pagamento",err)
        if(props.onFailed)
          props.onFailed(err.data.description)
        else
          console.error(err)
    }).finally(()=>{
      setDisabled(false);
    })
  }

  const handleSubmit = async (event) => {
    setDisabled(true);
    event.preventDefault();
    const { error, paymentMethod } = await stripe.createPaymentMethod({
      type: "card",
      card: elements.getElement(CardElement),
    });



    if (!error) {
      //console.log("Stripe 23 | token generated!", paymentMethod);
      //var amount = "2234"
      paymentMethod.amount = amount;
      paymentMethod._description = props.description;
      paymentMethod._customer = props.customer;
      paymentMethod._customerMail = props.customerMail;
      paymentMethod._IDRefAbb = props.IDRefAbb;
      paymentMethod._IDRefPagamento = props.IDRefPagamento; //usato per rferimento id tabella pagamenti per pagamentio di riga già inserita
      paymentMethod._DurationDays = props.DurationDays 
      paymentMethod._isFromRegister = props.isFromRegister //usato per flaggare quando provengo da pagina registra 
      //send token to backend here
      payHelper.chargePayment(paymentMethod).then(ok=>{
          //console.log("tutto ok")
          if(props.onOk)
            props.onOk(ok)
      }).catch(err=>{
          console.log("errore pagamento",err)
          if(props.onFailed)
            props.onFailed(err.data.description)
          else
            console.error(err)
      }).finally(()=>{
        setDisabled(false);
      })
    } else {
      setDisabled(false);
      console.log(error.message);
      if(props.onFailed)
        props.onFailed(error.message)
      else
        console.error(error.message);
    }
  };

  return (
    //potrei ritornare qui 2 tab con pagamento carta e pagamento IBAN
    /*
    
    
    
    */
    <Fragment>
       <Nav tabs>
        <NavItem>
          <NavLink
            className={classnames({ active: activeTab === '1' })}
            onClick={() => { toggle('1'); }}
          >
            <b>Carta</b>
          </NavLink>
        </NavItem>
        <NavItem>
          <NavLink
            className={classnames({ active: activeTab === '2' })}
            onClick={() => { toggle('2'); }}
          >
            <b>IBAN</b>
          </NavLink>
        </NavItem>
        <NavItem>
          <NavLink
            className={classnames({ active: activeTab === '3' })}
            onClick={() => { toggle('3'); }}
          >
            <b className="text-success">COUPON</b>
          </NavLink>
        </NavItem>
      </Nav>      
      <TabContent activeTab={activeTab}>
        <TabPane tabId="1">
          <Row>
            <Col sm="12">
              <form onSubmit={handleSubmit}>
                {isDisabled?<Progress animated bar color="info" value="100">Pagamento in corso, non chiudere il popup...</Progress>:<span></span>}
                <CardElement />
                <Button disabled={isDisabled} color="primary" block>Paga</Button>
              </form>             
            </Col>
          </Row>
        </TabPane>
        <TabPane tabId="2">
          <Row>
            <Col sm="12">
              {isDisabled?<Progress animated bar color="info" value="100">Pagamento in corso, non chiudere il popup...</Progress>:<span></span>}
              <p className="mt-1">Fai un bonifico di <b>{amount}</b>€ specificando nome, cognome ed e-mail nella causale e attiveremo l'abbonamento non appena ricevuto il pagamento.</p>
              <p>IBAN: <b>IT39M0306234210000002294778</b></p>
              <Button disabled={isDisabled} color='primary' block onClick={setManualPay}> FATTO</Button>
            </Col>                       
          </Row>
        </TabPane>
        <TabPane tabId="3">
          <Row>
            <Col sm="12">
              <p className="mt-1">Hai un Coupon? Inserisci qui sotto il codice sconto e premi il bottone con il check per validarlo! {couponObj.ID?<b>Il prezzo scontato è: {amount}€</b>:<></>}</p>   
              <InputGroup>
              <InputGroupAddon addonType="append">
                  <Button disabled={isLoadingCoupon} onClick={checkCoupon} color={couponColor}><i className="ni ni-check-bold"></i></Button>
                </InputGroupAddon>
              <Input className="pl-2" onChange={handleChangeCoupon}/>
              {couponObj.PercentualeSconto==100?<InputGroupAddon addonType="prepend">
                <Button color="success" onClick={setFreePay}>Procedi GRATIS</Button>
              </InputGroupAddon>:<></>
              }               
              </InputGroup>
            </Col>
          </Row>
        </TabPane>
      </TabContent>  
      

     
    </Fragment>
  );
};