/*!

=========================================================
* Argon Design System React - v1.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-design-system-react
* Copyright 2020 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/argon-design-system-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";
import swal from 'sweetalert2';


// reactstrap components
import {
  Button,
  Card,
  CardBody,
  FormGroup,
  Form,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Container,
  Row,
  Col,  
  Alert
} from "reactstrap";

// core components
import DemoNavbar from "components/Navbars/Header.js";
import SimpleFooter from "components/Footers/Footer.js";
import users from "../helpers/user.js";



let _this;

class Login extends React.Component {
  //_this = this;
  constructor(props) {
    super(props)
    console.log("props ",props)
    this.state = { username: '', password: '' ,errLogin:undefined, uuid:props.match.params.id, userInfo:{}}
    _this = this;
   
  }

  componentDidMount() {
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
    this.refs.main.scrollTop = 0;

    //elimino token login
    window.localStorage.removeItem('hoppla_token')

    //ottengo info se id passato è valido e mostro info utente in tal caso 
    users.chkLinkConfirmPsw(this.state.uuid).then(result=>{
      //console.log("userinfo ", result)
      this.setState({userInfo:result})
    }).catch(err=>{

    })

  }

  apiReq = (event) => {
    event.preventDefault();    
    users.confirmPassword(this.state.password,this.state.uuid, this.state.userInfo)
    .then(res=>{
      //console.log("caso then su login",res)
      //va su home dashboard
      //_this.props.history.push('/dashboard');
      //popup conferma e poi login 
    
        swal.fire({
            title: "Fatto",
            text: 'Password resettata correttamente',
            icon: "sucess",
            button: "OK"
        }).then(vals=>{
          if(vals.value){
            //console.log("redirect a login")
            _this.props.history.push('/login');
          }
        })
        

    })
    .catch(err=>{
      console.log("caso catch",err.data.description)
      _this.setState({errLogin:err.data.description})
    })
    
  }
  render() {
    return (
      <>
        <DemoNavbar />
        <main ref="main">
          <section className="section section-shaped section-lg">
            <div className="shape shape-style-1 bg-gradient-default">
              <span />
              <span />
              <span />
              <span />
              <span />
              <span />
              <span />
              <span />
            </div>
            <Container className="pt-lg-7">
              <Row className="justify-content-center">
                <Col lg="5">
                  <Card className="bg-secondary shadow border-0">

                    <CardBody className="px-lg-5 py-lg-5">
                      <div className="text-center text-muted mb-4">
                        <p>{this.state.userInfo.Nome} {this.state.userInfo.Cognome} -  <b><small>{this.state.userInfo.Mail}</small></b></p>
                      
                        <small>Inserisci la nuova password</small>
                      </div>
                      {this.state.errLogin?<Alert color="danger">
                        {this.state.errLogin}
                      </Alert>:''}
                      <Form role="form" >
                        <FormGroup className="mb-3">
                          <InputGroup className="input-group-alternative">
                            <InputGroupAddon addonType="prepend">
                              <InputGroupText>
                                <i className="ni ni-lock-circle-open" />
                              </InputGroupText>
                            </InputGroupAddon>
                            <Input placeholder="Password" type="password" onChange={(e) => this.setState({ password1: e.target.value })} />
                          </InputGroup>
                        </FormGroup>
                        <FormGroup>
                          <InputGroup className="input-group-alternative">
                            <InputGroupAddon addonType="prepend">
                              <InputGroupText>
                                <i className="ni ni-lock-circle-open" />
                              </InputGroupText>
                            </InputGroupAddon>
                            <Input
                              placeholder="Conferma Password"
                              type="password"
                              autoComplete="off"
                              onChange={(e) => this.setState({ password: e.target.value })}
                            />
                          </InputGroup>
                        </FormGroup>
                       
                        <div className="text-center">
                          <Button
                            className="btn-block"
                            color="primary"
                            type="submit"
                            disabled={this.state.password!==this.state.password1}
                            onClick={(event) => { this.apiReq(event) }}
                          >
                            Conferma
                          </Button>
                        </div>
                        {/*this.state.errLogin?<UncontrolledAlert color="danger" fade={false}>
                          
                          <span className="alert-inner--text ml-1">
                            {this.state.errLogin}</span>
    </UncontrolledAlert>:''*/}
                      </Form>
                    </CardBody>
                  </Card>
                  <Row className="mt-3">
                   
                  </Row>
                </Col>
              </Row>
            </Container>
          </section>
        </main>
        <SimpleFooter />
      </>
    );
  }
}

export default Login;
