import React from "react";
import {Container,Row,Col} from "reactstrap";

class AdminIndex extends React.Component {
    render() {
        return (
        <div className="position-relative">
            {/* shape Hero */}
            <section className="section section-lg section-shaped pb-50">
              <div className="shape shape-style-1 shape-primary">
                <span />
                <span />
                <span />
                <span />
                <span />
                <span />
                <span />
                <span />
                <span />
              </div>
              <Container fluid className="py-lg-md d-flex" >
                <div className="col px-0">
                  <Row>
                    <Col lg="6">                        
                        <p className="lead text-white">
                         Controlla il portale
                        </p>                        
                    </Col>
                  </Row>
                </div>
              </Container>
              {/* SVG separator */}
              <div className="separator separator-bottom separator-skew">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  preserveAspectRatio="none"
                  version="1.1"
                  viewBox="0 0 2560 100"
                  x="0"
                  y="0"
                >
                  <polygon
                    className="fill-white"
                    points="2560 0 2560 100 0 100"
                  />
                </svg>
              </div>
            </section>
            <section className="section pb-50">
            <Container fluid >
              <Row className="row-grid align-items-center">
                
                <Col className="" lg="6" sm="12">
                  <div className="d-flex px-3">
                    <div>
                      <div className="icon icon-lg icon-shape bg-gradient-white shadow rounded-circle text-primary">
                        <i className="ni ni-building text-primary" />
                      </div>
                    </div>
                    {/*<div className="pl-4">
                      <h4 className="display-3 ">
                          Eventi
                          <Button
                          className="mr-4 mt-2 float-right"
                          color="info"
                          href="#pablo"
                          onClick={e => e.preventDefault()}
                          size="sm"
                        >
                          <i className="fa fa-plus"></i> Crea Nuovo
                        </Button>
                        </h4>
                      <p className="">
                        Qui sotto puoi creare e gestire gli eventi di audio guida smart.
                      </p>
                    </div>*/}
                  </div>
                  </Col>
                </Row>
                </Container>
                </section>
        </div>
        )
    }
}

export default AdminIndex;