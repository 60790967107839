import API,{refresh, APInoToken,manageError} from './api'
import swal from 'sweetalert';

/**
 * ottiene info di utente
 */
async function getUserInfo(){    
    try{    
        let data = await API.post('/auth/verify',{})       
        return data.data.token.decoded;  
    }catch(err){
        //console.log("err -- - - -",err)
        manageError(err.response)
        //console.log("error",err.message)
        throw err.response
    }             
} 

/**
 * fa il login
 */
async function login(user,pass){   
    try{  
        let data = await APInoToken.post("/auth/login", {
            username: user,
            password: pass
        })

        window.localStorage.setItem('hoppla_token',data.data.token)
        //window.location.replace('/dashboard')
        //history.push("/admin");
        //this.handleClick()
        refresh()
    }catch(err){
        throw err.response
    }
           
} 

/*function getTokenInfo(){
    var token = window.localStorage.getItem('hoppla_token')
    if(token)
        return token
    else
        return undefined;
}*/

/**
 * inserisce utente
 */
 async function insert(obj){    
    try{    
        let data = await APInoToken.put('/usr/users',obj)       
        return data.data;  
    }catch(err){
        //console.log("err -- - - -",err)
        manageError(err.response)
        //console.log("error",err.message)
        throw err.response
    }             
}


//
/**
 *  modifica utente da amministratore
 */
 async function edit(obj){    
    try{    
        let data = await APInoToken.post('/usr/users',obj)       
        return data.data;  
    }catch(err){
        //console.log("err -- - - -",err)
        manageError(err.response)
        //console.log("error",err.message)
        throw err.response
    }             
}


/**
 * ottiene info di tutti
 */
 async function getAllUsers(obj){    
    try{    
        let data = await API.get('/usr/users',obj)       
        return data.data;  
    }catch(err){
        //console.log("err -- - - -",err)
        manageError(err.response)
        //console.log("error",err.message)
        throw err.response
    }             
} 


/**
 * ottiene dettaglio di un utente
 */
 async function getDtlUser(IDUser){    
    try{    
        let data = await API.get('/usr/user/'+IDUser)       
        return data.data;  
    }catch(err){
        //console.log("err -- - - -",err)
        manageError(err.response)
        //console.log("error",err.message)
        throw err.response
    }             
} 



/**
 * elimino utentui
 */
async function deleteUser(ID){    
    try{    
        let data = await API.delete('/usr/user',{params:{ID:ID}})       
        return data.data;  
    }catch(err){
        //console.log("err -- - - -",err)
        manageError(err.response)
        //console.log("error",err.message)
        throw err.response
    }             
} 

/**
 * ottiene statistiche di utente come abbonamento in corso, limiti e statistiche varie
 */
 async function getUserStat(obj){    
    try{    
        let data = await API.get('/usr/stat',obj)       
        return data.data;  
    }catch(err){
        //console.log("err -- - - -",err)
        manageError(err.response)
        //console.log("error",err.message)
        throw err.response
    }             
} 

/**
 * resetta la password di unn certo utente , richiede id utente e nuova password 
 */
 async function resetPsw(objIn){    
    try{    
        let data = await API.post('/usr/psw',objIn)       
        return data.data;  
    }catch(err){
        //console.log("err -- - - -",err)
        manageError(err.response)
        //console.log("error",err.message)
        throw err.response
    }             
} 

/**
 * chiede link di reset password per un certo utente  
 */
 
 async function forgotPassword(username){    
    try{    
        let data = await API.post('/auth/forgotpsw',{username})       
        return data.data;  
    }catch(err){
        //console.log("err -- - - -",err)
        manageError(err.response)
        //console.log("error",err.message)
        throw err.response
    }             
} 

/**
 * conferma  cambio password
 */
 
 async function confirmPassword(password,uuid,userInfo){    
    try{    
        let data = await API.post('/auth/confirmpsw',{uuid,password,userInfo})       
        return data.data;  
    }catch(err){
        //console.log("err -- - - -",err)
        manageError(err.response)
        //console.log("error",err.message)
        throw err.response
    }             
} 

/**
 * verifica link cambio password sia corretto
 */
 
 async function chkLinkConfirmPsw(uuid){    
    try{    
        let data = await API.post('/auth/chkpasswordreset',{uuid})       
        return data.data;  
    }catch(err){
        //console.log("err -- - - -",err)
        manageError(err.response)
        //console.log("error",err.message)
        throw err.response
    }             
} 

//
/**
 *  ottengo guide associate a account in corso 
 */
 async function getMyGuides(){    
    try{    
        let data = await API.get('/usr/guides',{})       
        return data.data;  
    }catch(err){
        //console.log("err -- - - -",err)
        manageError(err.response)
        //console.log("error",err.message)
        throw err.response
    }             
} 

/**
 *  inserisce un utente guida associato a museo
 */
 async function insMyGuide(objIn){    
    try{    
        let data = await API.put('/usr/guides',objIn)       
        return data.data;  
    }catch(err){
        //console.log("err -- - - -",err)
        manageError(err.response)
        //console.log("error",err.message)
        throw err.response
    }             
} 

/**
 *  modifica un utente guida associato a museo
 */
 async function editMyGuide(objIn){    
    try{    
        let data = await API.post('/usr/guides',objIn)       
        return data.data;  
    }catch(err){
        //console.log("err -- - - -",err)
        manageError(err.response)
        //console.log("error",err.message)
        throw err.response
    }             
} 

/**
 *  modifica psw di utente guida associata a museo
 */
 async function resetPswMyGuide(objIn){    
    try{    
        let data = await API.post('/usr/guides/psw',objIn)       
        return data.data;  
    }catch(err){
        //console.log("err -- - - -",err)
        manageError(err.response)
        //console.log("error",err.message)
        throw err.response
    }             
} 






export default{
    getUserInfo,
    getUserStat,
    //getTokenInfo,
    login,
    insert,
    edit,
    getAllUsers,
    getDtlUser,
    deleteUser,
    resetPsw,
    forgotPassword,
    chkLinkConfirmPsw,
    confirmPassword,
    //funzioni per guide associate 
    getMyGuides,
    insMyGuide,
    editMyGuide,
    resetPswMyGuide
}


