import API,{refresh, APInoToken,manageError} from './api'
import swal from 'sweetalert';


/**
 * ottiene lista di ticket per un utente
 */
 async function getUserTickets(IDUser){    
    try{    
        //console.log("IDMENUCAT",IDUser)
        let data = await API.get('/tkt/user',{params:{IDUser}})       
        return data.data;  
    }catch(err){
        //console.log("err -- - - -",err)
        manageError(err.response)
        //console.log("error",err.message)
        throw err.response
    }             
} 

/**
 * ottiene lista di ticket di tutti utenti
 */
 async function getAllTickets(){    
    try{    
        //console.log("IDMENUCAT",IDUser)
        let data = await API.get('/tkt',{params:{}})       
        return data.data;  
    }catch(err){
        //console.log("err -- - - -",err)
        manageError(err.response)
        //console.log("error",err.message)
        throw err.response
    }             
} 

/**
 * modifica abbonamento utente
 */
 async function addTicket(obj){    
    try{    
        //console.log("IDMENUCAT",obj)
        let data = await API.put('/tkt',obj)       
        return data.data;  
    }catch(err){
        //console.log("err -- - - -",err)
        manageError(err.response)
        //console.log("error",err.message)
        throw err.response
    }             
} 

/**
 * modifica abbonamento utente
 */
 async function getTktDtl(IDtck){    
    try{    
        //console.log("IDMENUCAT",obj)
        let data = await API.get('/tkt/dtl',{params:{ID:IDtck}})       
        return data.data;  
    }catch(err){
        //console.log("err -- - - -",err)
        manageError(err.response)
        //console.log("error",err.message)
        throw err.response
    }             
}

//risposta a ticket
async function reply(objIn){    
    try{    
        //console.log("IDMENUCAT",obj)
        let data = await API.post('/tkt/dtl',objIn)       
        return data.data;  
    }catch(err){
        //console.log("err -- - - -",err)
        manageError(err.response)
        //console.log("error",err.message)
        throw err.response
    }             
}

//setta stato come chiuso o riapre  changeStatusTkt
async function changeStatusTkt(objIn){    
    try{    
        //console.log("IDMENUCAT",obj)
        let data = await API.post('/tkt/status',objIn)       
        return data.data;  
    }catch(err){
        //console.log("err -- - - -",err)
        manageError(err.response)
        //console.log("error",err.message)
        throw err.response
    }             
}


function getStatusByID(ID){
    if(ID==1){
        return {msg:"in attesa di risposta",color:"warning"}
    }else if(ID==2){
        return {msg:"risposto da E-SOPO",color:"info"}
    }
    else if(ID==3){
        return {msg:"risposto da utente",color:"primary"}
    }
    else if(ID==4){
        return {msg:"chiuso",color:"success"}
    }else{
        return {msg:"in attesa di risposta",color:"warning"}
    }
}


export default{
    getUserTickets,
    getAllTickets,
    getTktDtl,
    addTicket,
    reply,
    getStatusByID,
    changeStatusTkt
}