/*!

=========================================================
* Argon Design System React - v1.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-design-system-react
* Copyright 2020 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/argon-design-system-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";
// nodejs library that concatenates classes
import classnames from "classnames";
import swal from "sweetalert2"

// reactstrap components
import {
  Badge,
  Button,
  Card,
  CardBody,
  FormGroup,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Container,
  Row,
  Col,
  UncontrolledCarousel 
} from "reactstrap";

// core components
import Header from "components/Navbars/Header.js";
import CardsFooter from "components/Footers/Footer.js";

import sys from "../helpers/system";

//importo logo mauto
import mautologo from '../assets/img/MAUTO_WHITE.png'

// index page sections
//import Download from "../IndexSections/Download.js";

import Img1 from '../assets/img/homepageCarousel/1.png'
import Img2 from '../assets/img/homepageCarousel/2.png'
import Img3 from '../assets/img/homepageCarousel/3.png'
import Img4 from '../assets/img/homepageCarousel/4.png'
import Img5 from '../assets/img/homepageCarousel/5.png'
import Img6 from '../assets/img/homepageCarousel/6.png'
import Img7 from '../assets/img/homepageCarousel/7.png'
import Img8 from '../assets/img/homepageCarousel/8.png'
import Img9 from '../assets/img/homepageCarousel/9.png'
import Img10 from '../assets/img/homepageCarousel/10.png'
import Img11 from '../assets/img/homepageCarousel/11.png'

  //definisco items per carousel 
  const items = [
    {
      src: Img1,//'data:image/svg+xml;charset=UTF-8,%3Csvg%20width%3D%22800%22%20height%3D%22400%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20viewBox%3D%220%200%20800%20400%22%20preserveAspectRatio%3D%22none%22%3E%3Cdefs%3E%3Cstyle%20type%3D%22text%2Fcss%22%3E%23holder_15ba800aa1d%20text%20%7B%20fill%3A%23555%3Bfont-weight%3Anormal%3Bfont-family%3AHelvetica%2C%20monospace%3Bfont-size%3A40pt%20%7D%20%3C%2Fstyle%3E%3C%2Fdefs%3E%3Cg%20id%3D%22holder_15ba800aa1d%22%3E%3Crect%20width%3D%22800%22%20height%3D%22400%22%20fill%3D%22%23777%22%3E%3C%2Frect%3E%3Cg%3E%3Ctext%20x%3D%22285.921875%22%20y%3D%22218.3%22%3EFirst%20slide%3C%2Ftext%3E%3C%2Fg%3E%3C%2Fg%3E%3C%2Fsvg%3E',
      altText: 'Slide 1',
      captionText:'',
      //caption: 'Slide 1',
      //header: 'Slide 1 Header',
      key: '1'
    },
    {
      src: Img2,//'data:image/svg+xml;charset=UTF-8,%3Csvg%20width%3D%22800%22%20height%3D%22400%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20viewBox%3D%220%200%20800%20400%22%20preserveAspectRatio%3D%22none%22%3E%3Cdefs%3E%3Cstyle%20type%3D%22text%2Fcss%22%3E%23holder_15ba800aa20%20text%20%7B%20fill%3A%23444%3Bfont-weight%3Anormal%3Bfont-family%3AHelvetica%2C%20monospace%3Bfont-size%3A40pt%20%7D%20%3C%2Fstyle%3E%3C%2Fdefs%3E%3Cg%20id%3D%22holder_15ba800aa20%22%3E%3Crect%20width%3D%22800%22%20height%3D%22400%22%20fill%3D%22%23666%22%3E%3C%2Frect%3E%3Cg%3E%3Ctext%20x%3D%22247.3203125%22%20y%3D%22218.3%22%3ESecond%20slide%3C%2Ftext%3E%3C%2Fg%3E%3C%2Fg%3E%3C%2Fsvg%3E',
      altText: 'Slide 2',
      captionText:'',
      //caption: 'Slide 2',
      //header: 'Slide 2 Header',
      key: '2'
    },
    {
      src: Img3,//'data:image/svg+xml;charset=UTF-8,%3Csvg%20width%3D%22800%22%20height%3D%22400%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20viewBox%3D%220%200%20800%20400%22%20preserveAspectRatio%3D%22none%22%3E%3Cdefs%3E%3Cstyle%20type%3D%22text%2Fcss%22%3E%23holder_15ba800aa21%20text%20%7B%20fill%3A%23333%3Bfont-weight%3Anormal%3Bfont-family%3AHelvetica%2C%20monospace%3Bfont-size%3A40pt%20%7D%20%3C%2Fstyle%3E%3C%2Fdefs%3E%3Cg%20id%3D%22holder_15ba800aa21%22%3E%3Crect%20width%3D%22800%22%20height%3D%22400%22%20fill%3D%22%23555%22%3E%3C%2Frect%3E%3Cg%3E%3Ctext%20x%3D%22277%22%20y%3D%22218.3%22%3EThird%20slide%3C%2Ftext%3E%3C%2Fg%3E%3C%2Fg%3E%3C%2Fsvg%3E',
      altText: 'Slide 3',
      captionText:'',
      //caption: 'Slide 3',
      //header: 'Slide 3 Header',
      key: '3'
    },
    {
      src: Img4,//'data:image/svg+xml;charset=UTF-8,%3Csvg%20width%3D%22800%22%20height%3D%22400%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20viewBox%3D%220%200%20800%20400%22%20preserveAspectRatio%3D%22none%22%3E%3Cdefs%3E%3Cstyle%20type%3D%22text%2Fcss%22%3E%23holder_15ba800aa21%20text%20%7B%20fill%3A%23333%3Bfont-weight%3Anormal%3Bfont-family%3AHelvetica%2C%20monospace%3Bfont-size%3A40pt%20%7D%20%3C%2Fstyle%3E%3C%2Fdefs%3E%3Cg%20id%3D%22holder_15ba800aa21%22%3E%3Crect%20width%3D%22800%22%20height%3D%22400%22%20fill%3D%22%23555%22%3E%3C%2Frect%3E%3Cg%3E%3Ctext%20x%3D%22277%22%20y%3D%22218.3%22%3EThird%20slide%3C%2Ftext%3E%3C%2Fg%3E%3C%2Fg%3E%3C%2Fsvg%3E',
      altText: 'Slide 4',
      captionText:'',
      //caption: 'Slide 3',
      //header: 'Slide 3 Header',
      key: '4'
    },
    {
      src: Img5,//'data:image/svg+xml;charset=UTF-8,%3Csvg%20width%3D%22800%22%20height%3D%22400%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20viewBox%3D%220%200%20800%20400%22%20preserveAspectRatio%3D%22none%22%3E%3Cdefs%3E%3Cstyle%20type%3D%22text%2Fcss%22%3E%23holder_15ba800aa21%20text%20%7B%20fill%3A%23333%3Bfont-weight%3Anormal%3Bfont-family%3AHelvetica%2C%20monospace%3Bfont-size%3A40pt%20%7D%20%3C%2Fstyle%3E%3C%2Fdefs%3E%3Cg%20id%3D%22holder_15ba800aa21%22%3E%3Crect%20width%3D%22800%22%20height%3D%22400%22%20fill%3D%22%23555%22%3E%3C%2Frect%3E%3Cg%3E%3Ctext%20x%3D%22277%22%20y%3D%22218.3%22%3EThird%20slide%3C%2Ftext%3E%3C%2Fg%3E%3C%2Fg%3E%3C%2Fsvg%3E',
      altText: 'Slide 5',
      captionText:'',
      //caption: 'Slide 3',
      //header: 'Slide 3 Header',
      key: '5'
    },
    {
      src: Img6,//'data:image/svg+xml;charset=UTF-8,%3Csvg%20width%3D%22800%22%20height%3D%22400%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20viewBox%3D%220%200%20800%20400%22%20preserveAspectRatio%3D%22none%22%3E%3Cdefs%3E%3Cstyle%20type%3D%22text%2Fcss%22%3E%23holder_15ba800aa21%20text%20%7B%20fill%3A%23333%3Bfont-weight%3Anormal%3Bfont-family%3AHelvetica%2C%20monospace%3Bfont-size%3A40pt%20%7D%20%3C%2Fstyle%3E%3C%2Fdefs%3E%3Cg%20id%3D%22holder_15ba800aa21%22%3E%3Crect%20width%3D%22800%22%20height%3D%22400%22%20fill%3D%22%23555%22%3E%3C%2Frect%3E%3Cg%3E%3Ctext%20x%3D%22277%22%20y%3D%22218.3%22%3EThird%20slide%3C%2Ftext%3E%3C%2Fg%3E%3C%2Fg%3E%3C%2Fsvg%3E',
      altText: 'Slide 6',
      captionText:'',
      //caption: 'Slide 3',
      //header: 'Slide 3 Header',
      key: '6'
    },
    {
      src: Img7,//'data:image/svg+xml;charset=UTF-8,%3Csvg%20width%3D%22800%22%20height%3D%22400%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20viewBox%3D%220%200%20800%20400%22%20preserveAspectRatio%3D%22none%22%3E%3Cdefs%3E%3Cstyle%20type%3D%22text%2Fcss%22%3E%23holder_15ba800aa21%20text%20%7B%20fill%3A%23333%3Bfont-weight%3Anormal%3Bfont-family%3AHelvetica%2C%20monospace%3Bfont-size%3A40pt%20%7D%20%3C%2Fstyle%3E%3C%2Fdefs%3E%3Cg%20id%3D%22holder_15ba800aa21%22%3E%3Crect%20width%3D%22800%22%20height%3D%22400%22%20fill%3D%22%23555%22%3E%3C%2Frect%3E%3Cg%3E%3Ctext%20x%3D%22277%22%20y%3D%22218.3%22%3EThird%20slide%3C%2Ftext%3E%3C%2Fg%3E%3C%2Fg%3E%3C%2Fsvg%3E',
      altText: 'Slide 7',
      captionText:'',
      //caption: 'Slide 3',
      //header: 'Slide 3 Header',
      key: '7'
    },
    {
      src: Img8,//'data:image/svg+xml;charset=UTF-8,%3Csvg%20width%3D%22800%22%20height%3D%22400%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20viewBox%3D%220%200%20800%20400%22%20preserveAspectRatio%3D%22none%22%3E%3Cdefs%3E%3Cstyle%20type%3D%22text%2Fcss%22%3E%23holder_15ba800aa21%20text%20%7B%20fill%3A%23333%3Bfont-weight%3Anormal%3Bfont-family%3AHelvetica%2C%20monospace%3Bfont-size%3A40pt%20%7D%20%3C%2Fstyle%3E%3C%2Fdefs%3E%3Cg%20id%3D%22holder_15ba800aa21%22%3E%3Crect%20width%3D%22800%22%20height%3D%22400%22%20fill%3D%22%23555%22%3E%3C%2Frect%3E%3Cg%3E%3Ctext%20x%3D%22277%22%20y%3D%22218.3%22%3EThird%20slide%3C%2Ftext%3E%3C%2Fg%3E%3C%2Fg%3E%3C%2Fsvg%3E',
      altText: 'Slide 8',
      captionText:'',
      //caption: 'Slide 3',
      //header: 'Slide 3 Header',
      key: '8'
    },
    {
      src: Img9,//'data:image/svg+xml;charset=UTF-8,%3Csvg%20width%3D%22800%22%20height%3D%22400%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20viewBox%3D%220%200%20800%20400%22%20preserveAspectRatio%3D%22none%22%3E%3Cdefs%3E%3Cstyle%20type%3D%22text%2Fcss%22%3E%23holder_15ba800aa21%20text%20%7B%20fill%3A%23333%3Bfont-weight%3Anormal%3Bfont-family%3AHelvetica%2C%20monospace%3Bfont-size%3A40pt%20%7D%20%3C%2Fstyle%3E%3C%2Fdefs%3E%3Cg%20id%3D%22holder_15ba800aa21%22%3E%3Crect%20width%3D%22800%22%20height%3D%22400%22%20fill%3D%22%23555%22%3E%3C%2Frect%3E%3Cg%3E%3Ctext%20x%3D%22277%22%20y%3D%22218.3%22%3EThird%20slide%3C%2Ftext%3E%3C%2Fg%3E%3C%2Fg%3E%3C%2Fsvg%3E',
      altText: 'Slide 9',
      captionText:'',
      //caption: 'Slide 3',
      //header: 'Slide 3 Header',
      key: '9'
    },
    {
      src: Img10,//'data:image/svg+xml;charset=UTF-8,%3Csvg%20width%3D%22800%22%20height%3D%22400%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20viewBox%3D%220%200%20800%20400%22%20preserveAspectRatio%3D%22none%22%3E%3Cdefs%3E%3Cstyle%20type%3D%22text%2Fcss%22%3E%23holder_15ba800aa21%20text%20%7B%20fill%3A%23333%3Bfont-weight%3Anormal%3Bfont-family%3AHelvetica%2C%20monospace%3Bfont-size%3A40pt%20%7D%20%3C%2Fstyle%3E%3C%2Fdefs%3E%3Cg%20id%3D%22holder_15ba800aa21%22%3E%3Crect%20width%3D%22800%22%20height%3D%22400%22%20fill%3D%22%23555%22%3E%3C%2Frect%3E%3Cg%3E%3Ctext%20x%3D%22277%22%20y%3D%22218.3%22%3EThird%20slide%3C%2Ftext%3E%3C%2Fg%3E%3C%2Fg%3E%3C%2Fsvg%3E',
      altText: 'Slide 10',
      captionText:'',
      //caption: 'Slide 3',
      //header: 'Slide 3 Header',
      key: '10'
    },
    {
      src: Img11,//'data:image/svg+xml;charset=UTF-8,%3Csvg%20width%3D%22800%22%20height%3D%22400%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20viewBox%3D%220%200%20800%20400%22%20preserveAspectRatio%3D%22none%22%3E%3Cdefs%3E%3Cstyle%20type%3D%22text%2Fcss%22%3E%23holder_15ba800aa21%20text%20%7B%20fill%3A%23333%3Bfont-weight%3Anormal%3Bfont-family%3AHelvetica%2C%20monospace%3Bfont-size%3A40pt%20%7D%20%3C%2Fstyle%3E%3C%2Fdefs%3E%3Cg%20id%3D%22holder_15ba800aa21%22%3E%3Crect%20width%3D%22800%22%20height%3D%22400%22%20fill%3D%22%23555%22%3E%3C%2Frect%3E%3Cg%3E%3Ctext%20x%3D%22277%22%20y%3D%22218.3%22%3EThird%20slide%3C%2Ftext%3E%3C%2Fg%3E%3C%2Fg%3E%3C%2Fsvg%3E',
      altText: 'Slide 11',
      captionText:'',
      //caption: 'Slide 3',
      //header: 'Slide 3 Header',
      key: '11'
    }

  ];


class Landing extends React.Component {
  //state = {};

  constructor(props){
    super(props)
    this.state = {
      ContactMail:'',
      NameMail:'',
      ContactText:''
    }

    this.requestSendContactMail = this.requestSendContactMail.bind(this)
  }



  componentDidMount() {
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
    this.refs.main.scrollTop = 0;
  }

  requestSendContactMail(e){
    console.log("invio mail: ",this.state)
    const emailTest = /^[^\s@]+@[^\s@]+\.[^\s@]{2,}$/i;

    //controllo campo mail sia corretto altrimenti mostro errore 
    if(!emailTest.test(this.state.ContactMail)){
      //mostro errore mail non valida
      swal.fire( 
        'Attenzione!',
        'Inserisci una mail valida!',
        'warning')
    }else{
      //invio a backend richiesta mail
      sys.sendContactMail(this.state.ContactMail,this.state.NameMail,this.state.ContactText).then(res=>{
        //svuoto campi state per messaggio 
        this.setState({ContactMail:''})
        this.setState({NameMail:''})
        this.setState({ContactText:''})

        //popup invio effettuato correttametne 
        swal.fire( 
          'Inviato!',
          'Messaggio inviato correttamente, ti risponderemo appena possibile',
          'success')
      }).catch(err=>{
        //pannello errore 
      })
    }


    
  }


  render() {
    return (
      <>
        <Header />
        <main ref="main">
          <div className="position-relative">
            {/* shape Hero */}
            <section className="section section-lg section-shaped pb-250">
              <div className="shape shape-style-1 shape-primary">
                <span />
                <span />
                <span />
                <span />
                <span />
                <span />
                <span />
                <span />
                <span />
              </div>
              <Container className="py-lg-md d-flex">
                <div className="col px-0">
                  <Row>
                    <Col lg="12" sm="12" xs="12">
                      <h1 className="display-3 text-white">
                        
                        <span>La Radioguida sul tuo smartphone.</span>
                      </h1>
                      <p className="lead text-white mt-0">
                      <img src={mautologo} className="img-fluid" style={{width:'60px'}}></img> In collaborazione con il Museo Nazionale dell'automobile di Torino
                      </p>
                      <p className="lead text-white mt-0">
                        <Row>
                          <Col xs={12} md={6} className="mt-1">
                            <div style={{'background': 'rgba(0, 0, 0,0.2)','border-radius':'4px','padding':'5px'}}>
                            <b>Come si usa E-SOPO?</b>
                            <ol class="list-group list-group-numbered ml-4">
                              <li class="small">Crea il tuo evento</li>
                              <li class="small">Clicca sul tasto AVVIA e inserisci il tuo nome per dare inizio allo streaming</li>
                              <li class="small">Clicca su QR code e fallo inquadrare al visitatore</li>
                            </ol>
                            </div>
                          </Col>
                          <Col xs={12} md={6} className="mt-1">
                            <div style={{'background': 'rgba(0, 0, 0,0.2)','border-radius': '4px','padding':'5px'}}>
                            <b>E il visitatore?</b>
                            <ol class="list-group list-group-numbered ml-4">
                              <li class="small">Inquadra il QR code dallo smartphone della guida</li>
                              <li class="small">Inserisce il proprio nome</li>
                              <li class="small">Se ha qualche domanda, potrà utilizzare il tasto FAI UNA DOMANDA nella chat diretta con la guida</li>
                            </ol>
                            </div>
                          </Col>
                        </Row>
                      </p>
                      
                      {/*<p className="lead text-white">
                        Stanco di usare dispositivi OBSOLETI? Non vuoi sanificare e caricare ad ogni visita i tuoi dispositivi?
                        Abbiamo la soluzione per te: un servizio on-line che ti permette di svolgere la tua visita guidata senza
                        bisogno di scomodi altoparlanti o altri dispositivi eccetto che il tuo smartphone!
                      </p>*/}
                      <div className="btn-wrapper">
                        {/*<Button
                          className="btn-icon mb-3 mb-sm-0"
                          color="info"
                          href=""
                        >                         
                          <span className="btn-inner--text">Come Funziona?</span>
                        </Button>
                        */}
                        
                        
                        <Button
                          className="btn-white btn-icon mb-3 mb-sm-0 ml-1 d-inline-block d-sm-none"
                          color="default"
                          href="/#/login"
                        >
                          <span className="btn-inner--icon mr-1">
                            <i className="fa fa-sign-in" />
                          </span>
                          <span className="btn-inner--text">
                            Login
                          </span>
                        </Button>
                        <Button
                          className="btn-white btn-icon mb-3 mb-sm-0 ml-1 d-inline-block d-sm-none"
                          color="default"
                          href="/#/register"
                        >
                          <span className="btn-inner--icon mr-1">
                            <i className="ni ni-cloud-download-95" />
                          </span>
                          <span className="btn-inner--text">
                            Iscriviti
                          </span>
                        </Button>
                        
                      </div>
                    </Col>
                    {/*
                    <Col lg="4" sm="2" xs="2">
                      <img src={mautologo} className="img-fluid" style={{width:'100px',float:'right'}}></img>
                    </Col>
                    */}
                  </Row>
                </div>
              </Container>
              {/* SVG separator */}
              <div className="separator separator-bottom separator-skew">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  preserveAspectRatio="none"
                  version="1.1"
                  viewBox="0 0 2560 100"
                  x="0"
                  y="0"
                >
                  <polygon
                    className="fill-white"
                    points="2560 0 2560 100 0 100"
                  />
                </svg>
              </div>
            </section>
            {/* 1st Hero Variation */}
          </div>
          <section className="section section-lg pt-lg-0 mt--200">
            <Container>
             
              <Row className="justify-content-center">
                <Col lg="12">
                <Row className="row-grid">
                    <Col lg="4">
                      <Card className="card-lift--hover shadow border-0">
                        <CardBody className="py-4">
                          {/*<div className="icon icon-shape icon-shape-warning rounded-circle mb-4">
                            <i className="ni ni-palette" />
                          </div>
                          */}
                          <h6 className="text-primary text-center text-uppercase display-4">
                            ONE
                          </h6>
                          <p className="description mt-3">
                            Per <b className="font-weight-bold">Guide</b> Freelance! Scegli il pacchetto con un numero prefissato di eventi e inizia la tua guida.
                          </p> 
                          <div className="description mt-3">
                              <ul className="list-group list-group-flush">
                                <li className="list-group-item p-2"><span className="font-weight-bold">1 Evento </span><h5 className="float-right mb-0"><Badge className="float-right" pill color="primary">4,99 € </Badge></h5></li>
                                <li className="list-group-item p-2"><span className="font-weight-bold">10 Eventi </span><h5 className="float-right mb-0"><Badge className="float-right" pill color="secondary">44,99 €</Badge></h5></li> 
                                <li className="list-group-item p-2"><span className="font-weight-bold">20 Eventi </span><h5 className="float-right mb-0"><Badge className="float-right" pill color="secondary">79,99 €</Badge></h5></li>                              
                                
                              </ul>
                            </div>                              
                          <Button
                            className="mt-4"
                            color="primary"
                            href="/#/register"                            
                          >
                            Iscriviti
                          </Button>
                          
                        </CardBody>
                      </Card>
                    </Col>
                    <Col lg="4">
                      <Card className="card-lift--hover shadow border-0">
                        <CardBody className="py-4">
                          {/*<div className="icon icon-shape icon-shape-primary rounded-circle mb-4">
                            <i className="ni ni-spaceship" />
                          </div>
                          */}
                          <h6 className="text-primary  text-center text-uppercase display-4">
                            PREMIUM PACK
                          </h6>
                            <p className="description mt-3">
                            Per <b className="font-weight-bold">Musei, Agenzie di Viaggio e di Guide</b>, Fino a 10 utenti. Scegli il pacchetto più adatto alle tue esigenze!
                            </p>    
                            <div className="description mt-3">
                              <ul className="list-group list-group-flush">
                                <li className="list-group-item p-2"><span className="font-weight-bold">25 Eventi </span><h5 className="float-right mb-0"><Badge className="float-right" pill color="secondary">79,99 € </Badge></h5></li>
                                <li className="list-group-item p-2"><span className="font-weight-bold">50 Eventi </span><h5 className="float-right mb-0"><Badge className="float-right" pill color="secondary">149,00 €</Badge></h5></li> 
                                <li className="list-group-item p-2"><span className="font-weight-bold">100 Eventi </span><h5 className="float-right mb-0"><Badge className="float-right" pill color="secondary">249,99 €</Badge></h5></li>                       
                               
                              </ul>
                            </div>                       
                          <Button
                            className="mt-4"
                            color="primary"
                            href="/#/register"                            
                          >
                            Iscriviti
                          </Button>
                        </CardBody>
                      </Card>
                    </Col>
                    <Col lg="4">
                      <Card className="card-lift--hover shadow border-0">
                        <CardBody className="py-4">
                          {/*<div className="icon icon-shape icon-shape-primary rounded-circle mb-4">
                            <i className="ni ni-spaceship" />
                          </div>*/}
                          <h6 className="text-primary  text-center text-uppercase display-4">
                            PREMIUM UNLIMITED
                          </h6>
                          <p className="description mt-3">
                            Per <b className="font-weight-bold">Musei, Agenzie di Viaggio e di Guide</b>, Fino a 10 utenti. EVENTI ILLIMITATI per un periodo di 6 mesi o 1 anno!
                          </p>     
                          <div className="description mt-3">
                              <ul className="list-group list-group-flush">
                                <li className="list-group-item p-2"><span className="font-weight-bold">6 Mesi </span><h5 className="float-right mb-0"><Badge className="float-right" pill color="secondary">150,00 € <small>(al mese)</small></Badge></h5></li>
                                <li className="list-group-item p-2"><span className="font-weight-bold">1 Anno </span><h5 className="float-right mb-0"><Badge className="float-right" pill color="secondary">120,00 € <small>(al mese)</small></Badge></h5></li> 
                                {/*
                                <li className="list-group-item p-2"><b>6 Mesi </b><span className="display-4 float-right"><Badge className="float-right" pill color="secondary">150,00 € <small>(al mese)</small></Badge></span></li>
                                <li className="list-group-item p-2"><b>1 Anno </b><span className="display-4 float-right"><Badge className="float-right" pill color="secondary">120,00 € <small>(al mese)</small></Badge></span></li> 
                                */}              
                              </ul>
                            </div>                        
                          <Button
                            className="mt-4"
                            color="primary"
                            href="/#/register"                            
                          >
                            Iscriviti
                          </Button>
                        </CardBody>
                      </Card>
                    </Col>
                    
                  </Row>
                   {/*
                  <Row className="row-grid">
                    <Col lg="4">
                      <Card className="card-lift--hover shadow border-0">
                        <CardBody className="py-5">
                          <div className="icon icon-shape icon-shape-primary rounded-circle mb-4">
                            <i className="ni ni-check-bold" />
                          </div>
                          <h6 className="text-primary text-uppercase">
                            Iscriviti Gratuitamente
                          </h6>
                          <p className="description mt-3">
                            Puoi provare per 5 giorni GRATUITAMENTE la piattaforma! Quando 
                            hai deciso puoi scegliere il piano più adatto alle tue esigenze.
                          </p>                         
                          <Button
                            className="mt-4"
                            color="primary"
                            href="#pablo"
                            onClick={e => e.preventDefault()}
                          >
                            Vedi gli abbonamenti
                          </Button>
                        </CardBody>
                      </Card>
                    </Col>
                    <Col lg="4">
                      <Card className="card-lift--hover shadow border-0">
                        <CardBody className="py-5">
                          <div className="icon icon-shape icon-shape-success rounded-circle mb-4">
                            <i className="ni ni-istanbul" />
                          </div>
                          <h6 className="text-success text-uppercase">
                            Crea il tuo evento
                          </h6>
                          <p className="description mt-3">
                            In pochi click sarai in grado di creare una visita guidata virtuale 
                            e condividere il link con i tuoi amici. Non c'è bisogno di app, un browser è sufficiente.
                          </p>                          
                          <Button
                            className="mt-4"
                            color="success"
                            href="#pablo"
                            onClick={e => e.preventDefault()}
                          >
                            Guarda il tutorial
                          </Button>
                        </CardBody>
                      </Card>
                    </Col>
                    <Col lg="4">
                      <Card className="card-lift--hover shadow border-0">
                        <CardBody className="py-5">
                          <div className="icon icon-shape icon-shape-warning rounded-circle mb-4">
                            <i className="ni ni-planet" />
                          </div>
                          <h6 className="text-warning text-uppercase">
                            TRASMETTI
                          </h6>
                          <p className="description mt-3">
                            Hoppla utilizza i più moderni protocolli di trasmissione audio video per
                            un esperienza fluida e con latenze prossime allo 0. 
                          </p>                          
                          <Button
                            className="mt-4"
                            color="warning"
                            href="#pablo"
                            onClick={e => e.preventDefault()}
                          >
                            Scopri come
                          </Button>
                        </CardBody>
                      </Card>
                    </Col>
                  </Row>
                  */}
                </Col>
              </Row>
              
            </Container>
          </section>
          <section className="section section-lg pt-0">
            <Container>
              <Row className="row-grid align-items-center">
                <Col className="order-md-2" md="12">
                  {/* VIDEO YOUTUBE EMBEDDATO  */}
                  <div className="video-responsive">
                    <iframe
                      width="853"
                      height="480"
                      src={`https://www.youtube.com/embed/31KzBMmqUig`}
                      frameBorder="0"
                      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                      allowFullScreen
                      title="Embedded youtube"
                    />
                </div>
                </Col>
              </Row>
            </Container>
          </section>
          {/*<section className="section section-lg">
            <Container>
              <Row className="row-grid align-items-center">
                <Col className="order-md-2" md="6">
                  <img
                    alt="..."
                    className="img-fluid floating"
                    src={require("assets/img/theme/promo-1.png")}
                  />
                </Col>
                <Col className="order-md-1" md="6">
                  <div className="pr-md-5">
                    <div className="icon icon-lg icon-shape icon-shape-success shadow rounded-circle mb-5">
                      <i className="ni ni-settings-gear-65" />
                    </div>
                    <h3>Non ti serve l' APP!</h3>
                    <p>
                      Per trasmettere e ascoltare il tuo browser è più che suffficiente. Puoi accedere al servizio da qualsiasi tipo di dispositivo!
                    </p>
                    <ul className="list-unstyled mt-5">
                      <li className="py-2">
                        <div className="d-flex align-items-center">
                          <div>
                            <Badge
                              className="badge-circle mr-3"
                              color="success"
                            >
                              <i className="ni ni-settings-gear-65" />
                            </Badge>
                          </div>
                          <div>
                            <h6 className="mb-0">
                              Responsive
                            </h6>
                          </div>
                        </div>
                      </li>
                      <li className="py-2">
                        <div className="d-flex align-items-center">
                          <div>
                            <Badge
                              className="badge-circle mr-3"
                              color="success"
                            >
                              <i className="ni ni-html5" />
                            </Badge>
                          </div>
                          <div>
                            <h6 className="mb-0">Testato su: Chrome, Firefox, Edge, Opera...</h6>
                          </div>
                        </div>
                      </li>
                      <li className="py-2">
                        <div className="d-flex align-items-center">
                          <div>
                            <Badge
                              className="badge-circle mr-3"
                              color="success"
                            >
                              <i className="ni ni-satisfied" />
                            </Badge>
                          </div>
                          <div>
                            <h6 className="mb-0">
                              Veloce e facile da utilizzare
                            </h6>
                          </div>
                        </div>
                      </li>
                    </ul>
                  </div>
                </Col>
              </Row>
            </Container>
          </section>*/}
          <section className="section section-lg bg-secondary" id="infohowto">
            <Container>
              <Row className="justify-content-center text-center">
                <Col>
                  <UncontrolledCarousel items={items} />
                </Col>
              </Row>
              </Container>
            </section>

          {/*
          <section className="section section-lg bg-secondary">
            <Container>
              <Row className="justify-content-center text-center mb-lg">
                <Col lg="8">
                  <h2 className="display-3">Chi Siamo?</h2>
                  <p className="lead text-muted">
                   Un gruppo di amici, a cui la quarantena ha stimolato le idee e ha deciso di realizzare
                   questo servizio per dare un aiuto concreto alla cultura.
                  </p>
                </Col>
              </Row>
              <Row className="justify-content-center">
                <Col className="mb-5 mb-lg-0" lg="4" md="6">
                  <div className="px-4">
                    <img
                      alt="..."
                      className="rounded-circle img-center img-fluid shadow shadow-lg--hover"
                      src={require("assets/img/theme/team-1-800x800.jpg")}
                      style={{ width: "200px" }}
                    />
                    <div className="pt-4 text-center">
                      <h5 className="title">
                        <span className="d-block mb-1">Alberto</span>
                        <small className="h6 text-muted">Web Developer</small>
                      </h5>
                      <div className="mt-3">
                        <Button
                          className="btn-icon-only rounded-circle"
                          color="warning"
                          href="#pablo"
                          onClick={e => e.preventDefault()}
                        >
                          <i className="fa fa-twitter" />
                        </Button>
                        <Button
                          className="btn-icon-only rounded-circle ml-1"
                          color="warning"
                          href="#pablo"
                          onClick={e => e.preventDefault()}
                        >
                          <i className="fa fa-facebook" />
                        </Button>
                        <Button
                          className="btn-icon-only rounded-circle ml-1"
                          color="warning"
                          href="#pablo"
                          onClick={e => e.preventDefault()}
                        >
                          <i className="fa fa-dribbble" />
                        </Button>
                      </div>
                    </div>
                  </div>
                </Col>
                <Col className="mb-5 mb-lg-0" lg="4" md="6">
                  <div className="px-4">
                    <img
                      alt="..."
                      className="rounded-circle img-center img-fluid shadow shadow-lg--hover"
                      src={require("assets/img/theme/team-2-800x800.jpg")}
                      style={{ width: "200px" }}
                    />
                    <div className="pt-4 text-center">
                      <h5 className="title">
                        <span className="d-block mb-1">Andrea</span>
                        <small className="h6 text-muted">
                          Marketing Strategist
                        </small>
                      </h5>
                      <div className="mt-3">
                        <Button
                          className="btn-icon-only rounded-circle"
                          color="primary"
                          href="#pablo"
                          onClick={e => e.preventDefault()}
                        >
                          <i className="fa fa-twitter" />
                        </Button>
                        <Button
                          className="btn-icon-only rounded-circle ml-1"
                          color="primary"
                          href="#pablo"
                          onClick={e => e.preventDefault()}
                        >
                          <i className="fa fa-facebook" />
                        </Button>
                        <Button
                          className="btn-icon-only rounded-circle ml-1"
                          color="primary"
                          href="#pablo"
                          onClick={e => e.preventDefault()}
                        >
                          <i className="fa fa-dribbble" />
                        </Button>
                      </div>
                    </div>
                  </div>
                </Col>
                <Col className="mb-5 mb-lg-0" lg="4" md="6">
                  <div className="px-4">
                    <img
                      alt="..."
                      className="rounded-circle img-center img-fluid shadow shadow-lg--hover"
                      src={require("assets/img/theme/team-3-800x800.jpg")}
                      style={{ width: "200px" }}
                    />
                    <div className="pt-4 text-center">
                      <h5 className="title">
                        <span className="d-block mb-1">Sara</span>
                        <small className="h6 text-muted">Platform Administrator</small>
                      </h5>
                      <div className="mt-3">
                        <Button
                          className="btn-icon-only rounded-circle"
                          color="info"
                          href="#pablo"
                          onClick={e => e.preventDefault()}
                        >
                          <i className="fa fa-twitter" />
                        </Button>
                        <Button
                          className="btn-icon-only rounded-circle ml-1"
                          color="info"
                          href="#pablo"
                          onClick={e => e.preventDefault()}
                        >
                          <i className="fa fa-facebook" />
                        </Button>
                        <Button
                          className="btn-icon-only rounded-circle ml-1"
                          color="info"
                          href="#pablo"
                          onClick={e => e.preventDefault()}
                        >
                          <i className="fa fa-dribbble" />
                        </Button>
                      </div>
                    </div>
                  </div>
                </Col>
                
              </Row>
            </Container>
          </section>
          */}
          <section className="section section-lg pt-0 bg-secondary">
            <Container>
              <Card className="bg-gradient-warning shadow-lg border-0">
                <div className="p-5">
                  <Row className="align-items-center">
                    <Col lg="8">
                      <h3 className="text-white">
                        Assistenza
                      </h3>
                      <p className="lead text-white mt-3">
                        Se dovessi trovare qualche malfunzionamento ti preghiamo di segnalarcelo.
                      </p>
                    </Col>
                    <Col className="ml-lg-auto" lg="3">
                      <Button
                        block
                        className="btn-white"
                        color="default"
                        href="mailto:info@e-sopo.com"
                        size="lg"
                      >
                        Apri Ticket
                      </Button>
                    </Col>
                  </Row>
                </div>
              </Card>
            </Container>
          </section>
          <section className="section section-lg bg-gradient-default">
            <Container className="pt-lg pb-100">
              
             
            </Container>
            {/* SVG separator */}
            <div className="separator separator-bottom separator-skew zindex-100">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                preserveAspectRatio="none"
                version="1.1"
                viewBox="0 0 2560 100"
                x="0"
                y="0"
              >
                <polygon
                  className="fill-white"
                  points="2560 0 2560 100 0 100"
                />
              </svg>
            </div>
          </section>
          <section className="section section-lg pt-lg-0 section-contact-us">
            <Container>
              <Row className="justify-content-center mt--300">
                <Col lg="8">
                  <Card className="bg-gradient-secondary shadow">
                    <CardBody className="p-lg-5">
                      <h4 className="mb-1">Interessante?</h4>
                      <p className="mt-0">
                        Contattaci compilando il form qui sotto per ogni curiosità!
                      </p>
                      <FormGroup
                        className={classnames("mt-5", {
                          focused: this.state.nameFocused
                        })}
                      >
                        <InputGroup className="input-group-alternative">
                          <InputGroupAddon addonType="prepend">
                            <InputGroupText>
                              <i className="ni ni-user-run" />
                            </InputGroupText>
                          </InputGroupAddon>
                          <Input
                            placeholder="Il tuo Nome"
                            type="text"
                            value={this.state.NameMail}
                            onFocus={e => this.setState({ nameFocused: true })}
                            onBlur={e => this.setState({ nameFocused: false })}
                            onChange={e=>{ e.persist();this.setState({NameMail:e.target.value})}}
                          />
                        </InputGroup>
                      </FormGroup>
                      <FormGroup
                        className={classnames({
                          focused: this.state.emailFocused
                        })}
                      >
                        <InputGroup className="input-group-alternative">
                          <InputGroupAddon addonType="prepend">
                            <InputGroupText>
                              <i className="ni ni-email-83" />
                            </InputGroupText>
                          </InputGroupAddon>
                          <Input
                            placeholder="Indirizzo mail"
                            type="email"
                            onFocus={e => this.setState({ emailFocused: true })}
                            onBlur={e => this.setState({ emailFocused: false })}
                            value={this.state.ContactMail}
                            onChange={e=>{ e.persist();this.setState({ContactMail:e.target.value})}}
                          />
                        </InputGroup>
                      </FormGroup>
                      <FormGroup className="mb-4">
                        <Input
                          className="form-control-alternative"
                          cols="80"
                          name="name"
                          placeholder="Scrivi un messaggio..."
                          value={this.state.ContactText}
                          onChange={e=>{ e.persist(); this.setState({ContactText:e.target.value})}}
                          rows="4"
                          type="textarea"
                        />
                      </FormGroup>
                      <div>
                        <Button
                          block
                          className="btn-round"
                          color="default"
                          size="lg"
                          type="button"
                          disabled={!this.state.ContactText || this.state.ContactText=='' || !this.state.ContactMail || this.state.ContactMail=='' || !this.state.NameMail || this.state.NameMail==''}
                          onClick={e=>{this.requestSendContactMail(e)}}
                        >
                          Invia
                        </Button>
                      </div>
                    </CardBody>
                  </Card>
                </Col>
              </Row>
            </Container>
          </section>
          {/*<Download />*/}
        </main>
        <CardsFooter />
      </>
    );
  }
}

export default Landing;
