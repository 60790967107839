/*!

=========================================================
* Argon Design System React - v1.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-design-system-react
* Copyright 2020 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/argon-design-system-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";
// nodejs library that concatenates classes
//import classnames from "classnames";
import ReactDatetime from "react-datetime";
import QRCode from "qrcode.react";

import '../../../src/assets/css/custom.css';
import 'moment/locale/it';
import moment from "moment"

import TimeField from 'react-simple-timefield';

import {NavLink} from 'react-router-dom'
// reactstrap components
import {
  
  Button,
  Container,
  Row,
  Col,
  Card,
  CardBody,
  CardFooter,
  Form,
  FormGroup,
  Input,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  Modal,
  ModalFooter,
  ModalHeader,
  ModalBody,
  UncontrolledTooltip
} from "reactstrap";


//importo helpers per utente
import evt from "../../helpers/events";
import users from "../../helpers/user";

import {Formik,Field,ErrorMessage} from "formik";
import swal from "sweetalert2"
import { isThisTypeNode } from "typescript";


class Landing extends React.Component {
  constructor(props){
    super(props)
    this.state = {
      isModalOpen: false,
      userInfo:{},
      isGuest:false,
      events:[],
      isModalQRCode:false,
      URLQRcode:''

    };

    this.toggleModal = this.toggleModal.bind(this);
    this.toggleModalQRCode = this.toggleModalQRCode.bind(this)
    this.formatDate = this.formatDate.bind(this)
    this.refreshEvents = this.refreshEvents.bind(this)
    this.handleDelete = this.handleDelete.bind(this)
    this.handleStart = this.handleStart.bind(this)
    this.handleStop = this.handleStop.bind(this)
    //this.validateForm = this.validateForm.bind(this)

  }
  
  componentDidMount() {
    //ottengo info di utente
    users.getUserInfo().then(data=>{
      //console.log("res userinfo : ",data)
      this.setState({userInfo:data})

      this.setState({isGuest:data.IDMenuCategory==5 || data.parentID!=null })
    }).catch(err=>{
      //console.log("caso errore",err)
    })
   
      /*
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
    this.refs.main.scrollTop = 0;*/
    this.refreshEvents()
  }


  //gestiione modal per creazione evento
  toggleModal(e){
    e.preventDefault()
    this.setState({isModalOpen:!this.state.isModalOpen})
  }

  refreshEvents(){
    //ottengo solo eventi non completati
    evt.getEvents(undefined,false).then(res=>{
      console.log("res",res)
      this.setState({events:res})
     
      
    }).catch(err=>{
      console.log("err",err)
    })
  }

  //crea evento 
  insertEvent(vals){
    //chiudo popup 
    this.setState({isModalOpen:false})
    //imposto ora e minuti su campo data 
    //vals.dateFrom = moment(vals.dateFrom+" "+vals.ore+":"+vals.minuti).toISOString()

    vals.dateFrom = moment(vals.dateFrom+" "+vals.oreminuti).toISOString()


    //console.log("in insert events",vals)
    //chiamo api su server e aggiorno lista eventi 
    evt.insertEvent(vals).then(res=>{
      //console.log("res",res)
      //this.setState({events:res})
      swal.fire(
        'Inserito!',
        'L evento è stato inserito correttamente',
        'success'
      )
      this.refreshEvents()
    }).catch(err=>{
      console.log("err",err)
    })
  }

  //gestisce eliminazione evento
  handleDelete(e, evtIn){
    //messaggio conferma
    swal.fire({
      title: 'Confermi?',
      text: "Stai per eliminare l'evento "+evtIn.Title ,
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Conferma'
    }).then((result) => {
      if (result.isConfirmed) {
        //console.log("e",e)
        //console.log("ID",ID)
        evt.deleteEvent(evtIn.ID).then(res=>{
          //console.log("res",res)
          //this.setState({users:res})
          swal.fire(
            'Eliminato!',
            'L evento è stato eliminato',
            'success'
          )
          this.refreshEvents()
        }).catch(err=>{
          console.log("err",err)
        })
      }
    })
  }

  //gestisce start evento
  handleStart(e, evtIn){
    //messaggio conferma
    swal.fire({
      title: 'Confermi?',
      html: "Stai per avviare l'evento "+evtIn.Title +" <br/><b>Durata evento: 2h 30'</b>",
      icon: 'info',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Conferma'
    }).then((result) => {
      if (result.isConfirmed) {
        //console.log("e",e)
        //console.log("ID",ID)
        evt.startEvent({UUID:evtIn.UUID}).then(res=>{
          //console.log("res",res)
          //this.setState({users:res})
          swal.fire({
            title:'Avviato!',
            text:'L\'evento è stato avviato, potrai accedere allo streaming cliccando sul bottone "vai allo streaming" oppure dalla lista degli eventi cliccando su "clicca qui per lo streaming"',
            icon:'success',
            showCancelButton: true,     
            showConfirmButton: true,         
            confirmButtonText:'vai allo streaming',           
            cancelButtonText:'ok',
            cancelButtonAriaLabel: 'Thumbs down'
          }).then(vals=>{
            console.log("vals",vals)
            if(vals.isConfirmed){
              //vai a pagina streaming 
               var urlToGo = '/dashboard/gestioneevento?id='+evtIn.UUID+''
               this.props.history.push(urlToGo);
            }
          })
          this.refreshEvents()
        }).catch(err=>{
          console.log("err",err)
        })
      }
    })

  }

    //gestisce start evento
    handleStop(e, evtIn){
      //messaggio conferma
      swal.fire({
        title: 'Confermi?',
        text: "Stai per concludere l'evento "+evtIn.Title ,
        icon: 'info',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Conferma'
      }).then((result) => {
        if (result.isConfirmed) {
          //console.log("e",e)
          //console.log("ID",ID)
          evt.stopEvent({UUID:evtIn.UUID}).then(res=>{
            //console.log("res",res)
            //this.setState({users:res})
            swal.fire(
              'Concluso!',
              'L evento è stato concluso, gli utenti connessi allo streming verranno espulsi dalla live! ',
              'success'
            )
            this.refreshEvents()
          }).catch(err=>{
            console.log("err",err)
          })
        }
      })
  
    }




  //valida form per creazione evento 
  validateForm(values) {
    const emailTest = /^[^\s@]+@[^\s@]+\.[^\s@]{2,}$/i;
    let errors = {};


    if (values.titolo === "") {
        errors.titolo = "Titolo obbligatorio";
    }

    if (values.descrizione === "") {
        errors.descrizione = "Descrizione obbligatoria";
    }else if (values.descrizione.length<10){
      errors.descrizione = "Inserisci una Descrizione di almeno 10 caratteri";
    }

    if (values.dataOraInizio === "") {
      errors.dataOraInizio = "Data e ora inizio obbligatoria";
    }

    if (values.durata === "") {
      errors.durata = "Durata obbligatorio";
    }
    
    if (values.ore === "") {
      errors.ore = "Ora inizio evento obbligatoria";
    }else if(values.ore>23 || values.ore<0){
      errors.ore = "Ore non valide";
    }/*else if(values.ore && values.ore.toString().length>2){
      errors.ore="Inserisci solo 2 cifre"
    }*/

    if (values.minuti === "") {
      errors.minuti = "Minuti inizio evento obbligatori";
    }else if(values.minuti>59 || values.minuti<0){
      errors.minuti="Minuti non validi"
    }/*else if(values.minuti && values.minuti.toString().length>2){
      errors.minuti="Inserisci solo 2 cifre"
    }*/



    //console.log("values in validate ",values )

    return errors;
  }

  copyToClipboard = str => {
    const el = document.createElement('textarea');
    el.value = str;
    document.body.appendChild(el);
    el.select();
    document.execCommand('copy');
    document.body.removeChild(el);
  };

  ShareLink(e,UUID){
    var urltoshare = window.location.origin + "/#/join/"+UUID

   
    swal.fire({
      title: '<div style="display:block!important"><h4>Condividi</h4><h6>clicca sul link per inviarlo via whatsapp</h6></div>',
      //html:'<i>'+urltoshare+'</i>',   
      html:'<a href="whatsapp://send?text='+encodeURIComponent('Clicca qui per entrare su e-sopo:\n '+urltoshare)+'" data-url="'+urltoshare+'" data-action="share/whatsapp/share">'+urltoshare+'</a>',   
      //icon: 'info',
      confirmButtonText: 'copia'      
    }).then(rr=>{
      //copio link in clipboard
      this.copyToClipboard(urltoshare)
      
    }).catch(err=>{

    })
  }

  toggleModalQRCode(e,UUID){
    var urltoshare = window.location.origin + "/#/join/"+UUID
    this.setState({URLQRcode:urltoshare})
    e.preventDefault()
    this.setState({isModalQRCode:!this.state.isModalQRCode})
  }


  formatDate(momentDate) {        
    return moment(momentDate).format("YYYY-MM-DD");
  }

  render() {
    return (
      <>
        
        
          <div className="position-relative">
            {/* shape Hero */}
            <section className="section section-lg section-shaped pb-50">
              <div className="shape shape-style-1 shape-primary">
                <span />
                <span />
                <span />
                <span />
                <span />
                <span />
                <span />
                <span />
                <span />
              </div>
              <Container  className="py-lg-md d-flex" >
                <div className="col px-0">
                  <Row>
                    <Col lg="6">
                      {/*<h1 className="display-3 text-white">
                        Inizia da qui!{" "}
                        <span>gestisci i tuoi eventi!</span>
                      </h1>*/}
                      <p className="lead text-white">
                        Gestisci i tuoi eventi, invita i tuoi amici e clienti!
                        </p>
                       
                        {/*
                      <div className="btn-wrapper">
                        <Button
                          className="btn-icon mb-3 mb-sm-0"
                          color="info"
                          href="https://demos.creative-tim.com/argon-design-system-react/#/documentation/alerts?ref=adsr-landing-page"
                        >
                          <span className="btn-inner--icon mr-1">
                            <i className="fa fa-code" />
                          </span>
                          <span className="btn-inner--text">Components</span>
                        </Button>
                        <Button
                          className="btn-white btn-icon mb-3 mb-sm-0 ml-1"
                          color="default"
                          href="https://www.creative-tim.com/product/argon-design-system-react?ref=adsr-landing-page"
                        >
                          <span className="btn-inner--icon mr-1">
                            <i className="ni ni-cloud-download-95" />
                          </span>
                          <span className="btn-inner--text">
                            Download React
                          </span>
                        </Button>
                      </div>
                      */}
                    </Col>
                    <p className="lead text-white mt-0">
                        <Row>
                          <Col xs={12} md={6} className="mt-1">
                          <div style={{'background': 'rgba(0, 0, 0,0.2)','border-radius':'4px','padding':'5px'}}>
                            <b>Come si usa E-SOPO?</b>
                            <ol class="list-group list-group-numbered ml-4">
                              <li class="small">Crea il tuo evento</li>
                              <li class="small">Clicca sul tasto AVVIA e inserisci il tuo nome per dare inizio allo streaming</li>
                              <li class="small">Clicca su QR code e fallo inquadrare al visitatore</li>
                            </ol>
                            </div>
                          </Col>
                          <Col xs={12} md={6} className="mt-1">
                          <div style={{'background': 'rgba(0, 0, 0,0.2)','border-radius':'4px','padding':'5px'}}>
                            <b>E il visitatore?</b>
                            <ol class="list-group list-group-numbered ml-4">
                              <li class="small">Inquadra il QR code dallo smartphone della guida</li>
                              <li class="small">Inserisce il proprio nome</li>
                              <li class="small">Se ha qualche domanda, potrà utilizzare il tasto FAI UNA DOMANDA nella chat diretta con la guida</li>
                            </ol>
                            </div>
                          </Col>
                        </Row>
                      </p>
                  </Row>
                </div>
              </Container>
              {/* SVG separator */}
              <div className="separator separator-bottom separator-skew">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  preserveAspectRatio="none"
                  version="1.1"
                  viewBox="0 0 2560 100"
                  x="0"
                  y="0"
                >
                  <polygon
                    className="fill-white"
                    points="2560 0 2560 100 0 100"
                  />
                </svg>
              </div>
            </section>
            {/* 1st Hero Variation */}
            <section className="section pb-50">
            <Container >
             
              <Row>
                <Col className="" lg="6" sm="12">
                  <div className="d-flex px-3">
                    <div>
                      <div className="icon icon-lg icon-shape bg-gradient-white shadow rounded-circle text-primary">
                        <i className="ni ni-building text-primary" />
                      </div>
                    </div>
                    <div className="pl-4">
                      <h4 className="display-3 ">
                          Eventi <small>({this.state.events.length})</small>
                          {/* visibile se IDMenucategory != 5  */}
                          {this.state.isGuest?
                            <span></span>:
                            <Button
                              className="mr-4 mt-2 float-right"
                              color="info"
                              href="#pablo"
                              onClick={this.toggleModal}
                              size="sm"
                            >
                              <i className="fa fa-plus"></i> Crea Nuovo
                            </Button>
                          }
                        
                        </h4>
                      <p className="">
                        Qui sotto puoi creare e gestire gli eventi di audio/video guida smart programmati e in corso, per maggiori dettagli visualizza lo storico dalla pagina nel menu "Eventi".
                      </p>
                    </div>
                  </div>
                  
                  
                </Col>
               
              </Row>
              {/*    ripetizione eventi     */}
              <Row className="row-grid align-items-center">     
              {this.state.events.map((item,i)=>{           
                return <Col className="" lg="6" sm="12" key={i}>
                  <Card className="shadow shadow-lg--hover mb-4">
                      <CardBody className="pb-0">
                        <div className=""> {/* d-flex px-3 */}
                        <div className="pl-4">
                            <h5 className="title text-warning mb-0">
                              {item.Title}
                            </h5>
                            <p className="mb-0">
                              {item.Description}
                            </p>
                            <a
                              className="text-warning mb-0"
                              href=""
                              onClick={e => e.preventDefault()}
                            >
                              Pianificato per {moment(item.DateScheduled).format("L HH:MM")}
                            </a>
                            {/* link per streaming solo se streaming è avviato  */}
                            {item.isEnabled!=0 && item.DateTimeStart && !item.DateTimeEnd?(
                              <p className="mt-3 mb-0">                                
                                <a
                                  className="btn btn-success btn-sm"
                                  href={'/#/dashboard/gestioneevento?id='+item.UUID+''}                                
                                >
                                Clicca qui per lo streaming
                                </a>                            
                              </p>
                            ):(
                              <span></span>
                            )}
                          </div>
                          <div className="row mt-4">  
                          
                            {/* QR CODE    
                            <span className="col-3 text-center p-1">
                              <div className="mt-2 icon icon-shape bg-gradient-primary rounded-circle text-white" id="tooltip3share" onClick={(e)=> {this.toggleModalQRCode(e,item.UUID)}}>
                                <i className="fa fa-qrcode" />
                              </div>
                              <div>QR Code</div>
                              <UncontrolledTooltip placement="right" target="tooltip3share">
                                Genera un QR Code
                              </UncontrolledTooltip>
                            </span>   
                            */}                
                            {/* AVVIA / STOP */}
                            <span className="col-4 mt-1 text-center p-1">
                              {item.isEnabled!=0 && item.DateTimeStart && !item.DateTimeEnd?(
                                <span>
                                  <div className=" icon icon-shape bg-gradient-warning rounded-circle text-white" id="tooltip133" onClick={(e)=> {this.handleStop(e,item)}}>
                                    <i className="ni ni-button-power" />
                                  </div>
                                  <div>Stop</div>
                                  <UncontrolledTooltip placement="right" target="tooltip133">
                                    Click per stoppare l'evento, una volta concluso le persone online nella stanza riceveranno una notifica di evento concluso!
                                  </UncontrolledTooltip>
                                </span>
                              ):(
                                <span>
                                  <div className=" icon icon-shape bg-gradient-success rounded-circle text-white" id="tooltip1" onClick={(e)=> {this.handleStart(e,item)}}>
                                    <i className="ni ni-button-play" />
                                  </div>
                                  <div>Avvia</div>
                                  <UncontrolledTooltip placement="right" target="tooltip1">
                                    Click per avviare l'evento, una volta avviato le persone potranno entrare nel link generato in automatico!
                                  </UncontrolledTooltip>
                                </span>
                              )}
                            </span>
                             {/* CONDIVIDI */}
                             <span className="col-4 text-center p-1">
                              <div className="mt-2 icon icon-shape bg-gradient-info rounded-circle text-white" id="tooltip2share" onClick={(e)=> {this.ShareLink(e,item.UUID)}}>
                                <i className="fa fa-share-alt" />
                              </div><br/>
                              Condividi
                              <UncontrolledTooltip placement="right" target="tooltip2share">
                              Condividi l'evento
                            </UncontrolledTooltip>
                            </span>   
                            {/* ELIMINA */}
                            <span className="col-4 text-center p-1">
                              <div className="mt-2 icon icon-shape bg-gradient-danger rounded-circle text-white" id="tooltip2" onClick={(e)=> {this.handleDelete(e,item)}}>
                                <i className="fa fa-times" />
                              </div><br/>
                              Elimina
                            </span>
                            <UncontrolledTooltip placement="right" target="tooltip2">
                              Elimina l'evento
                            </UncontrolledTooltip>
                            {/*<br></br>*/}
                            
                          </div>
                          <br></br>
                          
                        </div>
                    </CardBody>
                    <CardFooter className="text-center p-2">
                      {/* tasto per ottenere link 
                      <Button id="tooltip94749347" onClick={(event) => { this.ShareLink(event,item.UUID) }} size="sm" color="info" outline className="float-right" type="button">
                          <i className="fa fa-share-alt"></i>
                        </Button>
                        <UncontrolledTooltip delay={0} target="tooltip94749347">
                          Visualizza il link per gli invitati
                        </UncontrolledTooltip>
                        */}
                      {/* mostro stato di evento */}
                      {item.isEnabled==0?(
                        <Button
                        outline
                        className=" btn-icon btn-simple"
                        color="danger"
                        size="sm"
                        type="button"
                      >
                        eliminato
                      </Button>
                        
                      ):(
                        <span></span>
                      )}

                      {item.isEnabled==1 && !item.DateTimeStart?(
                        
                        <Button
                          outline
                          className=" btn-icon btn-simple"
                          color="primary"
                          size="sm"
                          type="button"
                        >
                          programmato
                        </Button>
                      ):(
                        <span></span>
                      )}

                      {item.isEnabled!=0 && item.DateTimeEnd?(
                        
                        <Button
                        outline
                        className=" btn-icon btn-simple"
                        color="success"
                        size="sm"
                        type="button"
                      >
                        concluso
                      </Button>
                      ):(
                        <span></span>
                      )}

                      {item.isEnabled!=0 && item.DateTimeStart && !item.DateTimeEnd?(
                        <span>
                          <Button
                            outline
                            className=" btn-icon btn-simple"
                            color="warning"
                            size="sm"
                            type="button"
                          >
                            in corso
                          </Button>
                         
                       
                       </span>
                      ):(
                        <span></span>
                      )}
                    </CardFooter>
                  </Card>
                </Col>
              })}
              </Row>
            </Container>
            {/* SVG separator */}
            <div className="separator separator-bottom separator-skew zindex-100">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                preserveAspectRatio="none"
                version="1.1"
                viewBox="0 0 2560 100"
                x="0"
                y="0"
              >
                <polygon
                  className="fill-white"
                  points="2560 0 2560 100 0 100"
                />
              </svg>
            </div>
          </section>
        </div>
          
          {/*modal per creazione evento */}
          {/**,ore:0,minuti:0 */}
          <Modal isOpen={this.state.isModalOpen} toggle={this.toggleModal} size="lg">
                         
              <Formik
                enableReinitialize
                validate={this.validateForm}                       
                initialValues={{ titolo:"",descrizione: "", dateFrom:new Date(),durata:180,ore:0,minuti:0,oreminuti:'00:00' }} 
                onSubmit={(values,{ setSubmitting }) => {
                    console.log("values",values)

                    this.insertEvent(values)
                    //alert("Form is validated! Submitting the form...");
                    //setSubmitting(false); role="form" name="formRegister"
                }}
              >{({ errors, touched,handleSubmit,handleChange,isSubmitting }) => (
                
                  <Form onSubmit={handleSubmit} onChange={handleChange}>
                    <ModalHeader toggle={this.toggleModal}>Aggiungi un nuovo evento</ModalHeader>
                    <ModalBody>
                  
                    <FormGroup>                        
                          <Field name="titolo">
                              {({ field, form, meta }) => (
                                    <div>
                                        <InputGroup className="input-group-alternative">
                                            <InputGroupAddon addonType="prepend">
                                                <InputGroupText>
                                                <i className="ni ni-caps-small" />
                                                </InputGroupText>
                                            </InputGroupAddon>
                                            <Input {...field} 
                                                disabled={isSubmitting}
                                                placeholder="Titolo dell'evento"
                                                type="text"
                                                autoComplete="off"
                                            />
                                        </InputGroup>
                                        {/*<input type="text" {...field} placeholder="First Name"/>*/}
                                        {/*meta.touched && meta.error && <div className="error">{meta.error}</div>*/}
                                    </div>
                              )}                               
                          </Field>
                          <ErrorMessage component="div" name="titolo" className="text-red small"/>
                      </FormGroup>
                      <FormGroup>                        
                          <Field name="descrizione">
                              {({ field, form, meta }) => (
                                    <div>
                                        <InputGroup className="input-group-alternative">
                                            <InputGroupAddon addonType="prepend">
                                                <InputGroupText>
                                                <i className="ni ni-caps-small" />
                                                </InputGroupText>
                                            </InputGroupAddon>
                                            <Input {...field} 
                                                disabled={isSubmitting}
                                                placeholder="Descrizione dell'evento"
                                                type="textarea"
                                                rows={5}
                                                autoComplete="off"
                                            />
                                        </InputGroup>
                                        {/*<input type="text" {...field} placeholder="First Name"/>*/}
                                        {/*meta.touched && meta.error && <div className="error">{meta.error}</div>*/}
                                    </div>
                              )}                               
                          </Field>
                          <ErrorMessage component="div" name="descrizione" className="text-red small"/>
                      </FormGroup>
                      <FormGroup className="row ">              
                          <div className="col-12 col-sm-6">
                          <Field name="dataOraInizio">
                              {({ field, form, meta }) => (
                                    <div>
                                        <InputGroup className="input-group-alternative" >
                                          <InputGroupAddon addonType="prepend">
                                            <InputGroupText>
                                              <i className="ni ni-calendar-grid-58" />
                                            </InputGroupText>
                                          </InputGroupAddon>
                                         
                                          {/* {...field} */}
                                          <ReactDatetime id="dtpck1"
                                            
                                            inputProps={{                                              
                                              placeholder: "Data inizio evento"
                                            }}
                                            initialValue={new Date()}
                                            onChange={(dateFromValue) => {form.setFieldValue('dateFrom', this.formatDate(dateFromValue))}} 
                                            locale="it"
                                            timeConstraints={{minutes:{step:15}}}
                                            timeFormat={false}
                                            readOnly={true}
                                            closeOnSelect={true}
                                          />
                                          
                                        </InputGroup>
                                        {/*<input type="text" {...field} placeholder="First Name"/>*/}
                                        {/*meta.touched && meta.error && <div className="error">{meta.error}</div>*/}
                                    </div>
                              )}                               
                          </Field>
                          <ErrorMessage component="div" name="dataOraInizio" className="text-red small"/>
                          </div>
                          <div className="mt-3 mt-sm-0 col-12 col-sm-6">
                            <Field name="oreminuti">
                                {({ field, form, meta }) => (
                                      <div>
                                          <InputGroup className="input-group-alternative" id="oraminutiInizioInputtooltip">
                                              <InputGroupAddon addonType="prepend">
                                                  <InputGroupText>
                                                  <i className="ni ni-time-alarm"/>
                                                  </InputGroupText>
                                              </InputGroupAddon>
                                              <TimeField
                                                  {...field}
                                                  className="form-control text-center"
                                                  value={field.value}                       // {String}   required, format '00:00' or '00:00:00'
                                                  //onChange={(event, value) => {field.value=value}} // {Function} required
                                                  colon=":"                          // {String}   default: ":"
                                                  showSeconds={false}                        // {Boolean}  default: false
                                              />
                                          </InputGroup>
                                           <UncontrolledTooltip  target="oraminutiInizioInputtooltip">
                                            Ora e minuti di Inizio dell'evento (formato 24H)
                                          </UncontrolledTooltip>
                                          


                                         
                                        
                                      </div>
                                )}                               
                            </Field>
                            <ErrorMessage component="div" name="oreminuti" className="text-red small"/>
                          </div>


                          </FormGroup>
                          {/*
                          <FormGroup>
                          <div className="mt-3 mt-sm-0 col-6 col-sm-3">
                            <Field name="ore">
                                {({ field, form, meta }) => (
                                      <div>
                                          <InputGroup className="input-group-alternative" id="oraInizioInputtooltip">
                                              <InputGroupAddon addonType="prepend">
                                                  <InputGroupText>
                                                  <i className="ni ni-time-alarm"/>
                                                  </InputGroupText>
                                              </InputGroupAddon>
                                              <Input {...field} 
                                                  disabled={isSubmitting}
                                                  placeholder="Ora inizio"
                                                  type="number"
                                                  step="1"
                                                  max="23"
                                                  min="0"
                                                  autoComplete="off"
                                              />
                                          </InputGroup>
                                           <UncontrolledTooltip  target="oraInizioInputtooltip">
                                            Ora di Inizio dell'evento (formato 24H)
                                          </UncontrolledTooltip>
                                          


                                         
                                        
                                      </div>
                                )}                               
                            </Field>
                            <ErrorMessage component="div" name="ore" className="text-red small"/>
                          </div>
                          <div className="mt-3 mt-sm-0 col-6 col-sm-3 ml-0">
                            <Field name="minuti">
                                {({ field, form, meta }) => (
                                      <div>
                                          <InputGroup className="input-group-alternative" id="MinutiInizioInputtooltip">
                                              <InputGroupAddon addonType="prepend">
                                                  <InputGroupText>
                                                  <i className="ni ni-time-alarm"/>
                                                  </InputGroupText>
                                              </InputGroupAddon>
                                              <Input {...field} 
                                                  disabled={isSubmitting}
                                                  placeholder="Minuti inizio"
                                                  type="number"
                                                  step="1"
                                                  max="59"
                                                  min="0"
                                                  autoComplete="off"
                                              />
                                          </InputGroup>
                                          <UncontrolledTooltip  target="MinutiInizioInputtooltip">
                                            Minuti di Inizio dell'evento
                                          </UncontrolledTooltip>                                          
                                      </div>
                                )}                               
                            </Field>
                            <ErrorMessage component="div" name="minuti" className="text-red small"/>
                          </div>
                      </FormGroup>
              */}

                      <FormGroup>                        
                          <Field name="durata">
                              {({ field, form, meta }) => (
                                    <div>
                                        <InputGroup className="input-group-alternative" id="tooltipDurataInpt">
                                            <InputGroupAddon addonType="prepend">
                                                <InputGroupText>
                                                <i className="ni ni-time-alarm"/>
                                                </InputGroupText>
                                            </InputGroupAddon>
                                            <Input {...field} 
                                                disabled={isSubmitting}
                                                placeholder="Durata (minuti)"
                                                type="number"
                                                step="15"
                                                autoComplete="off"
                                            />
                                        </InputGroup>
                                        <UncontrolledTooltip  target="tooltipDurataInpt">
                                            Durata Stimata ( minuti )
                                          </UncontrolledTooltip>
                                        {/*<input type="text" {...field} placeholder="First Name"/>*/}
                                        {/*meta.touched && meta.error && <div className="error">{meta.error}</div>*/}
                                    </div>
                              )}                               
                          </Field>
                          <ErrorMessage component="div" name="durata" className="text-red small"/>
                      </FormGroup>
                  
                  </ModalBody>
                  <ModalFooter>              
                    <Button color="secondary" disabled={this.state.isFormValid}  onClick={this.toggleModal}>Annulla</Button>
                    <Button color="primary" type="submit" disabled={(Object.keys(touched).length==0 || Object.keys(errors).length>0)?true:false}>Inserisci</Button>{' '}
                  </ModalFooter>
                  </Form>
            
                )}
                
              </Formik>
            
          </Modal>
          {/*modal per mostrareqrcode */}              
          <Modal isOpen={this.state.isModalQRCode} toggle={this.toggleModalQRCode} size="md">
              <ModalBody className="text-center">
                    <QRCode value={this.state.URLQRcode} size={256}></QRCode>
              </ModalBody>
            </Modal>
        
      </>
    );
  }
}

export default Landing;
