/*!

=========================================================
* Argon Design System React - v1.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-design-system-react
* Copyright 2020 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/argon-design-system-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import ReCAPTCHA from "react-google-recaptcha"
import React,{createRef} from "react";

// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  FormGroup,
  Form,
  Label,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Container,
  Row,
  Col,
  Modal,
  ModalBody,
  ModalHeader
} from "reactstrap";

import {Formik,Field,ErrorMessage} from "formik";
import swal from "sweetalert2"
import moment from "moment"

// core components
import DemoNavbar from "components/Navbars/Header.js";
import SimpleFooter from "components/Footers/Footer.js";
import sub from "../helpers/subscriptions.js";
import user from "../helpers/user.js";
import { getKEYStripe, getKEYRecaptcha } from "helpers/utils.js";

//stripe 
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";
import { CheckoutForm } from "../helpers/CheckoutForm";
const PUBLIC_KEY = getKEYStripe();
const KEY_RECAPTCHA = getKEYRecaptcha();
const stripeTestPromise = loadStripe(PUBLIC_KEY);

var _t={};

class Register extends React.Component {
    
    constructor(props){
        super(props);
        this.state = {
            subscriptionID:-1,
            userslevel:[],
            IDMenuCateogory:'',
            subscriptions:[],           
            nome:'',
            cognome:'',
            email:'',
            password:'',
            repeatpsw:'',
            recaptcha:null,
            coupon:'',
            privacy:'',
            selectedSub:{}
            
        }
        _t = this;
    }

  componentDidMount() {
    _t = this;
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
    this.refs.main.scrollTop = 0;
    this._reCaptchaRef = createRef();

    //scarico le tipologie di utente 
    sub.getUsersLevel().then(res=>{
        //console.log("result tipo utente",res)
        this.setState({userslevel:res})
        //setto primo indice di default 
        if(res.length>0){
            this.setState({IDMenuCategory:res[0].ID})

            //invoco abbonamenti di quel livello 
            sub.getSubscriptions(res[0].ID).then(res=>{
                //console.log("result",res)
                _t.setState({subscriptions:res})
                if(res.length>0){
                    console.log("setto default subscription ID",res[0])
                    _t.setState({subscriptionID:res[0].ID})
                }
            }).catch(err=>{
                console.log("err",err)
            })
        }
    }).catch(err=>{
        console.log("err tipo utente",err)
    })

    //scarico gli abbonamenti 
    /*
    sub.getSubscriptions().then(res=>{
        console.log("result",res)
        this.setState({subscriptions:res})
    }).catch(err=>{
        console.log("err",err)
    })
    */

  }

  handleUsersLevelChange(e,form ){
        e.persist()
        //_t.setState({IDMenuCategory:e.target.value})
        //console.log("this.state",_t.state)
        //scarico abbonamenti in funzione di livello utente selezionato 
        console.log("form",form)
        sub.getSubscriptions(e.target.value).then(res=>{
            console.log("result",res)
            _t.setState({subscriptions:res})
            if(res.length>0){
                form.setFieldValue('subscriptionID',res[0].ID)
               //_t.setState({subscriptionID:res[0].ID})
            }
        }).catch(err=>{
            console.log("err",err)
        })

  }

  handleSubscriptionChange(e){
      e.persist()
    //setto id abbonamento 
    //console.log(e.target.value)
    _t.setState({subscriptionID:e.target.value})

  }

  subscribe = (vals)=>{
      //apro popup caricamento 
      swal.fire({
        title: 'Registrazione in corso..',       
        //timer: 2000,
        timerProgressBar: true,
        allowOutsideClick:false,
        allowEscapeKey:false,
        didOpen: () => {
          swal.showLoading()          
        }
      }).then((result) => {
       
      })

        var objIn = vals;

        //aggiungo campo SubscriptionDescr e PricePerMonth, trovo la riga di array in cui è espressa descrizone e prezzo al mese 
        var fnd = this.state.subscriptions.filter(item=>{return item.ID==vals.subscriptionID})
        if(fnd.length>0){
            vals.PricePerMonth = fnd[0].PriceMonth
            vals.SubscriptionDescr = fnd[0].Title
            console.log("setto prezzo",vals)
            //seleziono oggetto per pagamento
            this.setState({selectedSub:fnd[0]})
        }else{
            console.log("non setto prezzo",vals)
        }

        console.log("vals",vals)
        //invio campi a server per sottoscrizione utente 
        user.insert(objIn).then(result=>{
            //chiudo popup attesa
            swal.close() 
            console.log("result arrivato da server",result)
            this.state.selectedSub.ID = result.IDAbb

            //faccio login per avere token per pagamento 
            user.login(objIn.email,objIn.password).then(o=>{
                //todo se ho coupon valido bypasso pagamento o applico sconto 
                //....
                console.log("BYPASS PAGAMENTO O SCONTO SU PREZZO ")
                //apro popup pagamento 
                this.toggleModalPagamento(undefined,this.state.selectedSub)
            }).catch(errL=>{
                console.log("Errore Login",errL)
            })
            



            //console.log("inserimento ok")
            //mostro popup successo, al tasto ok va a pagina login
            /*swal.fire({
                //position: 'top-end',
                icon: 'success',
                title: 'Completato',
                text:"Iscrizione avvenuta con successo, controlla la tua mail !",
                showConfirmButton: true,
                allowEscapeKey:false,
                allowOutsideClick:false
                //timer: 1500
              }).then(result=>{
                  if(result.value){
                      //vado a pagina login 
                      _t.props.history.push('/login');
                  }
              })
              */

        }).catch(err=>{
            // console.log("errore in inserimento ",err )
            //mostro popup errore
            swal.fire({
                //position: 'top-end',
                icon: 'error',
                title: 'Errore',
                text:""+err.data.description,
                showConfirmButton: true,
                allowEscapeKey:false,
                allowOutsideClick:false
                //timer: 1500
              })
            //swal.close() 
        })

  }

    //funzione per far comparire modal pagamento 
    toggleModalPagamento = (e,item)=>{
        //se sto aprendo modal , seleziono elemento
        if(!this.state.isModalPagamentoOpen){
          this.setState({selectedSub:item})
        }
        this.setState({isModalPagamentoOpen:!this.state.isModalPagamentoOpen})
    }

    //callback per pagamento failed 
    onFailed=(errMsgOut)=>{
        console.log("obj out failed: ",errMsgOut)
        //mostro errore
        swal.fire("Errore",errMsgOut,"error")
    }

    //callback per pagamento ok
    onOk=(objOut)=>{
        console.log("obj out ok: ",objOut)
        //mostro messaggio di successo 
        //swal.fire("Fatto","Transazione avvenuta con successo","success")
        swal.fire({
            //position: 'top-end',
            icon: 'success',
            title: 'Completato',
            text:"Iscrizione avvenuta con successo, controlla la tua mail !",
            showConfirmButton: true,
            allowEscapeKey:false,
            allowOutsideClick:false
            //timer: 1500
        }).then(result=>{
              if(result.value){
                  //vado a pagina login 
                  _t.props.history.push('/login');
              }
        })
        //chiudo popup 
        this.toggleModalPagamento()
    }
    

  //valida form 
  validateForm(values) {
        const emailTest = /^[^\s@]+@[^\s@]+\.[^\s@]{2,}$/i;
        let errors = {};
        if (values.email === "") {
            errors.email = "Mail obbligatoria";
        } else if (!emailTest.test(values.email)) {
            errors.email = "Formato mail invalido";
        }
        if (values.password === "") {
            errors.password = "Password obbligatoria";
        } else if (values.password.length < 3) {
            errors.password = "La password deve essere minimo di 3 caratteri";
        }

        if (values.repeatpsw === "") {
            errors.repeatpsw = "Password obbligatoria";
        } else if (values.repeatpsw.length < 3) {
            errors.repeatpsw = "La password deve essere minimo di 3 caratteri";
        }

        if(values.repeatpsw!=values.password){
            errors.repeatpsw = "La password non corrisponde con quella inserita";
        }

        if (values.nome === "") {
            errors.nome = "Nome obbligatorio";
        } else if (values.nome.length < 3) {
            errors.nome = "Nome deve avere minimo 3 caratteri";
        }

        if (values.cognome === "") {
            errors.cognome = "Cognome obbligatorio";
        } else if (values.cognome.length < 3) {
            errors.cognome = "Cognome deve avere minimo 3 caratteri";
        }

        if (values.IDMenuCategory === "") {
            errors.IDMenuCategory = "Livello utente obbligatorio";
        }else{
             //se IDMenuCategory == 2 ontrollo anche partita iva e ragione sociale sinao inserite 
            if(values.IDMenuCategory==2){
                if (values.piva === "") {
                    errors.piva = "Partita iva obbligatoria per l'abbonamento selezionato";
                }

                if (values.rsociale === "") {
                    errors.rsociale = "Ragione Sociale obbligatoria per l'abbonamento selezionato";
                }
        
            }
        }

       


        if (values.subscriptionID === "") {
            errors.subscriptionID = "abbonamento obbligatorio";
        }

        if (values.privacy === false) {
            errors.privacy = "Accetta le condizioni del trattamento dei dati per iscriverti";
        }

        if (values.recaptcha === null || values.recaptcha === false) {
            errors.recaptcha = 'invalido'
        }

        //console.log("values in validate ",values )

        return errors;
    }

  render() {
    return (
      <>
        <DemoNavbar />
        <main ref="main">
          <section className="section section-shaped section-lg">
            <div className="shape shape-style-1 bg-gradient-default">
              <span />
              <span />
              <span />
              <span />
              <span />
              <span />
              <span />
              <span />
            </div>
            <Container className="pt-lg-7">
              <Row className="justify-content-center">
                <Col lg="5">
                  <Card className="bg-secondary shadow border-0">                   
                    <CardBody className="px-lg-5 py-lg-5">
                      <div className="text-center text-muted mb-4">
                        <small></small>
                      </div>
                      <Formik
                        enableReinitialize 
                        validate={this.validateForm}                       
                        initialValues={{ email: this.state.email, password: "",repeatpsw:"",nome:this.state.nome,cognome:"",privacy:false,IDMenuCategory:this.state.IDMenuCategory,subscriptionID:this.state.subscriptionID,piva:'',rsociale:'', recaptcha:null }}
                        onSubmit={(values,{ setSubmitting }) => {
                            console.log("values",values)

                            this.subscribe(values)
                            //alert("Form is validated! Submitting the form...");
                            //setSubmitting(false); role="form" name="formRegister"
                        }}
                      >
                      {({ values,errors, touched,handleSubmit,handleChange,isSubmitting }) => (
                          <Form onSubmit={handleSubmit} onChange={handleChange}>
                          <FormGroup>                        
                          <Field name="nome">
                                {({ field, form, meta }) => (
                                    <div>
                                        <InputGroup className="input-group-alternative">
                                            <InputGroupAddon addonType="prepend">
                                                <InputGroupText>
                                                <i className="ni ni-circle-08" />
                                                </InputGroupText>
                                            </InputGroupAddon>
                                            <Input {...field} 
                                                disabled={isSubmitting}
                                                placeholder="Nome"
                                                type="text"
                                                autoComplete="off"
                                            />
                                        </InputGroup>
                                        {/*<input type="text" {...field} placeholder="First Name"/>*/}
                                        {/*meta.touched && meta.error && <div className="error">{meta.error}</div>*/}
                                    </div>
                                )}                               
                                </Field>
                                <ErrorMessage component="div" name="nome" className="text-red small"/>
                          </FormGroup>
                          <FormGroup>
                            <Field name="cognome">
                                {({ field, form, meta }) => (
                                    <div>
                                        <InputGroup className="input-group-alternative">
                                            <InputGroupAddon addonType="prepend">
                                                <InputGroupText>
                                                <i className="ni ni-circle-08" />
                                                </InputGroupText>
                                            </InputGroupAddon>
                                            <Input {...field} 
                                                placeholder="Cognome"
                                                type="text"
                                                autoComplete="off"
                                            />
                                        </InputGroup>
                                        {/*<input type="text" {...field} placeholder="First Name"/>*/}
                                        {/*meta.touched && meta.error && <div className="error">{meta.error}</div>*/}
                                    </div>
                                )}                               
                                </Field>
                                <ErrorMessage component="div" name="cognome" className="text-red small"/>
                          </FormGroup>
                          <FormGroup>
                            <Field name="email">
                                {({ field, form, meta }) => (
                                    <div>
                                        <InputGroup className="input-group-alternative">
                                            <InputGroupAddon addonType="prepend">
                                                <InputGroupText>
                                                <i className="ni ni-email-83" />
                                                </InputGroupText>
                                            </InputGroupAddon>
                                            <Input {...field} 
                                                placeholder="Email"
                                                type="mail"
                                                autoComplete="off"
                                            />
                                        </InputGroup>
                                        {/*<input type="text" {...field} placeholder="First Name"/>*/}
                                        {/*meta.touched && meta.error && <div className="error">{meta.error}</div>*/}
                                    </div>
                                )}                               
                                </Field>
                                <ErrorMessage component="div" name="email" className="text-red small"/>
                          </FormGroup>
                          <div className="form-group">
                                <Field name="password">
                                {({ field, form, meta }) => (
                                    <div>
                                        <InputGroup className="input-group-alternative">
                                            <InputGroupAddon addonType="prepend">
                                                <InputGroupText>
                                                <i className="ni ni-lock-circle-open" />
                                                </InputGroupText>
                                            </InputGroupAddon>
                                            <Input {...field} 
                                                placeholder="Password"
                                                type="password"
                                                autoComplete="off"
                                            />
                                        </InputGroup>
                                        {/*<input type="text" {...field} placeholder="First Name"/>*/}
                                        {/*meta.touched && meta.error && <div className="error">{meta.error}</div>*/}
                                    </div>
                                )}                               
                                </Field>
                                <ErrorMessage component="div" name="password" className="text-red small"/>
                            </div>
                          <FormGroup>
                                <Field name="repeatpsw">
                                {({ field, form, meta }) => (
                                    <div>
                                        <InputGroup className="input-group-alternative">
                                            <InputGroupAddon addonType="prepend">
                                                <InputGroupText>
                                                <i className="ni ni-lock-circle-open" />
                                                </InputGroupText>
                                            </InputGroupAddon>
                                            <Input {...field} 
                                                placeholder="Ripeti Password"
                                                type="password"
                                                autoComplete="off"
                                            />
                                        </InputGroup>
                                        {/*<input type="text" {...field} placeholder="First Name"/>*/}
                                        {/*meta.touched && meta.error && <div className="error">{meta.error}</div>*/}
                                    </div>
                                )}                               
                                </Field>
                                <ErrorMessage component="div" name="repeatpsw" className="text-red small"/>
                          </FormGroup>
                          {/*<FormGroup>
                                <Field name="coupon">
                                {({ field, form, meta }) => (
                                    <div>
                                        <InputGroup className="input-group-alternative">
                                            <InputGroupAddon addonType="prepend">
                                                <InputGroupText>
                                                <i className="ni ni-lock-circle-open" />
                                                </InputGroupText>
                                            </InputGroupAddon>
                                            <Input {...field} 
                                                placeholder="Coupon"
                                                type="text"
                                                autoComplete="off"
                                            />
                                        </InputGroup>
                                       
                                    </div>
                                )}                               
                                </Field>
                                <ErrorMessage component="div" name="coupon" className="text-red small"/>
                          </FormGroup>
                                */}
                          <FormGroup>
                            <Field name="IDMenuCategory">
                                {({ field, form, meta }) => (
                                    <div> {/* value={this.state.IDMenuCategory} onChange={this.handleUsersLevelChange}*/ }
                                        <Input type="select" 
                                        {...field} 
                                        className="form-control-sm"  
                                        
                                        onChange={(e)=>{this.handleUsersLevelChange(e,form)}}                                         
                                        >
                                            {this.state.userslevel.map((item,i)=>{
                                                return <option key={i} value={item.ID}>{item.DescrInRegister}</option>
                                            })}
                                        </Input>
                                        {/*<input type="text" {...field} placeholder="First Name"/>*/}
                                        {/*meta.touched && meta.error && <div className="error">{meta.error}</div>*/}
                                    </div>
                                )}                               
                                </Field>
                                <ErrorMessage component="div" name="IDMenuCategory" className="text-red small"/>
                              {/*<Label for="exampleSelect1" >Tipo di utente</Label>
                              <Input type="select" name="select" className="form-control-sm" id="exampleSelect1" value={this.state.IDMenuCategory} onChange={this.handleUsersLevelChange}>
                                          {this.state.userslevel.map((item,i)=>{
                                          return <option key={i} value={item.ID}>{item.DescrInRegister}</option>
                                          })}
                              </Input>
                                        */}
                          </FormGroup>
                          <FormGroup>
                                <Field name="subscriptionID">
                                {({ field, form, meta }) => (
                                    <div> {/* value={this.state.IDMenuCategory} onChange={this.handleUsersLevelChange}  */ }
                                        <Input 
                                        type="select" 
                                        {...field}
                                        className="form-control-sm"  
                                        
                                        >
                                            {this.state.subscriptions.map((item,i)=>{
                                                return <option key={i} value={item.ID}>{item.Title} - ({item.PriceMonth} €/Mese{item.isFlat==1?' per '+ moment.duration(item.DurationDays,'d').humanize():""})</option>
                                            })}
                                        </Input>
                                        {/*<input type="text" {...field} placeholder="First Name"/>*/}
                                        {/*meta.touched && meta.error && <div className="error">{meta.error}</div>*/}
                                    </div>
                                )}                               
                                </Field>
                                <ErrorMessage component="div" name="subscriptionID" className="text-red small"/>
                                {/*
                              <Label for="exampleSelect2" >Abbonamento</Label>
                              <Input type="select" name="select" className="form-control-sm" id="exampleSelect2" value={this.state.subscriptionID} onChange={this.handleSubscriptionChange}>
                                          {this.state.subscriptions.map((item,i)=>{
                                          return <option key={i} value={item.ID}>{item.Title} - ({item.PriceMonth} € al mese)</option>
                                          })}
                              </Input>
                                        */}
                          </FormGroup>

                            {/* MOSTRO PARTITA IVA e RAGIOE SOCIALE in caso scelta  */}
                            {values.IDMenuCategory==2?    
                                <div>       
                                    <FormGroup>
                                        <Field name="piva">
                                        {({ field, form, meta }) => (
                                            <div>
                                                <InputGroup className="input-group-alternative">
                                                    <InputGroupAddon addonType="prepend">
                                                        <InputGroupText>
                                                        <i className="ni ni-lock-circle-open" />
                                                        </InputGroupText>
                                                    </InputGroupAddon>
                                                    <Input {...field} 
                                                        placeholder="Partita IVA"
                                                        type="text"
                                                        autoComplete="off"
                                                    />
                                                </InputGroup>
                                                {/*<input type="text" {...field} placeholder="First Name"/>*/}
                                                {/*meta.touched && meta.error && <div className="error">{meta.error}</div>*/}
                                            </div>
                                        )}                               
                                        </Field>
                                        <ErrorMessage component="div" name="piva" className="text-red small"/>
                                    </FormGroup>
                                    <FormGroup>
                                    <Field name="rsociale">
                                    {({ field, form, meta }) => (
                                        <div>
                                            <InputGroup className="input-group-alternative">
                                                <InputGroupAddon addonType="prepend">
                                                    <InputGroupText>
                                                    <i className="ni ni-lock-circle-open" />
                                                    </InputGroupText>
                                                </InputGroupAddon>
                                                <Input {...field} 
                                                    placeholder="Ragione Sociale"
                                                    type="text"
                                                    autoComplete="off"
                                                />
                                            </InputGroup>
                                            {/*<input type="text" {...field} placeholder="First Name"/>*/}
                                            {/*meta.touched && meta.error && <div className="error">{meta.error}</div>*/}
                                        </div>
                                    )}                               
                                    </Field>
                                    <ErrorMessage component="div" name="rsociale" className="text-red small"/>
                                </FormGroup>
                            </div>
                            :
                            <span></span>
                            }

                          {/*<div className="text-muted font-italic">
                            <small>
                              password strength:{" "}
                              <span className="text-success font-weight-700">
                                strong
                              </span>
                            </small>
                                        </div>*/}
                            <Row>
                                <Col xs={12}>
                                <Field name="recaptcha">
                                    {({ field, form, meta }) => (
                                        <ReCAPTCHA
                                            {...field}
                                            ref={this._reCaptchaRef}
                                            sitekey={KEY_RECAPTCHA}
                                            onChange={(value) => {
                                            console.log("$$$$",  value);
                                            form.setFieldValue('recaptcha', value)
                                            //props.setFieldValue("recaptcha", value);
                                            //props.setSubmitting(false);
                                            }}
                                            /* size="invisible" */
                                        />
                                    )}
                                </Field>
                                <ErrorMessage component="div" name="recaptcha" className="text-red small"/>
                                </Col>
                            </Row>
                            
                          <Row className="my-4">
                            <Col xs="12">
                                <Field name="privacy">
                                {({ field, form, meta }) => (
                                    <div className="custom-control custom-control-alternative custom-checkbox">
                                        <input {...field} 
                                            className="custom-control-input"
                                            id="customCheckRegister"
                                            type="checkbox"
                                        />
                                        <label
                                            className="custom-control-label"
                                            htmlFor="customCheckRegister"
                                        >
                                        <span>
                                            Accetto le {" "}
                                            <a
                                            href="/#/privacy"
                                            //onClick={e => e.preventDefault()}
                                            >
                                            Privacy Policy
                                            </a>
                                        </span>
                                        </label>
                                    </div>
                                )}
                                </Field>
                                <ErrorMessage component="div" name="privacy" className="text-red small"/>
                            </Col>
                          </Row>
                          <div className="text-center">
                              {/*onClick={this.subscribe} type="submit" */}                              
                            <Button type="submit" disabled={(Object.keys(touched).length==0 || Object.keys(errors).length>0)?true:false}
                              className="mt-4"
                              color="primary"                              
                            >
                              Iscriviti 
                            </Button>
                          </div>
                        </Form>
                      )}
                      </Formik>                    

                    </CardBody>
                  </Card>                  
                </Col>
              </Row>
            </Container>
          </section>
        </main>
        {/* definisco modal dove ospitare form pagamento  */}
        <Modal isOpen={this.state.isModalPagamentoOpen}  size="" backdrop="static"> {/* toggle={this.toggleModalPagamento} */}
              <ModalHeader toggle={this.toggleModalPagamento} className="">Acquista: {this.state.selectedSub.Title} - <b>{this.state.selectedSub.PriceMonth}</b>€</ModalHeader> 
              <ModalBody className="p-2">
                <Container fluid>                        
                  
                    {/* tabella con pagamenti   per abbonamento selezionato 
                    customer={"cliente"}
                      customerMail={"mailcliente@cliente.it"}
                      */}
                    <Elements stripe={stripeTestPromise} >
                      <CheckoutForm 
                      amount={this.state.selectedSub.PriceMonth} 
                      IDRefAbb={this.state.selectedSub.ID}
                      isFromRegister={true}
                      DurationDays={this.state.selectedSub.DurationDays}
                      onOk={this.onOk} 
                      onFailed={this.onFailed} 
                      description={this.state.selectedSub.Title}
                      
                      />
                    </Elements>
                    
                  
                  </Container>
              </ModalBody>              
          </Modal>
        <SimpleFooter />
      </>
    );
  }
}

export default Register;
