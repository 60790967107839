
import React from "react";
import {Container,Row,Col,Table,Card,CardBody,CardHeader,CardFooter,Nav,Button,UncontrolledTooltip,CustomInput} from "reactstrap";
import swal from "sweetalert2"
import moment from 'moment'
//pagination 
import Pagination from "react-js-pagination";

//require("bootstrap/less/bootstrap.less");

//importo helpers per utente
import evt from "../../helpers/events.js";

class StoricoEventi extends React.Component {

  constructor(props){
    super(props)
    this.state = {
      events:[],
      activePage: 1,
      itemPerPage: 10
    }
    this.handlePageChange = this.handlePageChange.bind(this);
    this.handleDeleteEvt = this.handleDeleteEvt.bind(this);
    this.refreshEvents = this.refreshEvents.bind(this)
  }

  handlePageChange(pageNumber){
    //console.log("PAGE NUMBER",pageNumber)
    this.setState({ activePage: pageNumber });
  }


  handleDeleteEvt(e,subobj){
    //pannello di conferma 
    swal.fire({
      title: 'Confermi?',
      text: "Stai per eliminare l'evento "+subobj.ID ,
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Conferma'
    }).then((result) => {
      if (result.isConfirmed) {
        swal.fire(
          'Inviata!',
          'funzione non ancora implementata',
          'success'
        )
      }
    })

    
  }

  refreshEvents(e){
    var allEvents = false;
    if(e){
      e.persist()
      //console.log("allusers",e.target.checked)
      allEvents = e.target.checked
    }
    evt.getAllEvents(allEvents, true).then(res=>{
      console.log("res",res)
      this.setState({events:res})
    }).catch(err=>{
      console.log("err",err)
    })
  }

  componentDidMount(){
    this.refreshEvents()
  }
    render() {

      const { events, activePage, itemPerPage } = this.state;

      const indexOfLastTodo = activePage * itemPerPage;
      const indexOfFirstTodo = indexOfLastTodo - itemPerPage;
      const renderedEvents = events.slice(indexOfFirstTodo, indexOfLastTodo);

        return (
            <div className="position-relative">
            {/* shape Hero */}
            <section className="section section-md section-shaped pb-10">
              <div className="shape shape-style-1 shape-primary">
                <span />
                <span />
                <span />
                <span />
                <span />
                <span />
                <span />
                <span />
                <span />
              </div>
              <Container fluid className="py-lg-md d-flex" >
                <div className="col px-0">
                  <Row>
                    <Col lg="6">                        
                        <p className="pl-4 lead text-white">
                         Controlla gli eventi come amministratore <span className="badge badge-secondary">{this.state.events.length}</span>
                        </p>                        
                    </Col>
                  </Row>
                </div>
              </Container>
              {/* SVG separator */}
              <div className="separator separator-bottom separator-skew">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  preserveAspectRatio="none"
                  version="1.1"
                  viewBox="0 0 2560 100"
                  x="0"
                  y="0"
                >
                  <polygon
                    className="fill-white"
                    points="2560 0 2560 100 0 100"
                  />
                </svg>
              </div>
            </section>
            <section className="section pb-10 pt-0">
            <Container fluid className="p-5">
              {/*<Row className="row-grid align-items-center">
                
                <Col className="" lg="6" sm="12">
                  <div className="d-flex px-3">
                    <div>
                      <div className="icon icon-lg icon-shape bg-gradient-white shadow rounded-circle text-primary">
                        <i className="ni ni-building text-primary" />
                      </div>
                    </div>

                    <div className="pl-4">
                      <h4 className="display-3 ">
                          Eventi
                          <Button
                          className="mr-4 mt-2 float-right"
                          color="info"
                          href="#pablo"
                          onClick={e => e.preventDefault()}
                          size="sm"
                        >
                          <i className="fa fa-plus"></i> Crea Nuovo
                        </Button>
                        </h4>
                      <p className="">
                        Qui sotto puoi creare e gestire gli eventi di audio guida smart.
                      </p>
                    </div>
                  </div>
                  </Col>
                </Row>*/}
                {/* tabella utenti */}
                <Row>
                  <Col lg="12">

                    
                     
                    
                    <Card className=" shadow border-0">
                      <CardHeader>
                        <Row>
                          <Col>
                            <CustomInput className="ml-4" type="switch" id="exampleCustomSwitch" name="customSwitch" label="Mostra tutti" onChange={this.refreshEvents}/>
                          </Col>
                          <Col className="text-right">
                          
                              {/*<Button color="default" size="sm" outline type="button">                      
                                Nuovo
                                </Button>*/}
                            
                          </Col>
                        </Row>  
                      </CardHeader>
                        <CardBody className="p-0">
                        <Table className="mb-0" size="sm" hover striped responsive>{/* size="sm"*/}
                          <thead>
                            <tr>
                              <th>#</th>
                              <th>Titolo</th>
                              <th>Descrizione</th>
                              <th>Data Schedulata</th>
                              <th>Data Inizio reale</th>                              
                              <th>Durata</th>
                              <th>Durata reale</th>
                              <th className="text-center">Stato</th>
                            </tr>
                          </thead>
                          <tbody>
                            {renderedEvents.map((item,i)=>{
                              return <tr key={i}>
                                  <th scope="row">
                                    {item.ID ? (
                                      <span><small><b>{item.ID}</b></small></span>
                                    ) : (
                                      <span id={"TooltipAL"+i}><i class="text-red fa fa-exclamation-triangle" aria-hidden="true"></i>
                                      <UncontrolledTooltip placement="right" target={"TooltipAL"+i}>
                                        Abbonamento non esistente
                                      </UncontrolledTooltip>
                                      </span>
                                    )}                                                                      
                                  </th>
                                  <td><small>{item.Title}</small></td>
                                  <td><small>{item.Description}</small></td>
                                  <td><small>{moment(item.DateScheduled).format("DD-MM-YYYY")}</small></td>
                                  <td>{                                      
                                      item.DateTimeStart?(
                                        <span className=""><small>{moment(item.DateTimeStart).format("DD-MM-YYYY")}</small></span>
                                      ):(
                                        <span></span>
                                      )
                                  }</td>  
                                  <td>{
                                    item.DurationScheduled?(
                                      <span className=""><small>{item.DurationScheduled}</small></span>
                                    ):(
                                      <span></span>
                                    )
                                  }</td>
                                  <td>{
                                    item.DurationReal?(
                                      <span className=""><small>{item.DurationReal}</small></span>
                                    ):(
                                      <span></span>
                                    )
                                  }</td>
                                  <td className=" td-actions text-center" style={{minWidth:'150px'}}>
                                    

                                    {item.isEnabled==0?(
                                      <Button
                                      outline
                                      className=" btn-icon btn-simple"
                                      color="danger"
                                      size="sm"
                                      type="button"
                                    >
                                      eliminato
                                    </Button>
                                      
                                    ):(
                                      <span></span>
                                    )}

                                    {item.isEnabled==1 && !item.DateTimeStart?(
                                      
                                      <Button
                                        outline
                                        className=" btn-icon btn-simple"
                                        color="primary"
                                        size="sm"
                                        type="button"
                                      >
                                        programmato
                                      </Button>
                                    ):(
                                      <span></span>
                                    )}

                                    {item.isEnabled!=0 && item.DateTimeEnd?(
                                      
                                      <Button
                                      outline
                                      className=" btn-icon btn-simple"
                                      color="success"
                                      size="sm"
                                      type="button"
                                    >
                                      concluso
                                    </Button>
                                    ):(
                                      <span></span>
                                    )}

                                    {item.isEnabled!=0 && item.DateTimeStart && !item.DateTimeEnd?(
                                      
                                      <Button
                                        outline
                                        className=" btn-icon btn-simple"
                                        color="warning"
                                        size="sm"
                                        type="button"
                                      >
                                        in corso
                                      </Button>
                                    ):(
                                      <span></span>
                                    )}

                                    
                                    
                                    

                                   
                                  </td>
                                </tr>
                            })}                                                      
                          </tbody>
                        </Table>
                        </CardBody>
                        <CardFooter>
                          <Nav className="justify-content-center">
                            <Pagination
                                  hideNavigation
                                  activePage={this.state.activePage}                                  
                                  itemsCountPerPage={this.state.itemPerPage}
                                  totalItemsCount={this.state.events.length}
                                  pageRangeDisplayed={5}
                                  onChange={this.handlePageChange}
                                  itemClass="page-item"
                                  linkClass="page-link"                                 
                              />
                            </Nav>
                        </CardFooter>
                        </Card>
                  </Col>
                </Row>
                </Container>
                </section>
        </div>
        )
    }
}

export default StoricoEventi;