/*!

=========================================================
* Argon Design System React - v1.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-design-system-react
* Copyright 2020 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/argon-design-system-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";
import moment from "moment";
import swal from "sweetalert2"

import users from "../../helpers/user.js";
// reactstrap components
import { Button, Card, Container, Row, Col,UncontrolledTooltip } from "reactstrap";



class Profile extends React.Component {
  state= {
    user:{},
    stats:{
      boundaries:{
        userStats:{},
        evtDone:[]
      },
      abb:{}

      
    }
  }
  componentDidMount() {
    //moment().locale('it')
    /*document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
    this.refs.main.scrollTop = 0;
    */
   users.getUserInfo().then(data=>{
     console.log("getUserInfo : ",data)
     this.setState({user:data})
     //se ho parent id chiedo sue info 
     if(data.parentID){
        users.getDtlUser(data.parentID).then(dtl=>{
          //console.log("dettaglio utnte",dtl)
          this.setState({userParent:dtl})
        })
      }

   }).catch(err=>{
     //console.log("caso errore",err)
   })

   //ottengo statistiche 
   users.getUserStat().then(data=>{
    //console.log("res stat: ",data)
    if(data.abb.length==0){
      //nessun abbonamento trovato 

    }else{
      
    }

    this.setState({stats:data})
  }).catch(err=>{
    //console.log("caso errore",err)
    swal.close();
         
    //mostro messaggio di warning perchè non c'è ancora abbonamento 
    swal.fire(
      'Attenzione!',
      err.data.description,
      'warning'
    )
  })
    
  }

  getSessExp(exp){
    return moment(exp*1000).fromNow()
  }

  render() {
    return (
      <>       
        <main className="profile-page" ref="main">
          <section className="section-profile-cover section-shaped my-0">
            {/* Circles background */}
            <div className="shape shape-style-1 shape-primary alpha-4">
              <span />
              <span />
              <span />
              <span />
              <span />
              <span />
              <span />
            </div>
            {/* SVG separator */}
            <div className="separator separator-bottom separator-skew">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                preserveAspectRatio="none"
                version="1.1"
                viewBox="0 0 2560 100"
                x="0"
                y="0"
              >
                <polygon
                  className="fill-white"
                  points="2560 0 2560 100 0 100"
                />
              </svg>
            </div>
          </section>
          <section className="section">
            <Container>
              <Card className="card-profile shadow mt--300">
                <div className="px-4">
                  <Row className="justify-content-center">
                    <Col className="order-lg-2" lg="3">
                      <div className="card-profile-image">
                        <a href="#pablo" onClick={e => e.preventDefault()}>
                          <img
                            alt="..."
                            className="rounded-circle"
                            src={require("assets/img/theme/defaultavatar.jpg")}
                          />
                        </a>
                      </div>
                    </Col>
                    <Col
                      className="order-lg-3 text-lg-right align-self-lg-center"
                      lg="4"
                    >
                    
                      <div className="card-profile-actions py-4 mt-lg-0">
                        {/*<Button
                          className="mr-4"
                          color="info"
                          href="#pablo"
                          onClick={e => e.preventDefault()}
                          size="sm"
                        >
                          Modifica Immagine
                        </Button>*/}                        
                      </div>
                    </Col>
                    <Col className="order-lg-1" lg="4">
                      <div className="card-profile-stats d-flex justify-content-center">
                        <div>
                          <span className="heading">{this.state.stats.boundaries.evtDone.length}</span>
                          <span className="description">Eventi completati</span>
                        </div>
                        { this.state.stats.boundaries.userStats.isFlat==1 && this.state.stats.abb.length>0?
                            <div>                
                            <span className="heading text-success">ILLIMITATI</span>            
                            <span className="description text-success">Eventi rimanenti</span>
                          </div>
                          :
                          this.state.stats.boundaries.userStats.evtRemaining && this.state.stats.abb.length>0?
                          <div>
                            <span className="heading">{this.state.stats.boundaries.userStats.evtRemaining}</span>
                            <span className="description">Eventi rimanenti</span>
                          </div>:                          
                          <div>                
                            <span className="heading text-red">0</span>            
                            <span className="description text-red">Nessun Evento rimanente</span>
                          </div>
                          
                          
                        }
                        {this.state.stats.boundaries.userStats.MaxGuidesInside?
                          <div id="guidegestibilitooltip">
                            <span className="heading">{this.state.stats.guidesCreated}/{this.state.stats.boundaries.userStats.MaxGuidesInside}</span>
                            <span className="description">Guide gestibili</span>
                            <UncontrolledTooltip placement="right" target="guidegestibilitooltip">
                                Puoi ancora creare {this.state.stats.boundaries.userStats.MaxGuidesInside - this.state.stats.guidesCreated } Utenti guida associati al tuo account
                              </UncontrolledTooltip>
                          </div>
                          :
                          <div></div>
                        }
                        
                        {/*<div>
                          <span className="heading">89</span>
                          <span className="description">Partecipanti totali</span>
                        </div>*/}
                        
                      </div>
                    </Col>
                  </Row>
                  <div className="text-center mt-5">
                    <h3>
                      {this.state.user.Nome} {this.state.user.Cognome}{" "}
                       {/*<span className="font-weight-light">, 27</span>*/}
                       
                    </h3>
                    {this.state.userParent?<div><span class="badge badge-secondary">associata a:  {this.state.userParent.rsociale + " "+ this.state.userParent.Mail}</span></div>:<span></span>}
                    <div className="h6 font-weight-300">
                      <i className="ni location_pin mr-2" />
                      {this.state.user.Mail}
                    </div>
                    <div className="h6 mt-4">
                      <i className="ni business_briefcase-24 mr-2" />
                      Tipo di utente: {this.state.user.IDMenuCategory}, sessione scade {this.getSessExp(this.state.user.exp)}
                      <br></br>
                      {this.state.stats.abb.length>0?
                        <span>Abbonamento in scadenza il: {moment(this.state.stats.abb[0].ExpireOn).format('DD-MM-YYYY')}</span>
                        :
                        <span><i className="badge badge-danger">NESSUN ABBONAMENTO</i></span>
                      }
                      
                    </div>                    
                  </div>
                  {this.state.stats.abb.length>0?
                    <div className="mt-5 py-5 border-top text-center">
                      <Row className="justify-content-center">
                        <Col lg="9">
                          <p>
                            Il tuo abbonamento è di Tipo "{this.state.stats.boundaries.userStats.Title}" - {this.state.stats.boundaries.userStats.Description}
                          </p>
                          <a href="/#/acquista">
                            Vedi tutti gli abbonamenti
                          </a>
                        </Col>
                      </Row>
                    </div>:
                    <div className="mt-5 py-5 border-top text-center">
                        <Row className="justify-content-center">
                          <Col lg="9">
                            <h4 className="text-red">Nessun Abbonamento Trovato !</h4>
                            <a href="/#/acquista">
                              Vedi tutti gli abbonamenti
                            </a>
                          </Col>
                        </Row>
                    </div>
                  }
                </div>
              </Card>
            </Container>
          </section>
        </main>
        
      </>
    );
  }
}

export default Profile;
