/*!

=========================================================
* Argon Design System React - v1.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-design-system-react
* Copyright 2020 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/argon-design-system-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";
import { Link } from "react-router-dom";
// JavaScript plugin that hides or shows a component based on your scroll
import Headroom from "headroom.js";
import $ from 'jquery';

// reactstrap components
import {
  Button,
  UncontrolledCollapse,
  DropdownMenu,
  DropdownToggle,
  UncontrolledDropdown,
  Media,
  NavbarBrand,
  Navbar,
  NavItem,
  NavLink,
  Nav,
  Container,
  Row,
  Col,
  UncontrolledTooltip
} from "reactstrap";

class DemoNavbar extends React.Component {
  componentDidMount() {
    let headroom = new Headroom(document.getElementById("navbar-main"));
    // initialise
    headroom.init();
  }
  state = {
    collapseClasses: "",
    collapseOpen: false
  };

  onExiting = () => {
    this.setState({
      collapseClasses: "collapsing-out"
    });
  };

  onExited = () => {
    this.setState({
      collapseClasses: ""
    });
  };

  render() {
    return (
      <>
        <header className="header-global">
          <Navbar
            className="navbar-main navbar-transparent navbar-light headroom"
            expand="lg"
            id="navbar-main"
          >
            <Container>
              {<NavbarBrand className="" style={{"marginRight":"0!important"}} to="/" tag={Link}> {/** mr-lg-5 */}
                <img
                  alt="..."
                  style={{"height":"60px!important"}}
                  src={require("assets/img/brand/esopo_no_transparent.png")}
                />
              </NavbarBrand>
                }
              <button className="navbar-toggler" id="navbar_global">
                <span className="navbar-toggler-icon" />
              </button>
              <UncontrolledCollapse
                toggler="#navbar_global"
                navbar
                className={this.state.collapseClasses}
                onExiting={this.onExiting}
                onExited={this.onExited}
              >
                <div className="navbar-collapse-header">
                  <Row>
                    <Col className="collapse-brand" xs="6">
                      {<Link to="/">
                        <img
                          alt="..."
                          style={{"height":"50px!important"}}
                          src={require("assets/img/brand/esopo_no_transparent.png")}
                        />
                    </Link>}
                    </Col>
                    <Col className="collapse-close" xs="6">
                      <button className="navbar-toggler" id="navbar_global">
                        <span />
                        <span />
                      </button>
                    </Col>
                  </Row>
                </div>
               
                <Nav className="navbar-nav-hover align-items-lg-center" navbar>
                  <UncontrolledDropdown nav>
                    <DropdownToggle nav>
                      <i className="ni ni-ui-04 d-lg-none mr-1" />
                      <span className="nav-link-inner--text">Abbonamenti</span>
                    </DropdownToggle>
                    <DropdownMenu className="dropdown-menu-xl">
                      <div className="dropdown-menu-inner">
                        <Media
                          className="d-flex align-items-center"
                          href="/#/acquista"
                          
                        >
                          <div className="icon icon-shape bg-gradient-primary rounded-circle text-white">
                            <i className="ni ni-spaceship" />
                          </div>
                          <Media body className="ml-3">
                            <h6 className="heading text-primary mb-md-1">
                              Premium - Pack
                            </h6>
                            <p className="description d-none d-md-inline-block mb-0">
                              Per <b className="font-weight-bold">Musei, Agenzie di Viaggio e di Guide</b>, Fino a 10 utenti. Scegli il pacchetto più adatto alle tue esigenze!
                            </p>
                          </Media>
                        </Media>
                        <Media
                          className="d-flex align-items-center"
                          href="/#/acquista"
                          
                        >
                          <div className="icon icon-shape bg-gradient-primary rounded-circle text-white">
                            <i className="ni ni-spaceship" />
                          </div>
                          <Media body className="ml-3">
                            <h5 className="heading text-primary mb-md-1">
                              Premium - Unlimited
                            </h5>
                            <p className="description d-none d-md-inline-block mb-0">
                            Per <b className="font-weight-bold">Musei, Agenzie di Viaggio e di Guide</b>, Fino a 10 utenti. EVENTI Illimitati per un periodo di 6 mesi o 1 anno !
                            </p>
                          </Media>
                        </Media>
                        <Media
                          className="d-flex align-items-center"
                          href="/#/acquista"
                          
                        >
                          <div className="icon icon-shape bg-gradient-success rounded-circle text-white">
                            <i className="ni ni-palette" />
                          </div>
                          <Media body className="ml-3">
                            <h6 className="heading text-primary mb-md-1">
                              One
                            </h6>
                            <p className="description d-none d-md-inline-block mb-0">
                              Per <b className="font-weight-bold">Guide</b> Freelance! Scegli il pacchetto con un numero prefissato di eventi e inizia la tua guida.
                            </p>
                          </Media>
                        </Media>
                        {/*<Media
                          className="d-flex align-items-center"
                          href="https://demos.creative-tim.com/argon-design-system-react/#/documentation/alert?ref=adsr-navbar"
                          target="_blank"
                        >
                          <div className="icon icon-shape bg-gradient-warning rounded-circle text-white">
                            <i className="ni ni-ui-04" />
                          </div>
                          <Media body className="ml-3">
                            <h5 className="heading text-warning mb-md-1">
                              Guida
                            </h5>
                            <p className="description d-none d-md-inline-block mb-0">
                              Adatto alla guida freelance che necessita di uno strumento smart per gestire le proprie visite guidate.
                            </p>
                          </Media>
                        </Media>
                        */}
                      </div>
                    </DropdownMenu>
                  </UncontrolledDropdown>                  
                   
                </Nav>
                <Nav className="navbar-nav-hover align-items-lg-center mr-4" navbar>
                  {/*onClick={()=>{                   
                    $('html,body').animate({
                        scrollTop: $("#" + 'infohowto').offset().top
                    }, 'slow');
                  }} }*/}
                  <a href="https://youtu.be/31KzBMmqUig" target="blank" className="text-white " style={{fontSize:'14px',cursor:'pointer'}}>Come Funziona?</a>
                </Nav>
                <Nav className="navbar-nav-hover align-items-lg-center" navbar>
                  {/*onClick={()=>{                   
                    $('html,body').animate({
                        scrollTop: $("#" + 'infohowto').offset().top
                    }, 'slow');
                  }} }*/}
                  <a href="https://e-sopo.com/static/media/Manuali_E-SOPO.zip" target="_blank" className="text-white" style={{fontSize:'14px',cursor:'pointer'}}>Scarica il manuale d'uso</a>
                </Nav>
                <Nav className="align-items-lg-center ml-lg-auto" navbar>
                  {/*<NavItem>
                    <NavLink
                      className="nav-link-icon"
                      href="https://www.facebook.com/esopoguide"
                      id="tooltip333589074"
                      target="_blank"
                    >
                      <i className="fa fa-facebook-square" />
                      <span className="nav-link-inner--text d-lg-none ml-2">
                        Facebook
                      </span>
                    </NavLink>
                    <UncontrolledTooltip delay={0} target="tooltip333589074">
                      Seguici su Facebook
                    </UncontrolledTooltip>
                  </NavItem>
                  <NavItem>
                    <NavLink
                      className="nav-link-icon"
                      href="https://www.instagram.com/e_sopo_guide"
                      id="tooltip356693867"
                      target="_blank"
                    >
                      <i className="fa fa-instagram" />
                      <span className="nav-link-inner--text d-lg-none ml-2">
                        Instagram
                      </span>
                    </NavLink>
                    <UncontrolledTooltip delay={0} target="tooltip356693867">
                      Seguici su Instagram
                    </UncontrolledTooltip>
                  </NavItem>  
                  */}
                  <NavItem className="d-none d-lg-block">  
                    <Button
                            className="btn-white btn-icon mb-3 mb-sm-0 ml-1"
                            color="default"
                            href="/#/register"
                          >
                            <span className="btn-inner--icon mr-1">
                              <i className="ni ni-cloud-download-95" />
                            </span>
                            <span className="btn-inner--text">
                              Iscriviti
                            </span>
                      </Button>             
                  </NavItem>
                  <NavItem className="d-none d-lg-block"> {/*  ml-lg-4 */}
                    <Button
                      className="btn-neutral btn-icon"
                      color="default"
                      href="/#/login"
                      target="_self"
                    >
                      <span className="btn-inner--icon">
                        <i className="fa fa-sign-in mr-2" />
                      </span>
                      <span className="nav-link-inner--text ml-1">
                       <span>Log in</span>
                      </span>
                    </Button>
                  </NavItem>
                </Nav>
              </UncontrolledCollapse>
            </Container>
          </Navbar>
        </header>
      </>
    );
  }
}

export default DemoNavbar;
