//pagina guest per chi si connette e vuole partecipare a streaming
/*!

=========================================================
* Argon Design System React - v1.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-design-system-react
* Copyright 2020 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/argon-design-system-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";
import { Route, Switch, Redirect } from "react-router-dom";
// reactstrap components
import {   
    Button,   
    Container,
    Row,
    Col
  } from "reactstrap";
// core components
import Header from "components/Navbars/Header.js";
import CardsFooter from "components/Footers/Footer.js";
import GestioneEvento from "views/dashboard/GestioneEvento.js";


// index page sections
//import Download from "../IndexSections/Download.js";

class Join extends React.Component {
  state = {};
  constructor(props){
      //console.log("props join",props)
      super(props)
        this.state.id=props.match.params.id
  }
  componentDidMount() {
    //this.setState({id:props.match.params.id})
  }


  render() {
    return (
      <>
        <Header />  
        <GestioneEvento id={this.state.id}></GestioneEvento>  
        
        <CardsFooter />
      </>
    );
  }
}

export default Join;
