
import React,{Fragment} from "react";

import {
  Container,
  Row,
  Col,
  Table,
  Card,
  CardBody,
  CardFooter,
  Nav,
  Button,
  UncontrolledTooltip,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Form,
  FormGroup,
  Input,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  Label
} from "reactstrap";
import {Formik,Field,ErrorMessage} from "formik";
import swal from "sweetalert2"
//pagination 
import Pagination from "react-js-pagination";

//require("bootstrap/less/bootstrap.less");

//importo helpers per utente
import user from "../../helpers/user.js";
import sub from "../../helpers/subscriptions.js";



class AnagraficheUtenti extends React.Component {

  constructor(props){
    super(props)
    this.state = {
      users:[],
      activePage: 1,
      itemPerPage: 10,
      isModalEditOpen:false,
      isModalNewOpen:false,
      selectedUser:{},
      subscriptions:[],    
      userslevel:[]
    }
    this.handlePageChange = this.handlePageChange.bind(this);
    this.handleDeleteUser = this.handleDeleteUser.bind(this);
    this.handleRequestNew = this.handleRequestNew.bind(this);
    this.handleRequestEdit = this.handleRequestEdit.bind(this);
    this.handleDetails = this.handleDetails.bind(this)
    this.toggleModalEdit = this.toggleModalEdit.bind(this)
    this.handleResetPsw = this.handleResetPsw.bind(this)
    this.handleUsersLevelChange = this.handleUsersLevelChange.bind(this)
    this.validateForm = this.validateForm.bind(this);

  }

  handlePageChange(pageNumber){
    //console.log("PAGE NUMBER",pageNumber)
    this.setState({ activePage: pageNumber });
  }

  //inserisce nuovo utente a db
  handleRequestNew(usrIn){
    console.log("gestione richiesta nuovo utente",usrIn)
    //sistemo oggetto in ingresso 
    var objIn = {
      nome:usrIn.Nome,
      cognome:usrIn.Cognome,
      IDMenuCategory:usrIn.IDMenuCategory,
      password:usrIn.password,
      email:usrIn.Mail
    }
    //creazione utente da parte di amministratore
    user.insert(objIn).then(rr=>{
      //messaggio di successo 
      swal.fire(
        'Inserito!',
        'L utente è creato',
        'success'
      )

      ///chiudo popup
      //this.toggleModalEdit()      
      this.setState({isModalEditOpen:!this.state.isModalEditOpen})

    }).catch(err=>{

    }).finally(()=>{     
      //refresh utenti 
      this.refreshUsers()      
    })

  }

  //modifica un utente 
  handleRequestEdit(usrIn){
    console.log("gestione edit utente",usrIn)
    console.log("gestione edit utente selecteduser",this.state.selectedUser)

    //modifico selected user con campi arrivati da objIn
    var objIn={
      ...this.state.selectedUser,
      ...usrIn
    }
    //modifica di utente da parte di amministratore
    user.edit(objIn).then(rr=>{
      //messaggio di successo 
      swal.fire(
        'Modificato!',
        'L utente è stato modificato',
        'success'
      )

      ///chiudo popup
      //this.toggleModalEdit()      
      this.setState({isModalEditOpen:!this.state.isModalEditOpen})
      
    }).catch(err=>{

    }).finally(()=>{
      ///refresh utenti
      this.refreshUsers()  
      
    })
  }

  //apre/chiude popup modal per inserire un nuovo utente
  /*
  toggleModalNew(e){
    e.preventDefault()
    this.setState({isModalNewOpen:!this.state.isModalNewOpen})
  }*/

  //mostra dettagli di utente
  handleDetails(){
    
  }

  toggleModalEdit(e,item){
    
    if(item){
      //la password non è visibile se è in modifica quindi se esiste il camo selectedUser.ID
      this.setState({selectedUser:item})
      //preseleziono idmenucategory per utente selezionato 
      this.setState({IDMenuCategory:item.IDMenuCategory})
      //this.state.IDMenuCategory=item.IDMenuCategory
    }else{
      //caso nuovo utente o chiusura popup 
      this.setState({selectedUser:{Nome:'',Cognome:'',Mail:'',Password:'',isEnabled:1,IDMenuCategory:''}})
    }

    e.preventDefault()
    this.setState({isModalEditOpen:!this.state.isModalEditOpen})
  }

  //resetta password di utente
  handleResetPsw(userObj){
    //popup per richiedere nuova psw 
    console.log('utente elezionato ',userObj)

    swal.fire({
      title: 'Modifica la password',
      input: 'password',
      inputLabel: 'Password',
      inputPlaceholder: 'Scrivi qui la password',
      inputAttributes: {
        maxlength: 50,
        autocapitalize: 'off',
        autocorrect: 'off'
      }
    }).then(result=>{
      //console.log("result.value",result.value)
      //controllo se input valido 
      if(result.value){
        var newPsw = result.value;
        //chiamo procedura a backend per fare update di password a db 
        user.resetPsw({id:userObj.ID,psw:newPsw}).then(res=>{
          //password modificata correttamente mostro avviso 
          swal.fire('Fatto','Password per utente '+userObj.Mail+" modificata correttamente!",'success')

        }).catch(err=>{
          //errore nel cambio password , mostro popup scegliere un atra password ? 
          
        })
      }else{
        swal.fire('Attenzione!','inserisci una password valida','warning')
      }

    })
    
    
  }

  handleChangeIsEnabled(e,item){
    console.log("e.target.checked checkbox",e.target.checked)
    if(e.target.checked)
      item.value=1;
    else
      item.value=0;
  }

  validateForm(values){
    const emailTest = /^[^\s@]+@[^\s@]+\.[^\s@]{2,}$/i;
    let errors = {};


    if (values.Nome === "") {
        errors.Nome = "Nome obbligatorio";
    }
    if (values.Cognome === "") {
      errors.Cognome = "Cognome obbligatorio";
    }

    if (values.Mail === "") {
      errors.Mail = "Mail obbligatoria";
    } else if (!emailTest.test(values.Mail)) {
      errors.Mail = "Formato mail invalido";
    }

    //controllo la password se sono in inserimento 
    if(this.state.selectedUser){
      //console.log("esiste selected user per contrllo id ")
      if(!this.state.selectedUser.ID){
        //console.log("non esiste id per controllo password",this.state.selectedUser)
        if (values.password === "") {
          errors.password = "Password obbligatoria";
        }
      }
    }

    //controllo abbonamento selezionato 


    //controllo tipo di utente selezionato 
    if (values.IDMenuCategory == "") {
      errors.IDMenuCategory = "Tipo di utente obbligatorio";
    } 


    //console.log("values in validate ",values )

    return errors;
  }

  handleDeleteUser(e,userobj){
    //pannello di conferma 
    swal.fire({
      title: 'Confermi?',
      text: "Stai per eliminare l'utente "+userobj.Nome + " "+userobj.Cognome,
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Conferma'
    }).then((result) => {
      if (result.isConfirmed) {
        //console.log("e",e)
        //console.log("ID",ID)
        user.deleteUser(userobj.ID).then(res=>{
          //console.log("res",res)
          //this.setState({users:res})
          swal.fire(
            'Eliminato!',
            'L utente è stato eliminato',
            'success'
          )
          this.refreshUsers()
        }).catch(err=>{
          console.log("err",err)
        })
      }
    })

    
  }

  refreshUsers(){
    user.getAllUsers().then(res=>{
      //console.log("res",res)
      this.setState({users:res})
    }).catch(err=>{
      console.log("err",err)
    })
  }

  handleUsersLevelChange(e){
    e.persist()
    this.setState({IDMenuCategory:e.target.value})
    //console.log("this.state",_t.state)
    //scarico abbonamenti in funzione di livello utente selezionato 
    /*sub.getSubscriptions(e.target.value).then(res=>{
        console.log("result handleUsersLevelChange",res)
        this.setState({subscriptions:res})
        if(res.length>0){
            this.setState({subscriptionID:res[0].ID})
        }
    }).catch(err=>{
        console.log("err",err)
    })
    */

  }


  componentDidMount(){
    this.refreshUsers()


    //scarico le tipologie di utente 
    sub.getUsersLevel().then(res=>{
      //console.log("result tipo utente",res)
      this.setState({userslevel:res})
      //setto primo indice di default 
      if(res.length>0){
          //this.setState({IDMenuCategory:res[0].ID})

          //invoco abbonamenti di quel livello 
          /*sub.getSubscriptions(res[0].ID).then(res=>{
              console.log("result",res)
              this.setState({subscriptions:res})
              if(res.length>0){
                  //this.setState({subscriptionID:res[0].ID})
              }
          }).catch(err=>{
              console.log("err",err)
          })
          */
      }
    }).catch(err=>{
        console.log("err tipo utente",err)
    })


  }
    render() {

      const { users, activePage, itemPerPage } = this.state;

      const indexOfLastTodo = activePage * itemPerPage;
      const indexOfFirstTodo = indexOfLastTodo - itemPerPage;
      const renderedUsers = users.slice(indexOfFirstTodo, indexOfLastTodo);

        return (
            <div className="position-relative">
            {/* shape Hero */}
            <section className="section section-md section-shaped pb-10">
              <div className="shape shape-style-1 shape-primary">
                <span />
                <span />
                <span />
                <span />
                <span />
                <span />
                <span />
                <span />
                <span />
              </div>
              <Container fluid className="py-lg-md d-flex" >
                <div className="col px-0">
                  <Row>
                    <Col lg="6">                        
                        <p className="pl-4 lead text-white">
                         Anagrafiche Utenti <span className="badge badge-secondary">{this.state.users.length}</span>
                        </p>                        
                    </Col>
                  </Row>
                </div>
              </Container>
              {/* SVG separator */}
              <div className="separator separator-bottom separator-skew">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  preserveAspectRatio="none"
                  version="1.1"
                  viewBox="0 0 2560 100"
                  x="0"
                  y="0"
                >
                  <polygon
                    className="fill-white"
                    points="2560 0 2560 100 0 100"
                  />
                </svg>
              </div>
            </section>
            <section className="section pb-10 pt-0">
            <Container fluid className="p-5" >
              {/*<Row className="row-grid align-items-center">
                
                <Col className="" lg="6" sm="12">
                  <div className="d-flex px-3">
                    <div>
                      <div className="icon icon-lg icon-shape bg-gradient-white shadow rounded-circle text-primary">
                        <i className="ni ni-building text-primary" />
                      </div>
                    </div>

                    <div className="pl-4">
                      <h4 className="display-3 ">
                          Eventi
                          <Button
                          className="mr-4 mt-2 float-right"
                          color="info"
                          href="#pablo"
                          onClick={e => e.preventDefault()}
                          size="sm"
                        >
                          <i className="fa fa-plus"></i> Crea Nuovo
                        </Button>
                        </h4>
                      <p className="">
                        Qui sotto puoi creare e gestire gli eventi di audio guida smart.
                      </p>
                    </div>
                  </div>
                  </Col>
                </Row>*/}
                {/* tabella utenti */}
                <Row>
                  <Col lg="12">
                    <div className="text-right pb-2">
                    <Button color="default" outline size="sm" type="button" onClick={this.toggleModalEdit}>    {/* era: this.toggleModalNew */}                  
                      <i className="fa fa-plus"></i> Nuovo
                    </Button>
                    </div>
                    <Card className=" shadow border-0">
                        <CardBody className="p-0">
                        <Table className="mb-0" size="sm" hover striped responsive>{/* size="sm"*/}
                          <thead>
                            <tr>
                              <th>#</th>
                              <th>Nome</th>
                              <th>Cognome</th>
                              <th>Mail</th>
                              <th>Tipo Utente</th>
                              <th>Abbonamento</th>
                              <th className="text-center">Abilitato</th>
                              <th style={{"minWidth":"130px"}}></th>
                            </tr>
                          </thead>
                          <tbody>
                            {renderedUsers.map((item,i)=>{
                              return <tr key={i}>
                                  <th scope="row"><small><b>{indexOfFirstTodo+i+1}</b></small></th>
                                  <td><small>{item.Nome}</small></td>
                                  <td><small>{item.Cognome}</small></td>
                                  <td><small>{item.Mail}</small></td>
                                  <td><small>{item.UserLevel}</small></td>
                                  <td><small>{item.Abbonamento}</small></td>
                                  <td className="text-center"><small className={item.isEnabled?'fa fa-check text-success':'fa fa-exclamation-circle text-danger'}></small></td>
                                  <td className=" td-actions text-right">
                                    <Button
                                      id={"tooltipsf"+i}
                                      onClick={(e)=>{this.handleResetPsw(item)}}
                                      className=" btn-icon btn-simple"
                                      color="info"
                                      size="sm"
                                      type="button"
                                    >
                                      <i className=" ni ni-key-25 pt-1"></i>
                                    </Button>
                                    <UncontrolledTooltip target={"tooltipsf"+i} placement={'auto'}>
                                      Modifica la password
                                    </UncontrolledTooltip>

                                    <Button
                                      id={"tooltidtl"+i}
                                      className=" btn-icon btn-simple"
                                      color="success"
                                      size="sm"
                                      type="button"
                                      onClick={(event)=>{this.toggleModalEdit(event,item)}}
                                    >
                                      <i className=" ni ni-settings-gear-65 pt-1"></i>
                                    </Button>
                                    <UncontrolledTooltip  target={"tooltidtl"+i} placement={'auto'}>
                                      Modifica
                                    </UncontrolledTooltip>

                                    <Button onClick={(e)=>{this.handleDeleteUser(e,item)}}
                                      id={"tooltidel"+i}
                                      className=" btn-icon btn-simple"
                                      color="danger"
                                      size="sm"
                                      type="button"
                                    >
                                      <i className=" fa fa-times pt-1"></i>
                                    </Button>
                                    <UncontrolledTooltip  target={"tooltidel"+i} placement={'auto'}>
                                      Elimina
                                    </UncontrolledTooltip>
                                  </td>
                                </tr>
                            })}                                                      
                          </tbody>
                        </Table>
                        </CardBody>
                        <CardFooter>
                          <Nav className="justify-content-center">
                            <Pagination
                                  hideNavigation
                                  activePage={this.state.activePage}                                  
                                  itemsCountPerPage={this.state.itemPerPage}
                                  totalItemsCount={this.state.users.length}
                                  pageRangeDisplayed={5}
                                  onChange={this.handlePageChange}
                                  itemClass="page-item"
                                  linkClass="page-link"                                 
                              />
                            </Nav>
                        </CardFooter>
                        </Card>
                  </Col>
                </Row>
                </Container>
                </section>


                {/*modal per modifica utente */}              
                <Modal isOpen={this.state.isModalEditOpen} toggle={this.toggleModalEdit} size="md">
                    <ModalHeader toggle={this.toggleModalEdit}>{this.state.selectedUser.Nome} {this.state.selectedUser.Cognome}</ModalHeader>
                          <Formik
                          enableReinitialize
                          validate={this.validateForm}                       
                          initialValues={{isEnabled:this.state.selectedUser.isEnabled==1, Nome:this.state.selectedUser.Nome,Cognome: this.state.selectedUser.Cognome,Mail:this.state.selectedUser.Mail,IDMenuCategory:this.state.selectedUser.IDMenuCategory  , password:""}} 
                          onSubmit={(values,{ setSubmitting }) => {
                                //console.log("values in submit",values)
                                //console.log("this.state",this.state.selectedUser.ID)
                                if(!this.state.selectedUser.ID){
                                  //console.log("nuovo utente")
                                  this.handleRequestNew(values)
                                }else{
                                  //console.log("Edit utente")
                                  this.handleRequestEdit(values)
                                }
                                
                                //alert("Form is validated! Submitting the form...");
                                //setSubmitting(false); role="form" name="formRegister"
                          }}
                          >{({ errors, touched,handleSubmit,handleChange,isSubmitting }) => (
                           <Fragment>
                            <Form onSubmit={handleSubmit} onChange={handleChange}>
                            <ModalBody>
                             
                                <Row>
                                  <Col>
                                    <FormGroup>                        
                                    <Field name="Nome">
                                        {({ field, form, meta }) => (
                                              <div>
                                                <Label><b>Nome</b></Label>
                                                  <InputGroup className="input-group-alternative">
                                                      <InputGroupAddon addonType="prepend">
                                                          <InputGroupText>
                                                          <i className="ni ni-caps-small" />
                                                          </InputGroupText>
                                                      </InputGroupAddon>
                                                      <Input {...field} 
                                                          disabled={isSubmitting}
                                                          placeholder="Nome utente"
                                                          type="text"
                                                          autoComplete="off"
                                                      />
                                                  </InputGroup>
                                                  {/*<input type="text" {...field} placeholder="First Name"/>*/}
                                                  {/*meta.touched && meta.error && <div className="error">{meta.error}</div>*/}
                                              </div>
                                        )}                               
                                    </Field>
                                    <ErrorMessage component="div" name="Nome" className="text-red small"/>
                                  </FormGroup>
                                </Col>
                                <Col>
                                    <FormGroup>                        
                                      <Field name="Cognome">
                                          {({ field, form, meta }) => (
                                                <div>
                                                    <Label><b>Cognome</b></Label>
                                                    <InputGroup className="input-group-alternative">
                                                        <InputGroupAddon addonType="prepend">
                                                            <InputGroupText>
                                                            <i className="ni ni-caps-small" />
                                                            </InputGroupText>
                                                        </InputGroupAddon>
                                                        <Input {...field} 
                                                            disabled={isSubmitting}
                                                            placeholder="Cognome utente"
                                                            type="text"
                                                            autoComplete="off"
                                                        />
                                                    </InputGroup>
                                                    {/*<input type="text" {...field} placeholder="First Name"/>*/}
                                                    {/*meta.touched && meta.error && <div className="error">{meta.error}</div>*/}
                                                </div>
                                          )}                               
                                      </Field>
                                      <ErrorMessage component="div" name="Cognome" className="text-red small"/>
                                    </FormGroup>
                                  </Col>
                                </Row>
                                <Row>
                                  <Col>
                                    {/* MAIL */}
                                    <FormGroup>
                                      <Field name="Mail">
                                          {({ field, form, meta }) => (
                                              <div>
                                                <Label><b>Mail</b></Label>
                                                  <InputGroup className="input-group-alternative">
                                                      <InputGroupAddon addonType="prepend">
                                                          <InputGroupText>
                                                          <i className="ni ni-email-83" />
                                                          </InputGroupText>
                                                      </InputGroupAddon>
                                                      <Input {...field} 
                                                          disabled={isSubmitting}
                                                          placeholder="Email"
                                                          type="mail"
                                                          autoComplete="off"
                                                      />
                                                  </InputGroup>
                                                  {/*<input type="text" {...field} placeholder="First Name"/>*/}
                                                  {/*meta.touched && meta.error && <div className="error">{meta.error}</div>*/}
                                              </div>
                                          )}                               
                                          </Field>
                                          <ErrorMessage component="div" name="Mail" className="text-red small"/>
                                    </FormGroup>
                                  </Col>
                                  <Col>
                                    {/* PASSWORD , visibile solo se in creazione utente , cioè se selectedUser.ID non esiste*/}
                                    {this.state.selectedUser.ID?
                                      <span></span>
                                      :
                                      <FormGroup>
                                      <Field name="password">
                                        {({ field, form, meta }) => (
                                            <div>
                                              <Label><b>Password</b></Label>
                                                <InputGroup className="input-group-alternative">
                                                    <InputGroupAddon addonType="prepend">
                                                        <InputGroupText>
                                                        <i className="ni ni-lock-circle-open" />
                                                        </InputGroupText>
                                                    </InputGroupAddon>
                                                    <Input {...field} 
                                                        disabled={isSubmitting}
                                                        placeholder="Password"
                                                        type="password"
                                                        autoComplete="off"
                                                    />
                                                </InputGroup>
                                                {/*<input type="text" {...field} placeholder="First Name"/>*/}
                                                {/*meta.touched && meta.error && <div className="error">{meta.error}</div>*/}
                                            </div>
                                        )}                               
                                        </Field>
                                        <ErrorMessage component="div" name="password" className="text-red small"/>
                                      </FormGroup>
                                    }
                                  </Col>
                                </Row>
                                <Row>
                                  <Col>
                                    {/* ABBONAMENTO */}

                                    <FormGroup>
                                      <Field name="IDMenuCategory">
                                          {({ field, form, meta }) => (
                                              <div> {/* value={this.state.IDMenuCategory} onChange={this.handleUsersLevelChange}*/ }
                                                  <Label><b>Tipo Utenza</b></Label>
                                                  <Input type="select" {...field} className="form-control-sm" placeholder={"Seleziona un tipo di utente"} onChange={this.handleUsersLevelChange} >
                                                      <option value="">Seleziona un elemento...</option>
                                                      {this.state.userslevel.map((item,i)=>{
                                                          return <option key={i} value={item.ID}>{item.DescrInRegister}</option>
                                                      })}
                                                  </Input>
                                                  {/*<input type="text" {...field} placeholder="First Name"/>*/}
                                                  {/*meta.touched && meta.error && <div className="error">{meta.error}</div>*/}
                                              </div>
                                          )}                               
                                          </Field>
                                          <ErrorMessage component="div" name="IDMenuCategory" className="text-red small"/>
                                        {/*<Label for="exampleSelect1" >Tipo di utente</Label>
                                        <Input type="select" name="select" className="form-control-sm" id="exampleSelect1" value={this.state.IDMenuCategory} onChange={this.handleUsersLevelChange}>
                                                    {this.state.userslevel.map((item,i)=>{
                                                    return <option key={i} value={item.ID}>{item.DescrInRegister}</option>
                                                    })}
                                        </Input>
                                                  */}
                                    </FormGroup>
                                  </Col>
                                  <Col>
                                    {/* UTENTE ABILITATO */}
                                    <FormGroup className="ml-4">
                                      <Field name="isEnabled">
                                        {({ field, form, meta }) => (
                                            <div>
                                              <Label>                                                                                   
                                                    <Input {...field} 
                                                        disabled={isSubmitting}                                                       
                                                        checked={field.value}
                                                        type="checkbox"
                                                        onChange={(e)=>{form.setFieldValue('isEnabled',e.target.checked)}}
                                                        autoComplete="off"
                                                    />
                                                    
                                                    <b>Abilitato {field.value}</b>
                                                    </Label>
                                                
                                                {/*<input type="text" {...field} placeholder="First Name"/>*/}
                                                {/*meta.touched && meta.error && <div className="error">{meta.error}</div>*/}
                                            </div>
                                        )}                               
                                        </Field>
                                        <ErrorMessage component="div" name="isEnabled" className="text-red small"/>
                                      </FormGroup>

                                    {/* TIPO ABBONAMENTO 
                                    <FormGroup>
                                          <Field name="subscriptionID">
                                          {({ field, form, meta }) => (
                                              <div> 
                                                  <Label><b>Abbonamento</b></Label>
                                                  <Input type="select" {...field} className="form-control-sm"  >
                                                      <option value="">Seleziona un elemento...</option>
                                                      {this.state.subscriptions.map((item,i)=>{
                                                          return <option key={i} value={item.ID}>{item.Title} - ({item.PriceMonth} € al mese)</option>
                                                      })}
                                                  </Input>
                                                
                                              </div>
                                          )}                               
                                          </Field>
                                          <ErrorMessage component="div" name="subscriptionID" className="text-red small"/>
                                         
                                    </FormGroup>
                                    */}
                                  </Col>
                                </Row>
                                
                            </ModalBody>
                            <ModalFooter>                              
                              {/* tasti conferma per modifica , inserisci per nuovo e chiudi */}
                              <Button color="secondary" disabled={this.state.isFormValid}  onClick={this.toggleModalEdit}>Annulla</Button>
                              {this.state.selectedUser.ID?
                              <Button color="primary" type="submit" disabled={(Object.keys(errors).length>0)?true:false}>Modifica</Button>
                              :
                              <Button color="primary" type="submit" disabled={(Object.keys(touched).length==0 || Object.keys(errors).length>0)?true:false}>Inserisci</Button>
                              }

                            </ModalFooter>
                            </Form> 
                            </Fragment>
                          )}


                  
                    </Formik>
                </Modal>
        </div>
           
        )
    }
}

export default AnagraficheUtenti;