
import React,{Fragment} from "react";

import {
  Container,
  Row,
  Col,
  Table,
  Card,
  CardBody,
  CardFooter,
  CustomInput,
  Nav,
  Button,
  UncontrolledTooltip,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Form,
  FormGroup,
  Input,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  Label
} from "reactstrap";
import {Formik,Field,ErrorMessage} from "formik";
import swal from "sweetalert2"
//pagination 
import Pagination from "react-js-pagination";

//require("bootstrap/less/bootstrap.less");

//importo helpers per utente
import coupon from "../../helpers/coupon.js";

import moment from 'moment'



class AnagraficheUtenti extends React.Component {

  constructor(props){
    super(props)
    this.state = {
      coupons:[],
      activePage: 1,
      itemPerPage: 10,
      isModalEditOpen:false,
      isModalNewOpen:false,
      selectedCoupon:{},
      isActivated:false,
      isDeleted:false
     
    }
    this.handlePageChange = this.handlePageChange.bind(this);
    this.handleDeleteCoupon = this.handleDeleteCoupon.bind(this);
    this.handleRequestNew = this.handleRequestNew.bind(this);
    this.handleRequestEdit = this.handleRequestEdit.bind(this);
    this.handleDetails = this.handleDetails.bind(this)
    this.toggleModalEdit = this.toggleModalEdit.bind(this) 
    this.validateForm = this.validateForm.bind(this);
    this.refreshCoupons = this.refreshCoupons.bind(this);

  }

  handlePageChange(pageNumber){
    //console.log("PAGE NUMBER",pageNumber)
    this.setState({ activePage: pageNumber });
  }

  //inserisce nuovo utente a db
  handleRequestNew(usrIn){
    console.log("gestione richiesta nuovo utente",usrIn)
    //sistemo oggetto in ingresso 
    var objIn = {
      Codice:usrIn.Codice,
      Durata:usrIn.Durata,
      Sconto:usrIn.Sconto,
      MaxActivation:usrIn.MaxActivation
    }
    //creazione utente da parte di amministratore
    coupon.insert(objIn).then(rr=>{
      //messaggio di successo 
      swal.fire(
        'Inserito!',
        'Coupon inserito correttamente',
        'success'
      )

      ///chiudo popup
      //this.toggleModalEdit()      
      this.setState({isModalEditOpen:!this.state.isModalEditOpen})

    }).catch(err=>{

    }).finally(()=>{     
      //refresh utenti 
      this.refreshCoupons()      
    })

  }

  //modifica un utente 
  handleRequestEdit(usrIn){
    console.log("gestione edit utente",usrIn)
    console.log("gestione edit utente selectedCoupon",this.state.selectedCoupon)

    //modifico selected user con campi arrivati da objIn
    var objIn={
      ...this.state.selectedCoupon,
      ...usrIn
    }
    //modifica di utente da parte di amministratore
    coupon.edit(objIn).then(rr=>{
      //messaggio di successo 
      swal.fire(
        'Modificato!',
        'Il coupon è stato modificato',
        'success'
      )

      ///chiudo popup
      //this.toggleModalEdit()      
      this.setState({isModalEditOpen:!this.state.isModalEditOpen})
      
    }).catch(err=>{

    }).finally(()=>{
      ///refresh coupons
      this.refreshCoupons()  
      
    })
  }

  //apre/chiude popup modal per inserire un nuovo utente
  /*
  toggleModalNew(e){
    e.preventDefault()
    this.setState({isModalNewOpen:!this.state.isModalNewOpen})
  }*/

  //mostra dettagli di utente
  handleDetails(){
    
  }

  toggleModalEdit(e,item){
    
    if(item){
      //la password non è visibile se è in modifica quindi se esiste il camo selectedCoupon.ID
      this.setState({selectedCoupon:item})
      //preseleziono idmenucategory per utente selezionato 
      this.setState({IDMenuCategory:item.IDMenuCategory})
      //this.state.IDMenuCategory=item.IDMenuCategory
    }else{
      //caso nuovo utente o chiusura popup 
      this.setState({selectedCoupon:{Codice:'',Sconto:0,Durata:5,MaxActivation:1}})
    }

    e.preventDefault()
    this.setState({isModalEditOpen:!this.state.isModalEditOpen})
  }


  handleChangeIsEnabled(e,item){
    console.log("e.target.checked checkbox",e.target.checked)
    if(e.target.checked)
      item.value=1;
    else
      item.value=0;
  }

  validateForm(values){
    
    let errors = {};


    if (values.Codice === "") {
        errors.Codice = "Codice sconto obbligatorio";
    }
    if (values.Durata === "") {
      errors.Durata = "Durata obbligatoria";
    }

    if (values.Sconto === "") {
      errors.Sconto = "Sconto obbligatorio";
    } else if (values.Sconto>100 || values.Sconto<0) {
      errors.Sconto = "Formato sconto invalido";
    }


    //console.log("values in validate ",values )

    return errors;
  }

  handleDeleteCoupon(e,couponObj){
    //pannello di conferma 
    swal.fire({
      title: 'Confermi?',
      text: "Stai per eliminare il copon con codice "+couponObj.Codice ,
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Conferma'
    }).then((result) => {
      if (result.isConfirmed) {
        //console.log("e",e)
        //console.log("ID",ID)
        coupon.del(couponObj.ID).then(res=>{
          //console.log("res",res)
          //this.setState({users:res})
          swal.fire(
            'Eliminato!',
            'Il coupon è stato eliminato',
            'success'
          )
          this.refreshCoupons()
        }).catch(err=>{
          console.log("err",err)
        })
      }
    })

    
  }

  refreshCoupons(e,e1){
    var isActivated = this.state.isActivated?true:false;
    var isDeleted = this.state.isDeleted?true:false;
    if(e) {
        e.persist()
        isActivated = e.target.checked
        this.setState({isActivated:isActivated})
    }

    if(e1) {
      e1.persist()
      isDeleted = e1.target.checked
      this.setState({isDeleted:isDeleted})
    }
  
    coupon.getCoupons(isActivated,isDeleted).then(res=>{
      console.log("res",res)
      this.setState({coupons:res})
    }).catch(err=>{
      console.log("err",err)
    })
  }

  handleUsersLevelChange(e){
    e.persist()
    this.setState({IDMenuCategory:e.target.value})
    //console.log("this.state",_t.state)
    //scarico abbonamenti in funzione di livello utente selezionato 
    /*sub.getSubscriptions(e.target.value).then(res=>{
        console.log("result handleUsersLevelChange",res)
        this.setState({subscriptions:res})
        if(res.length>0){
            this.setState({subscriptionID:res[0].ID})
        }
    }).catch(err=>{
        console.log("err",err)
    })
    */

  }


  componentDidMount(){
    this.refreshCoupons()


  }
    render() {

      const { coupons, activePage, itemPerPage } = this.state;

      const indexOfLastTodo = activePage * itemPerPage;
      const indexOfFirstTodo = indexOfLastTodo - itemPerPage;
      const renderedCoupon = coupons.slice(indexOfFirstTodo, indexOfLastTodo);

        return (
            <div className="position-relative">
            {/* shape Hero */}
            <section className="section section-md section-shaped pb-10">
              <div className="shape shape-style-1 shape-primary">
                <span />
                <span />
                <span />
                <span />
                <span />
                <span />
                <span />
                <span />
                <span />
              </div>
              <Container fluid className="py-lg-md d-flex" >
                <div className="col px-0">
                  <Row>
                    <Col lg="6">                        
                        <p className="pl-4 lead text-white">
                         Anagrafiche Coupon <span className="badge badge-secondary">{this.state.coupons.length}</span>
                        </p>                        
                    </Col>
                  </Row>
                </div>
              </Container>
              {/* SVG separator */}
              <div className="separator separator-bottom separator-skew">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  preserveAspectRatio="none"
                  version="1.1"
                  viewBox="0 0 2560 100"
                  x="0"
                  y="0"
                >
                  <polygon
                    className="fill-white"
                    points="2560 0 2560 100 0 100"
                  />
                </svg>
              </div>
            </section>
            <section className="section pb-10 pt-0">
            <Container fluid className="p-5" >
              {/*<Row className="row-grid align-items-center">
                
                <Col className="" lg="6" sm="12">
                  <div className="d-flex px-3">
                    <div>
                      <div className="icon icon-lg icon-shape bg-gradient-white shadow rounded-circle text-primary">
                        <i className="ni ni-building text-primary" />
                      </div>
                    </div>

                    <div className="pl-4">
                      <h4 className="display-3 ">
                          Eventi
                          <Button
                          className="mr-4 mt-2 float-right"
                          color="info"
                          href="#pablo"
                          onClick={e => e.preventDefault()}
                          size="sm"
                        >
                          <i className="fa fa-plus"></i> Crea Nuovo
                        </Button>
                        </h4>
                      <p className="">
                        Qui sotto puoi creare e gestire gli eventi di audio guida smart.
                      </p>
                    </div>
                  </div>
                  </Col>
                </Row>*/}
                {/* tabella utenti */}
                <Row>
                  <Col lg="12">
                    <div className="text-right pb-2">
                       
                        <CustomInput className="ml-4 float-left mr-4" type="switch" id="exampleCustomSwitch" name="customSwitch" label="Mostra Attivati" onChange={(e)=>{this.refreshCoupons(e)}}/>
                        <CustomInput className="ml-4 float-left mr-4" type="switch" id="exampleCustomSwitch2" name="customSwitch2" label="Mostra Eliminati" onChange={(e)=>{this.refreshCoupons(null,e)}}/>
                        <small className="float-left ml-4"><i>I giorni di durata sono irilevanti in caso di sconto inferiore al 100%</i></small>
                        <Button color="default" outline size="sm" type="button" onClick={this.toggleModalEdit}>    {/* era: this.toggleModalNew */}                  
                        <i className="fa fa-plus"></i> Nuovo
                        </Button>
                    </div>
                    <Card className=" shadow border-0">
                        <CardBody className="p-0">
                        <Table className="mb-0" size="sm" hover striped responsive>{/* size="sm"*/}
                          <thead>
                            <tr>
                              <th>#</th>
                              <th>Codice</th>
                              <th>% Sconto</th>
                              <th>Giorni durata</th>
                              <th>Mail Attivazione</th>
                              <th>Data Creazione</th>
                              <th>Data Attivazione</th>
                              <th>Attivato</th>  
                              <th>Num Attivazioni</th> 
                              <th></th>                           
                            </tr>
                          </thead>
                          <tbody>
                            {renderedCoupon.map((item,i)=>{
                              return <tr key={i}>
                                  <th scope="row"><small><b>{indexOfFirstTodo+i+1}</b></small></th>
                                  <td><small>{item.Codice}</small></td>
                                  <td><small>{item.PercentualeSconto}</small></td>
                                  <td><small>{item.Durata}</small></td>
                                  <td><small>{item.MailActivated}</small></td>
                                  <td><small>{moment(item.DateAdd).format('DD-MM-YYYY HH:mm:ss')}</small></td>
                                  <td><small>{item.DateActivated?moment(item.DateActivated).format('DD-MM-YYYY HH:mm:ss'):''}</small></td>
                                  <td><small className={item.isActivated>0?'fa fa-check text-success':'fa fa-hourglass text-danger'}></small></td>   
                                  <td><small> {item.isActivated} / {item.MaxActivation}</small></td> 
                                  <td>
                                    {item.isDeleted!=1?
                                      <>
                                        <Button onClick={(e)=>{this.handleDeleteCoupon(e,item)}}
                                          id={"tooltidel"+i}
                                          className=" btn-icon btn-simple"
                                          color="danger"
                                          size="sm"
                                          type="button"
                                        >
                                          <i className=" fa fa-times pt-1"></i>
                                        </Button>
                                        <UncontrolledTooltip  target={"tooltidel"+i} placement={'auto'}>
                                          Elimina
                                        </UncontrolledTooltip>
                                      </>:
                                      <></>
                                    }
                                  </td>                              
                                </tr>
                            })}                                                      
                          </tbody>
                        </Table>
                        </CardBody>
                        <CardFooter>
                          <Nav className="justify-content-center">
                            <Pagination
                                  hideNavigation
                                  activePage={this.state.activePage}                                  
                                  itemsCountPerPage={this.state.itemPerPage}
                                  totalItemsCount={this.state.coupons.length}
                                  pageRangeDisplayed={5}
                                  onChange={this.handlePageChange}
                                  itemClass="page-item"
                                  linkClass="page-link"                                 
                              />
                            </Nav>
                        </CardFooter>
                        </Card>
                  </Col>
                </Row>
                </Container>
                </section>


                {/*modal per modifica utente */}              
                <Modal isOpen={this.state.isModalEditOpen} toggle={this.toggleModalEdit} size="md">
                    <ModalHeader toggle={this.toggleModalEdit}>{this.state.selectedCoupon.Codice} {this.state.selectedCoupon.Sconto}</ModalHeader>
                          <Formik
                          enableReinitialize
                          validate={this.validateForm}                       
                          initialValues={{Codice:this.state.selectedCoupon.Codice,Sconto: this.state.selectedCoupon.Sconto,Durata:this.state.selectedCoupon.Durata,MaxActivation:this.state.selectedCoupon.MaxActivation}} 
                          onSubmit={(values,{ setSubmitting }) => {
                                //console.log("values in submit",values)
                                //console.log("this.state",this.state.selectedCoupon.ID)
                                if(!this.state.selectedCoupon.ID){
                                  //console.log("nuovo utente")
                                  this.handleRequestNew(values)
                                }else{
                                  //console.log("Edit utente")
                                  this.handleRequestEdit(values)
                                }
                                
                                //alert("Form is validated! Submitting the form...");
                                //setSubmitting(false); role="form" name="formRegister"
                          }}
                          >{({ errors, touched,handleSubmit,handleChange,isSubmitting }) => (
                           <Fragment>
                            <Form onSubmit={handleSubmit} onChange={handleChange}>
                            <ModalBody>
                             
                                <Row>
                                  <Col>
                                    <FormGroup>                        
                                    <Field name="Codice">
                                        {({ field, form, meta }) => (
                                              <div>
                                                <Label><b>Codice</b></Label>
                                                  <InputGroup className="input-group-alternative">
                                                      <InputGroupAddon addonType="prepend">
                                                          <InputGroupText>
                                                          <i className="ni ni-caps-small" />
                                                          </InputGroupText>
                                                      </InputGroupAddon>
                                                      <Input {...field} 
                                                          disabled={isSubmitting}
                                                          placeholder="Codice Coupon"
                                                          type="text"
                                                          autoComplete="off"
                                                      />
                                                  </InputGroup>
                                                  {/*<input type="text" {...field} placeholder="First Name"/>*/}
                                                  {/*meta.touched && meta.error && <div className="error">{meta.error}</div>*/}
                                              </div>
                                        )}                               
                                    </Field>
                                    <ErrorMessage component="div" name="Codice" className="text-red small"/>
                                  </FormGroup>
                                </Col>
                                <Col>
                                    <FormGroup>                        
                                      <Field name="Durata">
                                          {({ field, form, meta }) => (
                                                <div>
                                                    <Label><b>Durata</b></Label>
                                                    <InputGroup className="input-group-alternative">
                                                        <InputGroupAddon addonType="prepend">
                                                            <InputGroupText>
                                                            <i className="ni ni-caps-small" />
                                                            </InputGroupText>
                                                        </InputGroupAddon>
                                                        <Input {...field} 
                                                            disabled={isSubmitting}
                                                            placeholder="Durata in giorni"
                                                            type="text"
                                                            autoComplete="off"
                                                        />
                                                    </InputGroup>
                                                    {/*<input type="text" {...field} placeholder="First Name"/>*/}
                                                    {/*meta.touched && meta.error && <div className="error">{meta.error}</div>*/}
                                                </div>
                                          )}                               
                                      </Field>
                                      <ErrorMessage component="div" name="Durata" className="text-red small"/>
                                    </FormGroup>
                                  </Col>
                                </Row>
                                <Row>
                                  <Col>
                                    {/* MAIL */}
                                    <FormGroup>
                                      <Field name="Sconto">
                                          {({ field, form, meta }) => (
                                              <div>
                                                <Label><b>Sconto</b></Label>
                                                  <InputGroup className="input-group-alternative">
                                                      <InputGroupAddon addonType="prepend">
                                                          <InputGroupText>
                                                          <i className="ni ni-cart" />
                                                          </InputGroupText>
                                                      </InputGroupAddon>
                                                      <Input {...field} 
                                                          disabled={isSubmitting}
                                                          placeholder="25%"
                                                          type="number"
                                                          autoComplete="off"
                                                      />
                                                  </InputGroup>
                                                  {/*<input type="text" {...field} placeholder="First Name"/>*/}
                                                  {/*meta.touched && meta.error && <div className="error">{meta.error}</div>*/}
                                              </div>
                                          )}                               
                                          </Field>
                                          <ErrorMessage component="div" name="Sconto" className="text-red small"/>
                                    </FormGroup>
                                  </Col>
                                  <Col>
                                    <FormGroup>                        
                                      <Field name="MaxActivation">
                                          {({ field, form, meta }) => (
                                                <div>
                                                    <Label><b>Max Attivazioni</b></Label>
                                                    <InputGroup className="input-group-alternative">
                                                        <InputGroupAddon addonType="prepend">
                                                            <InputGroupText>
                                                            <i className="ni ni-caps-small" />
                                                            </InputGroupText>
                                                        </InputGroupAddon>
                                                        <Input {...field} 
                                                            disabled={isSubmitting}
                                                            placeholder="Max Utilizzi"
                                                            type="text"
                                                            autoComplete="off"
                                                        />
                                                    </InputGroup>
                                                    {/*<input type="text" {...field} placeholder="First Name"/>*/}
                                                    {/*meta.touched && meta.error && <div className="error">{meta.error}</div>*/}
                                                </div>
                                          )}                               
                                      </Field>
                                      <ErrorMessage component="div" name="MaxActivation" className="text-red small"/>
                                    </FormGroup>
                                  </Col>
                                  
                                </Row>
                               
                                
                            </ModalBody>
                            <ModalFooter>                              
                              {/* tasti conferma per modifica , inserisci per nuovo e chiudi */}
                              <Button color="secondary" disabled={this.state.isFormValid}  onClick={this.toggleModalEdit}>Annulla</Button>
                              {this.state.selectedCoupon.ID?
                              <Button color="primary" type="submit" disabled={(Object.keys(errors).length>0)?true:false}>Modifica</Button>
                              :
                              <Button color="primary" type="submit" disabled={(Object.keys(touched).length==0 || Object.keys(errors).length>0)?true:false}>Inserisci</Button>
                              }

                            </ModalFooter>
                            </Form> 
                            </Fragment>
                          )}


                  
                    </Formik>
                </Modal>
        </div>
           
        )
    }
}

export default AnagraficheUtenti;