//contiene funzioni per gestione utente 
import axios from "axios";
import swal from 'sweetalert2';

const baseUrl = '/api/v1'


//imposto headers di default axios 
axios.defaults.headers.common = {'Accept':'Application/json'}

/**
 * refresh di token
 */
export function refresh(){
    //token = window.localStorage.getItem('hoppla_token');    
    axios.defaults.headers.common = {'Authorization': 'Bearer '+window.localStorage.getItem('hoppla_token'),'Accept':'Application/json'}
} 

//var token = window.localStorage.getItem('hoppla_token');
axios.defaults.baseURL = baseUrl
//check se presente token 
if(window.localStorage.getItem('hoppla_token'))
    refresh()



//fa richieste senza utilizzo di token
export const APInoToken = axios.create({
        baseURL:baseUrl,
        headers:{
            //'Authorization':'Bearer ' + window.localStorage.getItem('hoppla_token'),     
            'Accept':'Application/json'
        }
    })

//gestisce oggetto errore http, se sessione scaduta mostra messaggio, se errore imprevisto mostra messaggio 
export const manageError = (response)=>{
    swal.fire({
        title: "Errore",
        text: (response!==undefined?(response.data.description||response.data.message):'Errore imprevisto, ritenta'),
        icon: "error",
        button: "OK",
    });
}


export default axios

/*export default axios.create({
    baseURL:baseUrl,
    headers:{
        'Authorization':'Bearer ' + token,     
        'Accept':'Application/json'
    }
    
});*/