import React,{Fragment} from "react";
import ReactDatetime from 'react-datetime';


import {
  Container,
  Row,
  Col,
  CustomInput,
  Table,
  Card,
  CardBody,
  CardFooter,
  CardHeader,
  Nav,
  Button,
  UncontrolledTooltip,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Form,
  FormGroup,
  Input,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  Label
} from "reactstrap";
import {Formik,Field,ErrorMessage} from "formik";
import swal from "sweetalert2"
//pagination 
import Pagination from "react-js-pagination";

//require("bootstrap/less/bootstrap.less");

//importo helpers per utente
import sub from "../../helpers/subscriptions.js";

class AnagraficheUtenti extends React.Component {

  constructor(props){
    super(props)
    this.state = {
      subscriptions:[],
      selectedAbb:{},
      userslevel:[],
      activePage: 1,
      itemPerPage: 10
    }
    this.handlePageChange = this.handlePageChange.bind(this);
    this.handleDeleteSubscription = this.handleDeleteSubscription.bind(this);
    this.handleInsertSubscription = this.handleInsertSubscription.bind(this);
    this.handleEditSubscription = this.handleEditSubscription.bind(this);
    this.toggleModalEdit = this.toggleModalEdit.bind(this)
    this.validateForm = this.validateForm.bind(this)

  }

  handlePageChange(pageNumber){
    //console.log("PAGE NUMBER",pageNumber)
    this.setState({ activePage: pageNumber });
  }

  //inserisce abbonamento 
  handleInsertSubscription(objIn){
    console.log("INSERISCI",objIn)
    //invoco api a server e chiudo popup
    sub.insAnaSubscription(objIn).then(res=>{
      this.setState({isModalEditOpen:!this.state.isModalEditOpen})
      //refresh abbonamenti 
      this.refreshSubscriptions()
    }).catch(err=>{

    })
  }

  //modifica abbonamento
  handleEditSubscription(objIn){
    //invoco api a server e chiudo popup 
    console.log("MODIFICA",objIn)
    objIn.ID = this.state.selectedAbb.ID;
    sub.editAnaSubscription(objIn).then(res=>{
      this.setState({isModalEditOpen:!this.state.isModalEditOpen})
      //refresh abbonamenti 
      this.refreshSubscriptions()
    }).catch(err=>{

    })

  }

  handleDeleteSubscription(e,subobj){
    //pannello di conferma 
    swal.fire({
      title: 'Confermi?',
      text: "Stai per eliminare l'abbonamento "+subobj.Title ,
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Conferma'
    }).then((result) => {
      if (result.isConfirmed) {
        //console.log("e",e)
        //console.log("ID",ID)
        sub.deleteSubscription(subobj.ID).then(res=>{
          //console.log("res",res)
          //this.setState({users:res})
          swal.fire(
            'Eliminato!',
            'L abbonamento è stato eliminato',
            'success'
          )
          this.refreshSubscriptions()
        }).catch(err=>{
          console.log("err",err)
        })
      }
    })

    
  }

  toggleModalEdit(e,item){
    
    if(item){
      //
      this.setState({selectedAbb:item})
     
    }else{
      //caso nuova sottoscrizione o chiusura popup 
      this.setState({selectedAbb:{
        PriceMonth: 0,
        Title:'',
        Description:'',
        MaxEventsPerMonth:0,
        MaxGuidesInside:0,
        isFlat:false,
        IDMenuCategory:'',
        DurationDays:0
      }
      })
    }
    if(e)
      e.preventDefault()
    this.setState({isModalEditOpen:!this.state.isModalEditOpen})
  }

  refreshSubscriptions(){
    sub.getSubscriptions().then(res=>{
      console.log("res",res)
      this.setState({subscriptions:res})
    }).catch(err=>{
      console.log("err",err)
    })
  }

  //validazione form
  validateForm(values){
      const emailTest = /^[^\s@]+@[^\s@]+\.[^\s@]{2,}$/i;
      let errors = {};
  
  
      if (values.PriceMonth === "" || values.PriceMonth<=0) {
        errors.PriceMonth = "Prezzo obbligatorio";
      }
      if (values.Title === "") {
        errors.Title = "Nome abbonamento obbligatorio";
      }
       
      //controllo se di tipo flat o no 
      if(values.isFlat){
        //numero massimo guide obbligatorio
        if (values.MaxGuidesInside === "" || values.MaxGuidesInside<=0) {
          errors.MaxGuidesInside = "Numero massimo di guide obbligatorio";
        }

      }else{
        //numero massimo eventi obbligatorio
        if (values.MaxEventsPerMonth === "" || values.MaxEventsPerMonth<=0) {
          errors.MaxEventsPerMonth = "Numero massimo di eventi per durata abbonamento obbligatorio";
        }
      }
  
      //controllo tipo di utente selezionato 
      if (values.IDMenuCategory == "") {
        errors.IDMenuCategory = "Tipo di utente obbligatorio";
      } 

      if (values.DurationDays == "" || values.DurationDays<=0) {
        errors.DurationDays = "Durata abbonamento non valida";
      } 
  
  
      //console.log("values in validate ",values )
  
      return errors;
    
    }
  
  

  componentDidMount(){
    this.refreshSubscriptions()
    //ottengo anagrafica tipo di utenti 
    sub.getUsersLevel().then(res=>{
      this.setState({userslevel:res})
    }).catch(err=>{

    })

  }
    render() {

      const { subscriptions, activePage, itemPerPage } = this.state;

      const indexOfLastTodo = activePage * itemPerPage;
      const indexOfFirstTodo = indexOfLastTodo - itemPerPage;
      const renderedSubscriptions = subscriptions.slice(indexOfFirstTodo, indexOfLastTodo);

        return (
            <div className="position-relative">
            {/* shape Hero */}
            <section className="section section-md section-shaped pb-10">
              <div className="shape shape-style-1 shape-primary">
                <span />
                <span />
                <span />
                <span />
                <span />
                <span />
                <span />
                <span />
                <span />
              </div>
              <Container fluid className="py-lg-md d-flex" >
                <div className="col px-0">
                  <Row>
                    <Col lg="6">                        
                        <p className="pl-4 lead text-white">
                         Anagrafiche Abbonamenti <span className="badge badge-secondary">{this.state.subscriptions.length}</span>
                        </p>                        
                    </Col>
                  </Row>
                </div>
              </Container>
              {/* SVG separator */}
              <div className="separator separator-bottom separator-skew">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  preserveAspectRatio="none"
                  version="1.1"
                  viewBox="0 0 2560 100"
                  x="0"
                  y="0"
                >
                  <polygon
                    className="fill-white"
                    points="2560 0 2560 100 0 100"
                  />
                </svg>
              </div>
            </section>
            <section className="section pb-10 pt-0">
            <Container fluid className="p-5">
              {/*<Row className="row-grid align-items-center">
                
                <Col className="" lg="6" sm="12">
                  <div className="d-flex px-3">
                    <div>
                      <div className="icon icon-lg icon-shape bg-gradient-white shadow rounded-circle text-primary">
                        <i className="ni ni-building text-primary" />
                      </div>
                    </div>

                    <div className="pl-4">
                      <h4 className="display-3 ">
                          Eventi
                          <Button
                          className="mr-4 mt-2 float-right"
                          color="info"
                          href="#pablo"
                          onClick={e => e.preventDefault()}
                          size="sm"
                        >
                          <i className="fa fa-plus"></i> Crea Nuovo
                        </Button>
                        </h4>
                      <p className="">
                        Qui sotto puoi creare e gestire gli eventi di audio guida smart.
                      </p>
                    </div>
                  </div>
                  </Col>
                </Row>*/}
                {/* tabella utenti */}
                <Row>
                  <Col lg="12">
                    <div className="text-right pb-2">
                    <Button color="default" outline type="button" onClick={this.toggleModalEdit}>                      
                      Nuovo
                    </Button>
                    </div>
                    <Card className=" shadow border-0">
                        <CardBody className="p-0">
                        <Table className="mb-0" hover striped responsive>{/* size="sm"*/}
                          <thead>
                            <tr>
                              <th>#</th>
                              <th>Nome</th>
                              <th>Descrizione</th>
                              <th>Costo Mensile</th>
                              <th>Durata <small>(giorni)</small></th>
                              <th>N. Max di Guide</th>
                              <th>N. Max di Eventi</th>
                              <th></th>
                            </tr>
                          </thead>
                          <tbody>
                            {renderedSubscriptions.map((item,i)=>{
                              return <tr key={i}>
                                  <th scope="row">{i+1}</th>
                                  <td>{item.Title}</td>
                                  <td style={{overflow: 'hidden', textOverflow: 'ellipsis',maxWidth:'300px'}}><small>{item.Description}</small></td>
                                  <td>{item.PriceMonth}€</td>    
                                  <td>{item.DurationDays}</td>                                
                                  <td>{item.MaxGuidesInside}</td>
                                  <td>{item.MaxEventsPerMonth}</td>
                                  <td className=" td-actions text-right" style={{minWidth:'150px'}}>
                                   
                                    <Button onClick={(e)=>{this.toggleModalEdit(e,item)}}
                                      className=" btn-icon btn-simple"
                                      color="success"
                                      size="sm"
                                      type="button"
                                    >
                                      <i className=" ni ni-settings-gear-65 pt-1"></i>
                                    </Button>

                                    <Button onClick={(e)=>{this.handleDeleteSubscription(e,item)}}
                                      className=" btn-icon btn-simple"
                                      color="danger"
                                      size="sm"
                                      type="button"
                                    >
                                      <i className=" fa fa-times pt-1"></i>
                                    </Button>
                                  </td>
                                </tr>
                            })}                                                      
                          </tbody>
                        </Table>
                        </CardBody>
                        <CardFooter>
                          <Nav className="justify-content-center">
                            <Pagination
                                  hideNavigation
                                  activePage={this.state.activePage}                                  
                                  itemsCountPerPage={this.state.itemPerPage}
                                  totalItemsCount={this.state.subscriptions.length}
                                  pageRangeDisplayed={5}
                                  onChange={this.handlePageChange}
                                  itemClass="page-item"
                                  linkClass="page-link"                                 
                              />
                            </Nav>
                        </CardFooter>
                        </Card>
                  </Col>
                </Row>
                </Container>
                </section>

                 {/*modal per modifica utente */}              
             <Modal isOpen={this.state.isModalEditOpen} toggle={this.toggleModalEdit} size="lg">
                    <ModalHeader toggle={this.toggleModalEdit}>{this.state.selectedAbb.Title||'Nuovo'}</ModalHeader>
                          <Formik
                          enableReinitialize
                          validate={this.validateForm}                       
                          initialValues={{
                            PriceMonth: this.state.selectedAbb.PriceMonth,
                            Title:this.state.selectedAbb.Title,
                            Description:this.state.selectedAbb.Description,
                            MaxEventsPerMonth:this.state.selectedAbb.MaxEventsPerMonth||0,
                            MaxGuidesInside:this.state.selectedAbb.MaxGuidesInside||0,
                            isFlat:this.state.selectedAbb.isFlat==1,
                            IDMenuCategory:this.state.selectedAbb.IDMenuCategory,
                            DurationDays: this.state.selectedAbb.DurationDays
                          }} 
                          onSubmit={(values,{ setSubmitting }) => {
                                //console.log("values in submit",values)
                                //console.log("this.state",this.state.selectedUser.ID)
                                if(!this.state.selectedAbb.ID){
                                  //console.log("nuovo utente")
                                  this.handleInsertSubscription(values)
                                }else{
                                  //console.log("Edit utente")
                                  this.handleEditSubscription(values)
                                }
                                
                                //alert("Form is validated! Submitting the form...");
                                //setSubmitting(false); role="form" name="formRegister"
                          }}
                          >{({ values,errors, touched,handleSubmit,handleChange,isSubmitting }) => (
                           <Fragment>
                            <Form onSubmit={handleSubmit} onChange={handleChange}>
                            <ModalBody>
                             
                                <Row>
                                  {/* utente a cui associare abbonamento, solo se è nuovo */}
                                                                 
                                  <Col md="6">
                                    <FormGroup>                        
                                        <Field name="Title">
                                            {({ field, form, meta }) => (
                                                  <div>
                                                    <Label><b>Nome</b></Label>
                                                      <InputGroup className="input-group-alternative">
                                                          <InputGroupAddon addonType="prepend">
                                                              <InputGroupText>
                                                              <i className="ni ni-money-coins" />
                                                              </InputGroupText>
                                                          </InputGroupAddon>
                                                          <Input {...field} 
                                                              disabled={isSubmitting}
                                                              placeholder="Nome"
                                                              type="text"
                                                              autoComplete="off"
                                                          />
                                                      </InputGroup>
                                                      {/*<input type="text" {...field} placeholder="First Name"/>*/}
                                                      {/*meta.touched && meta.error && <div className="error">{meta.error}</div>*/}
                                                  </div>
                                            )}                               
                                        </Field>
                                        <ErrorMessage component="div" name="Title" className="text-red small"/>
                                      </FormGroup>       
                                  </Col> 
                                  <Col md="6">                                   
                                  <FormGroup>                        
                                        <Field name="Description">
                                            {({ field, form, meta }) => (
                                                  <div>
                                                    <Label><b>Descrizione</b></Label>
                                                      <InputGroup className="input-group-alternative">
                                                          <InputGroupAddon addonType="prepend">
                                                              <InputGroupText>
                                                              <i className="ni ni-money-coins" />
                                                              </InputGroupText>
                                                          </InputGroupAddon>
                                                          <Input {...field} 
                                                              disabled={isSubmitting}
                                                              rows="4"
                                                              placeholder="Descrizione"
                                                              type="textarea"
                                                              autoComplete="off"
                                                          />
                                                      </InputGroup>
                                                      {/*<input type="text" {...field} placeholder="First Name"/>*/}
                                                      {/*meta.touched && meta.error && <div className="error">{meta.error}</div>*/}
                                                  </div>
                                            )}                               
                                        </Field>
                                        <ErrorMessage component="div" name="Description" className="text-red small"/>
                                      </FormGroup>           
                                  </Col>
                                  <Col md="6">
                                    <FormGroup>                        
                                        <Field name="PriceMonth">
                                            {({ field, form, meta }) => (
                                                  <div>
                                                    <Label><b>Prezzo</b></Label>
                                                      <InputGroup className="input-group-alternative">
                                                          <InputGroupAddon addonType="prepend">
                                                              <InputGroupText>
                                                              <i className="ni ni-money-coins" />
                                                              </InputGroupText>
                                                          </InputGroupAddon>
                                                          <Input {...field} 
                                                              disabled={isSubmitting}
                                                              placeholder="Prezzo"
                                                              type="number"
                                                              autoComplete="off"
                                                          />
                                                      </InputGroup>
                                                      {/*<input type="text" {...field} placeholder="First Name"/>*/}
                                                      {/*meta.touched && meta.error && <div className="error">{meta.error}</div>*/}
                                                  </div>
                                            )}                               
                                        </Field>
                                        <ErrorMessage component="div" name="PriceMonth" className="text-red small"/>
                                      </FormGroup>
                                  </Col>
                                  <Col md="6">
                                      <FormGroup>                        
                                        <Field name="MaxEventsPerMonth">
                                            {({ field, form, meta }) => (
                                                  <div>
                                                    <Label><b>Numero Massimo eventi</b></Label>
                                                      <InputGroup className="input-group-alternative">
                                                          <InputGroupAddon addonType="prepend">
                                                              <InputGroupText>
                                                              <i className="ni ni-money-coins" />
                                                              </InputGroupText>
                                                          </InputGroupAddon>
                                                          <Input {...field} 
                                                              disabled={isSubmitting||values.isFlat}
                                                              placeholder="Numeor masismo eventi al mese"
                                                              type="number"
                                                              autoComplete="off"
                                                          />
                                                      </InputGroup>
                                                      {/*<input type="text" {...field} placeholder="First Name"/>*/}
                                                      {/*meta.touched && meta.error && <div className="error">{meta.error}</div>*/}
                                                  </div>
                                            )}                               
                                        </Field>
                                        <ErrorMessage component="div" name="MaxEventsPerMonth" className="text-red small"/>
                                      </FormGroup>
                                  </Col>
                                  <Col md="6">
                                      <FormGroup>                        
                                        <Field name="MaxGuidesInside">
                                            {({ field, form, meta }) => (
                                                  <div>
                                                    <Label><b>Numero Massimo Guide</b></Label>
                                                      <InputGroup className="input-group-alternative">
                                                          <InputGroupAddon addonType="prepend">
                                                              <InputGroupText>
                                                              <i className="ni ni-money-coins" />
                                                              </InputGroupText>
                                                          </InputGroupAddon>
                                                          <Input {...field} 
                                                              disabled={isSubmitting|| !values.isFlat}
                                                              placeholder="Numero massimo guide"
                                                              type="number"
                                                              autoComplete="off"
                                                          />
                                                      </InputGroup>
                                                      {/*<input type="text" {...field} placeholder="First Name"/>*/}
                                                      {/*meta.touched && meta.error && <div className="error">{meta.error}</div>*/}
                                                  </div>
                                            )}                               
                                        </Field>
                                        <ErrorMessage component="div" name="MaxGuidesInside" className="text-red small"/>
                                      </FormGroup>
                                  </Col>
                                  <Col md="6">
                                    {/* flat , cioè se infiniti eventi ma durata limitata */}
                                    <FormGroup className="ml-4">
                                      <Field name="isFlat">
                                        {({ field, form, meta }) => (
                                            <div>
                                              <Label>                                                                                   
                                                    <Input {...field} 
                                                        disabled={isSubmitting}                                                       
                                                        checked={field.value}
                                                        type="checkbox"
                                                        onChange={(e)=>{form.setFieldValue('isFlat',e.target.checked)}}
                                                        autoComplete="off"
                                                    />
                                                    
                                                    <b>Flat {field.value} <small>(eventi illimitati nella durata dell'abbonamento)</small></b>
                                                    </Label>
                                                
                                                {/*<input type="text" {...field} placeholder="First Name"/>*/}
                                                {/*meta.touched && meta.error && <div className="error">{meta.error}</div>*/}
                                            </div>
                                        )}                               
                                        </Field>
                                        <ErrorMessage component="div" name="isFlat" className="text-red small"/>
                                      </FormGroup>
                                  </Col>
                                  <Col md="6">
                                      <FormGroup>                        
                                        <Field name="DurationDays">
                                            {({ field, form, meta }) => (
                                                  <div>
                                                    <Label><b>Durata dell'abbonamento <small>(giorni)</small></b></Label>
                                                      <InputGroup className="input-group-alternative">
                                                          <InputGroupAddon addonType="prepend">
                                                              <InputGroupText>
                                                              <i className="ni ni-calendar" />
                                                              </InputGroupText>
                                                          </InputGroupAddon>
                                                          <Input {...field} 
                                                              disabled={isSubmitting}
                                                              placeholder="Durata in giorni dell'abbonamento"
                                                              type="number"
                                                              autoComplete="off"
                                                          />
                                                      </InputGroup>
                                                      {/*<input type="text" {...field} placeholder="First Name"/>*/}
                                                      {/*meta.touched && meta.error && <div className="error">{meta.error}</div>*/}
                                                  </div>
                                            )}                               
                                        </Field>
                                        <ErrorMessage component="div" name="DurationDays" className="text-red small"/>
                                      </FormGroup>
                                  </Col>
                                  <Col>
                                  {!this.state.selectedAbb.ID?
                                    <FormGroup>
                                        <Field name="IDMenuCategory">
                                            {({ field, form, meta }) => (
                                                <div> {/* value={this.state.IDMenuCategory} onChange={this.handleUsersLevelChange}*/ }
                                                    <Label><b>Tipo Utenza</b></Label>
                                                    <Input type="select" {...field} className="form-control-sm" placeholder={"Seleziona un tipo di utente"} >
                                                        <option value="">Seleziona un elemento...</option>
                                                        {this.state.userslevel.map((item,i)=>{
                                                            return <option key={i} value={item.ID}>{item.DescrInRegister}</option>
                                                        })}
                                                    </Input>
                                                    {/*<input type="text" {...field} placeholder="First Name"/>*/}
                                                    {/*meta.touched && meta.error && <div className="error">{meta.error}</div>*/}
                                                </div>
                                            )}                               
                                            </Field>
                                            <ErrorMessage component="div" name="IDMenuCategory" className="text-red small"/>
                                          {/*<Label for="exampleSelect1" >Tipo di utente</Label>
                                          <Input type="select" name="select" className="form-control-sm" id="exampleSelect1" value={this.state.IDMenuCategory} onChange={this.handleUsersLevelChange}>
                                                      {this.state.userslevel.map((item,i)=>{
                                                      return <option key={i} value={item.ID}>{item.DescrInRegister}</option>
                                                      })}
                                          </Input>
                                                    */}
                                      </FormGroup>
                                      :
                                      <span></span>
                                      }
                                  </Col>
                                </Row>
                                
                            </ModalBody>
                            <ModalFooter>                              
                              {/* tasti conferma per modifica , inserisci per nuovo e chiudi */}
                              <Button color="secondary" disabled={this.state.isFormValid}  onClick={this.toggleModalEdit}>Annulla</Button>
                              {this.state.selectedAbb.ID?
                              <Button color="primary" type="submit" disabled={(Object.keys(errors).length>0)?true:false}>Modifica</Button>
                              :
                              <Button color="primary" type="submit" disabled={(Object.keys(touched).length==0 || Object.keys(errors).length>0)?true:false}>Inserisci</Button>
                              }

                            </ModalFooter>
                            </Form> 
                            </Fragment>
                          )}


                  
                    </Formik>
                </Modal>
        </div>
        )
    }
}

export default AnagraficheUtenti;