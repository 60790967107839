import API,{refresh, APInoToken,manageError} from './api'
import swal from 'sweetalert';

/**
 * ottiene info di utente
 */
async function getSubscriptions(IDMenuCategory){    
    try{    
        //console.log("IDMENUCAT",IDMenuCategory)
        let data = await API.get('/ana/subscriptions',{params:{IDMenuCategory}})       
        return data.data;  
    }catch(err){
        //console.log("err -- - - -",err)
        manageError(err.response)
        //console.log("error",err.message)
        throw err.response
    }             
} 

/**
 * modifica abbonamento utente
 */
 async function editSubscription(obj){    
    try{    
        //console.log("IDMENUCAT",obj)
        let data = await API.post('/sub/subscription',obj)       
        return data.data;  
    }catch(err){
        //console.log("err -- - - -",err)
        manageError(err.response)
        //console.log("error",err.message)
        throw err.response
    }             
} 

/**
 * inserisce abbonamento utente
 */
 async function insSubscription(obj){    
    try{    
        //console.log("IDMENUCAT",obj)
        let data = await API.put('/sub/subscription',obj)       
        return data.data;  
    }catch(err){
        //console.log("err -- - - -",err)
        manageError(err.response)
        //console.log("error",err.message)
        throw err.response
    }             
} 

/**
 * modifica abbonamento in anagrafica
 */
 async function editAnaSubscription(obj){    
    try{    
        //console.log("IDMENUCAT",obj)
        let data = await API.post('/ana/subscription',obj)       
        return data.data;  
    }catch(err){
        //console.log("err -- - - -",err)
        manageError(err.response)
        //console.log("error",err.message)
        throw err.response
    }             
} 

/**
 * inserisce abbonamento in anagrafica
 */
 async function insAnaSubscription(obj){    
    try{    
        //console.log("IDMENUCAT",obj)
        let data = await API.put('/ana/subscriptions',obj)       
        return data.data;  
    }catch(err){
        //console.log("err -- - - -",err)
        manageError(err.response)
        //console.log("error",err.message)
        throw err.response
    }             
} 




/**
 * elimina un abbonamento
 */
 async function deleteSubscription(ID){    
    try{    
        //console.log("IDMENUCAT",IDMenuCategory)
        let data = await API.delete('/ana/subscription/'+ID)       
        return data.data;  
    }catch(err){
        //console.log("err -- - - -",err)
        manageError(err.response)
        //console.log("error",err.message)
        throw err.response
    }             
} 

/**
 * ottiene livelli di utente ( menu )
 */
 async function getUsersLevel(){    
    try{    
        let data = await API.get('/ana/userslevel',{})       
        return data.data;  
    }catch(err){
        //console.log("err -- - - -",err)
        manageError(err.response)
        //console.log("error",err.message)
        throw err.response
    }             
} 

/**
 * ottiene sottoscrizioni per utente in modo da controllare pagamenti 
 */
 async function getUsersSubscriptions(allusers){    
    try{    
        let data = await API.get('/sub/users',{params:{allusers}})       
        return data.data;  
    }catch(err){
        //console.log("err -- - - -",err)
        manageError(err.response)
        //console.log("error",err.message)
        throw err.response
    }             
} 

/**
 * ottiene sottoscrizioni di singolo  utente 
 */
 async function getUserSubscriptions(isPayed){    
    try{    
        let data = await API.get('/sub/user',{params:{isPayed}})       
        return data.data;  
    }catch(err){
        //console.log("err -- - - -",err)
        manageError(err.response)
        //console.log("error",err.message)
        throw err.response
    }             
} 

/**ottiene limite di utente per abbonamento  */
async function getBoundaries(ID){    
    try{    
        let data = await API.get('/sub/boundaries',{params:{ID}})       
        return data.data;  
    }catch(err){
        //console.log("err -- - - -",err)
        manageError(err.response)
        //console.log("error",err.message)
        throw err.response
    }             
} 

/**
 * invia un sollecito via mail 
 */
 
  async function sendSollecito(sub){    
    try{    
        let data = await API.post('/sub/sollecito',sub)       
        return data.data;  
    }catch(err){
        //console.log("err -- - - -",err)
        manageError(err.response)
        //console.log("error",err.message)
        throw err.response
    }             
} 


/**ottiene limite di utente per abbonamento  */
async function getPaymentsByIDAbb(IDAbb,isAll){    
    try{    
        let data = await API.get('/sub/payments',{params:{IDAbb,isAll}})       
        return data.data;  
    }catch(err){
        //console.log("err -- - - -",err)
        manageError(err.response)
        //console.log("error",err.message)
        throw err.response
    }             
} 

//modifica stato di pagamento come pagato o non pagato 
async function editPaymentState(IDPayment,isPayed){    
    try{    
        let data = await API.post('/sub/payment',{IDPayment,isPayed})       
        return data.data;  
    }catch(err){
        //console.log("err -- - - -",err)
        manageError(err.response)
        //console.log("error",err.message)
        throw err.response
    }             
} 

//modifica flag isEnabled id pagamento 
async function editPaymentEnableFlag(IDPayment,isEnabled){    
    try{    
        let data = await API.post('/sub/payment/enabled',{IDPayment,isEnabled})       
        return data.data;  
    }catch(err){
        //console.log("err -- - - -",err)
        manageError(err.response)
        //console.log("error",err.message)
        throw err.response
    }             
} 


//modifica flag isEnabled id abbonamento 
async function editSubscriptionEnableFlag(IDAbb,isEnabled){    
    try{    
        let data = await API.post('/sub/enabled',{IDAbb,isEnabled})       
        return data.data;  
    }catch(err){
        //console.log("err -- - - -",err)
        manageError(err.response)
        //console.log("error",err.message)
        throw err.response
    }             
} 

//aggiunge un pagamento per abbonamento selezionato 
async function addPayment(objIn){    
    try{    
        let data = await API.put('/sub/payment',objIn)       
        return data.data;  
    }catch(err){
        //console.log("err -- - - -",err)
        manageError(err.response)
        //console.log("error",err.message)
        throw err.response
    }             
} 



export default{
    getSubscriptions,
    deleteSubscription,
    getUsersLevel,
    getBoundaries,
    editSubscription,
    insSubscription,
    insAnaSubscription,
    editAnaSubscription,
    //per pagamenti 
    getUsersSubscriptions,
    sendSollecito,   
    //pagin personale utente pagamenti 
    getUserSubscriptions,
    //ottiene pagamenti da id abbonamento 
    getPaymentsByIDAbb,
    editPaymentState,
    editPaymentEnableFlag,
    editSubscriptionEnableFlag,
    addPayment
}


